import { defineMessages } from 'react-intl';

const messages = defineMessages({
	usersTab: {
		id: 'admin.productDetails.tab.users',
		description:
			'Label for sub-tab of mapped users in the Product Details page',
		defaultMessage: 'Mapped Users',
	},
	unmappedUsersTab: {
		id: 'admin.productDetails.tab.unmappedUsers',
		description:
			'Label for sub-tab of unmapped users in the Product Details page',
		defaultMessage: 'Unmapped Users',
	},
	detailsTab: {
		id: 'admin.productDetails.tab.details',
		description: 'Label for sub-tab of details in the Product Details page',
		defaultMessage: 'Details',
	},
	activateTenantButton: {
		id: 'admin.users.button.activateTenant',
		description: 'Activate Planview Admin for a product button text',
		defaultMessage: 'Enable Planview Admin authentication',
	},
	activateSuccess: {
		id: 'admin.users.activateSuccess',
		description:
			'Text of success / non failure response to activate product',
		defaultMessage:
			'Planview Admin authentication is enabled. To access this product, users must also be users in Planview Admin.',
	},
	deactivateSuccess: {
		id: 'admin.users.deactivateSuccess',
		description:
			'Text of success / non failure response to deactivate product',
		defaultMessage:
			"Planview Admin authentication is disabled. To access this product, users must use the product's original authentication system.",
	},
	planviewAdminActivated: {
		id: 'admin.users.planviewAdminActivated',
		description:
			'Text displayed on product screen when it is activated / SSO with PV Admin',
		defaultMessage: 'Planview Admin authentication enabled',
	},
	deactivatePlanviewAdmin: {
		id: 'admin.users.deactivatePlanviewAdmin',
		description: 'Action label to deactivate SSO with PV Admin',
		defaultMessage: 'Disable Planview Admin authentication',
	},
	tdumEnabled: {
		id: 'admin.users.tdumEnabled',
		description:
			'Text displayed when SSO is enforced because TDUM is enabled',
		defaultMessage: 'Top-down user management is enabled',
	},
	cautionText: {
		id: 'admin.productDetails.cautionText',
		description: 'Caution label for warning messages',
		defaultMessage: 'Caution',
	},
	enableButton: {
		id: 'admin.productDetails.enableButton',
		description:
			'Confirmation button text for activating product in planview admin confirmation modal',
		defaultMessage: 'Enable',
	},
	disableButton: {
		id: 'admin.productDetails.disableButton',
		description:
			'Confirmation button text for deactivating product in planview admin confirmation modal',
		defaultMessage: 'Disable',
	},
	cancelButton: {
		id: 'admin.productDetails.cancelButton',
		description:
			'Cancel button text for deactivating product in planview admin confirmation modal',
		defaultMessage: 'Cancel',
	},
	activateProductHeader: {
		id: 'admin.productDetails.activateProductHeader',
		description:
			'Header text for activate product in planview admin confirmation modal',
		defaultMessage: 'Enable Planview Admin authentication',
	},
	deactivateProductHeader: {
		id: 'admin.productDetails.deactivateProductHeader',
		description:
			'Header text for deactivate product in planview admin confirmation modal',
		defaultMessage: 'Disable Planview Admin authentication',
	},
	activateProductWarning: {
		id: 'admin.productDetails.activateProductWarning',
		description:
			'Text displayed when admin attempts to activate product in Planview Admin',
		defaultMessage:
			'Enabling Planview Admin authentication will change how users access this product. If your instance of Planview Admin is configured to use password authentication, users of this product cannot access it until they set up their Planview Admin password.',
	},
	deactivateProductWarning: {
		id: 'admin.productDetails.deactivateProductWarning',
		description:
			'Text displayed when admin attempts to deactivate product in Planview Admin',
		defaultMessage:
			"Disabling Planview Admin authentication will force users to log in using the product's original authentication system. This may affect users' ability to access the product.",
	},
});

export default messages;
