const SUPPORTED_LOCALES = ['de', 'en', 'es', 'fr', 'sv'];

export const getDefaultLocale = () => {
	const preferredLocales = window.navigator.languages;
	for (let i = 0; i < preferredLocales.length; i++) {
		const lang = preferredLocales[i];
		const langCode = lang.indexOf('-') ? lang.split('-')[0] : lang;
		if (SUPPORTED_LOCALES.includes(langCode)) {
			return langCode;
		}
	}
	return 'en';
};

export type Messages = Record<string, string>;

const consolidateMessages = (
	base: Messages,
	filter: Messages,
	grid: Messages,
	uikit: Messages,
) => {
	return {
		...base,
		...filter,
		...grid,
		...uikit,
	};
};

export const loadLocaleData = async (locale: string) => {
	switch (locale) {
		case 'de': {
			const { default: base } = await import(
				'../translations/de-DE.json'
			);
			const { default: filter } = await import(
				'../../node_modules/@planview/pv-filter/lang/de.json'
			);

			const { default: grid } = await import(
				'../../node_modules/@planview/pv-grid/lang/de.json'
			);

			const { default: uikit } = await import(
				'../../node_modules/@planview/pv-uikit/lang/de.json'
			);

			return consolidateMessages(base, filter, grid, uikit);
		}
		case 'es': {
			const { default: base } = await import(
				'../translations/es-ES.json'
			);
			const { default: filter } = await import(
				'../../node_modules/@planview/pv-filter/lang/es.json'
			);

			const { default: grid } = await import(
				'../../node_modules/@planview/pv-grid/lang/es.json'
			);

			const { default: uikit } = await import(
				'../../node_modules/@planview/pv-uikit/lang/es.json'
			);

			return consolidateMessages(base, filter, grid, uikit);
		}
		case 'fr': {
			const { default: base } = await import(
				'../translations/fr-FR.json'
			);
			const { default: filter } = await import(
				'../../node_modules/@planview/pv-filter/lang/fr.json'
			);

			const { default: grid } = await import(
				'../../node_modules/@planview/pv-grid/lang/fr.json'
			);

			const { default: uikit } = await import(
				'../../node_modules/@planview/pv-uikit/lang/fr.json'
			);

			return consolidateMessages(base, filter, grid, uikit);
		}
		case 'sv': {
			const { default: base } = await import(
				'../translations/sv-SE.json'
			);
			const { default: filter } = await import(
				'../../node_modules/@planview/pv-filter/lang/sv.json'
			);

			const { default: grid } = await import(
				'../../node_modules/@planview/pv-grid/lang/sv.json'
			);

			const { default: uikit } = await import(
				'../../node_modules/@planview/pv-uikit/lang/sv.json'
			);

			return consolidateMessages(base, filter, grid, uikit);
		}
		default: {
			const { default: base } = await import(
				'../translations/en-US.json'
			);
			const { default: filter } = await import(
				'../../node_modules/@planview/pv-filter/lang/en.json'
			);

			const { default: grid } = await import(
				'../../node_modules/@planview/pv-grid/lang/en.json'
			);

			const { default: uikit } = await import(
				'../../node_modules/@planview/pv-uikit/lang/en.json'
			);

			return consolidateMessages(base, filter, grid, uikit);
		}
	}
};
