import React, { useState } from 'react';
import { ListItem } from '@planview/pv-uikit';
import useResendMessagesForTenantAction from './hooks/useResendTenantsAction';
import useManageDataAction from './hooks/useManageDataAction';
import { ToolbarDropdownMenu } from '@planview/pv-toolbar';
import { Customer } from '../../../types';
import AddCustomProductDialog from './AddCustomProductDialog';
import { isDemoCustomer } from '../../../helpers/util';

type ToolbarToolsMenuProps = {
	customer: Customer;
};

const ToolbarToolsMenu = ({ customer }: ToolbarToolsMenuProps) => {
	const [showAddCustomProduct, setShowAddCustomProduct] = useState(false);
	const { setResendMessagesOrg, resendMessagesModal } =
		useResendMessagesForTenantAction();
	const { manageDataEnabled, manageDataAction, manageDataModal } =
		useManageDataAction(customer);
	const { id } = customer;

	const trigger = {
		label: 'Tools',
		withCaret: true,
	};

	return (
		<>
			<ToolbarDropdownMenu
				alignRight={true}
				trigger={trigger}
				label="Tools menu"
			>
				<ListItem
					key="add-custom-product"
					label="Add a custom product"
					onActivate={() => {
						setShowAddCustomProduct(true);
					}}
					hidden={!isDemoCustomer(customer)}
				/>
				<ListItem
					key="resend-messages-to-all-tenants"
					label="Resend messages to all tenants"
					onActivate={() => {
						// eslint-disable-next-line
						// @ts-ignore imported JS function is typed as (null) => void
						setResendMessagesOrg({ id });
					}}
				/>
				<ListItem
					key="manage-product-data"
					label="Manage data"
					onActivate={manageDataAction}
					disabled={!manageDataEnabled}
				/>
			</ToolbarDropdownMenu>
			{showAddCustomProduct ? (
				<AddCustomProductDialog
					customerId={id}
					setShowAddCustomProduct={setShowAddCustomProduct}
				/>
			) : null}
			{resendMessagesModal}
			{manageDataModal}
		</>
	);
};

export default ToolbarToolsMenu;
