import { defineMessages, MessageDescriptor } from 'react-intl';

/* eslint-disable camelcase */
const messages: { [string: string]: MessageDescriptor } = defineMessages({
	header: {
		id: 'home.productTile.modal.addProduct.header',
		description: 'Add modal header text',
		defaultMessage: 'Add Product Instance to Planview Admin',
	},
	addProductInfoEmail: {
		id: 'home.productTile.modal.addProduct.info',
		description:
			'Informational message on each connect modal about email address. Do not translate {email} and <b> tag placeholders',
		defaultMessage:
			'Your email address (<b>{email}</b>) must be associated with an <b>administrative user</b> for this product instance.',
	},
	inputLabel_ppmpro: {
		id: 'home.productTile.modal.addProduct.inputLabel.ppmpro',
		description: 'Tile connect modal body text ppmpro',
		defaultMessage: 'Please enter the product URL',
	},
	inputPlaceholder_ppmpro: {
		id: 'home.productTile.modal.addProduct.inputPlaceholder.ppmpro',
		description: 'Tile connect field input tip text ppmpro',
		defaultMessage: 'https://mycompanyname.ppmpro.com',
	},
	inputLabel_projectplace: {
		id: 'home.productTile.modal.addProduct.inputLabel.projectplace',
		description: 'Tile connect modal body text projectplace',
		defaultMessage:
			'Please enter the product URL. It must be one of the following:',
	},
	inputPlaceholder_projectplace: {
		id: 'home.productTile.modal.addProduct.inputPlaceholder.projectplace',
		description: 'Tile connect field input tip text projectplace',
		defaultMessage: 'https://service.projectplace.com',
	},
	projectplaceProdURL: {
		id: 'home.productTile.modal.addProduct.projectplace.prodURL',
		description: 'Text for projectplace connect URL for prod instance',
		defaultMessage: 'https://service.projectplace.com - Production',
	},
	projectplaceSandboxUSURL: {
		id: 'home.productTile.modal.addProduct.projectplace.sandboxUSURL',
		description:
			'Text for projectplace connect URL for US Sandbox instance',
		defaultMessage:
			'https://sandbox-us.projectplace.com - Sandbox for accounts hosted in the US',
	},
	projectplaceSandboxSEURL: {
		id: 'home.productTile.modal.addProduct.projectplace.sandboxSEURL',
		description:
			'Text for projectplace connect URL for Sweden Sandbox instance',
		defaultMessage:
			'https://sandbox-se.projectplace.com - Sandbox for accounts hosted outside the US',
	},
	inputLabel_leankit: {
		id: 'home.productTile.modal.addProduct.inputLabel.leankit',
		description: 'Tile connect modal body text AgilePlace',
		defaultMessage: 'Please enter the product URL',
	},
	inputPlaceholder_leankit: {
		id: 'home.productTile.modal.addProduct.inputPlaceholder.leankit',
		description: 'Tile connect field input tip text AgilePlace',
		defaultMessage: 'https://mycompanyname.leankit.com',
	},
	inputLabel_e1_prm: {
		id: 'home.productTile.modal.addProduct.inputLabel.e1',
		description: 'Tile connect modal body text Portfolios',
		defaultMessage: 'Please enter the product URL',
	},
	inputPlaceholder_e1_prm: {
		id: 'home.productTile.modal.addProduct.inputPlaceholder.e1',
		description: 'Tile connect field input tip text Portfolios',
		defaultMessage: 'https://mycompanyname.pvcloud.com/planview',
	},
	inputLabel_ea: {
		id: 'home.productTile.modal.addProduct.inputLabel.ea',
		description: 'Tile connect modal body text EA',
		defaultMessage: 'Please enter the product URL',
	},
	inputPlaceholder_ea: {
		id: 'home.productTile.modal.addProduct.inputPlaceholder.ea',
		description: 'Tile connect field input tip text EA',
		defaultMessage: 'Sign in URL',
	},
	inputLabel_hub: {
		id: 'home.productTile.modal.addProduct.inputLabel.hub',
		description: 'Tile connect modal body text Hub',
		defaultMessage: 'Please enter the text',
	},
	inputPlaceholder_hub: {
		id: 'home.productTile.modal.addProduct.inputPlaceholder.hub',
		description: 'Tile connect field input tip text Hub ',
		defaultMessage: 'Please enter the text',
	},
	inputLabel_viz: {
		id: 'home.productTile.modal.addProduct.inputLabel.viz',
		description: 'Tile connect modal body text for Viz',
		defaultMessage: 'Please enter the product URL',
	},
	inputPlaceholder_viz: {
		id: 'home.productTile.modal.addProduct.inputPlaceholder.viz',
		description: 'Tile connect field input tip text Viz',
		defaultMessage: 'https://viz.tasktop.net',
	},
	inputLabel_advisor: {
		id: 'home.productTile.modal.addProduct.inputLabel.advisor',
		description: 'Tile connect modal body text Advisor',
		defaultMessage: 'Please enter the product URL',
	},
	inputPlaceholder_advisor: {
		id: 'home.productTile.modal.addProduct.inputPlaceholder.advisor',
		description: 'Tile connect field input tip text Advisor',
		defaultMessage: 'https://mycompanyname.enrichconsulting.com',
	},
	inputLabel_adaptivework: {
		id: 'home.productTile.modal.addProduct.inputLabel.adaptivework',
		description: 'Tile connect modal body text adpativeWork',
		defaultMessage:
			'Please enter the product URL. It must be one of the following:',
	},
	inputPlaceholder_adaptivework: {
		id: 'home.productTile.modal.addProduct.inputPlaceholder.adaptivework',
		description: 'Tile connect field input tip text adaptivework',
		defaultMessage: 'https://app2.clarizen.com/Clarizen',
	},
	adaptiveworkProdUSURL: {
		id: 'home.productTile.modal.addProduct.adaptivework.prodUSURL',
		description: 'Text for adaptivework connect URL for US prod instance',
		defaultMessage: 'https://app2.clarizen.com/Clarizen - US Production',
	},
	adaptiveworkProdEUURL: {
		id: 'home.productTile.modal.addProduct.adaptivework.prodEUURL',
		description: 'Text for adaptivework connect URL for EU prod instance',
		defaultMessage: 'https://eu1.clarizen.com/Clarizen - EU Production',
	},
	adaptiveworkSandboxUSURL: {
		id: 'home.productTile.modal.addProduct.adaptivework.sandboxUSURL',
		description:
			'Text for adaptivework connect URL for US sandbox instance',
		defaultMessage: 'https://app.clarizentb.com/Clarizen - US Sandbox',
	},
	adaptiveworkSandboxEUURL: {
		id: 'home.productTile.modal.addProduct.adaptivework.sandboxEUURL',
		description:
			'Text for adaptivework connect URL for EU sandbox instance',
		defaultMessage: 'https://eu.clarizentb.com/Clarizen - EU Sandbox',
	},
	continue: {
		id: 'home.productTile.modal.continue.button',
		description: 'Continue button text',
		defaultMessage: 'Continue',
	},
	add: {
		id: 'home.productTile.modal.add.button',
		description: 'Add button text',
		defaultMessage: 'Add',
	},
	addNewInstanceModalText: {
		id: 'home.productTile.modal.addNewInstanceModalText',
		description:
			'Label to add a new instance/tenant of an app like Roadmaps/IdeaPlace that does not need a URL. This is currently hidden behind feature flag and not visible to users or translators',
		defaultMessage: 'Add a new instance of {appName}',
	},
	contactServiceModalText: {
		id: 'home.productTile.modal.contactServiceModalText',
		description:
			'Label to direct customers to contact their service rep to add product',
		defaultMessage:
			'A subscription is required to add {appName}. Please reach out to your Planview representative to update your subscription preferences.',
	},
	addSandbox: {
		id: 'home.productTile.modal.addSandbox',
		description:
			'checkbox label to create a sandbox in the addNewInstanceModal',
		defaultMessage: 'Create as sandbox',
	},
	addSuccess: {
		id: 'home.productTile.modal.add.success',
		description:
			'Success message if connection URL to a product was successfully retrieved. This will be shown in the app, but most likely not seen by the user because we open a new window with the product URL',
		defaultMessage:
			'Add URL for the product has been retrieved. Please sign in to the product instance in the new window to continue.',
	},
	addProductSuccess: {
		id: 'home.productTile.modal.add.addProductSuccess',
		description:
			'Success message when adding a product like IdeaPlace or Roadmaps',
		defaultMessage: 'Product has been added successfully.',
	},
	multipleProductionInstancesWarning: {
		id: 'home.productTile.modal.multipleProductionInstancesWarning',
		description:
			'Help message to inform users on the restrictions regarding multiple production instances. This message is hidden whenever the associated feature flag is enabled.',
		defaultMessage:
			'You are only allowed to add a single production instance of any given tenant application.',
	},
	generalDescription: {
		id: 'home.productTile.modal.generalDescription',
		description: 'General description of what adding a product does',
		defaultMessage:
			'Adding this product to Planview Admin will import its users into Planview Admin and add a tile to the Products page.',
	},
	emailOnSsoOnDescription: {
		id: 'home.productTile.modal.emailOnSsoOnDescription',
		description:
			'Description of what happens when email notifications are on and SSO is on',
		defaultMessage:
			'A welcome email will be sent to all users of this product. You can disable these automated emails in your Settings.',
	},
	emailOnSsoOffDescription: {
		id: 'home.productTile.modal.emailOnSsoOffDescription',
		description:
			'Description of what happens when email notifications are on and SSO is off',
		defaultMessage:
			'A welcome email will be sent to all users of this product. Any users new to Planview Admin will need to set their passwords using the link in the welcome email.',
	},
	authDescription: {
		id: 'home.productTile.modal.authDescription',
		description:
			'Description of how to enable authentication for the product. Do not translate <strong> tags.',
		defaultMessage:
			'To enable Planview Admin authentication for this product, click <strong>Manage product</strong> on the product tile.',
	},
});

export default messages;
