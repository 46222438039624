import React, { useContext } from 'react';
import { AppContext } from '../../../context';
import { useIntl } from 'react-intl';
import messages from './Input.messages';
import { ToastType } from '../../../types/toast';
import { Input } from '@planview/pv-form';
import { ButtonEmpty } from '@planview/pv-uikit';
import { Copy } from '@planview/pv-icons';
import styled from 'styled-components';

type CopyableInputProps = {
	value: string;
	label?: string;
};

const InputButtonWrapper = styled.div`
	display: flex;
	flex-direction: row;
`;

const CopyButtonWrapper = styled.div`
	align-self: flex-end;
	margin-bottom: 11px;
`;

/**
 * Input box with an accompanying copy to clipboard button
 *
 * @component
 * @param {Object} props - The properties of the component.
 * @param {*} props.value - The value to be copied to the clipboard.
 *
 * @returns {JSX.Element} The CopyableInput component.
 */
const CopyableInput = (props: CopyableInputProps): JSX.Element => {
	const appContext = useContext(AppContext);
	const intl = useIntl();

	const copy = async () => {
		await navigator.clipboard.writeText(props.value);

		const message = intl.formatMessage(messages.copyToClipboardMessage);
		appContext.showToast({
			message,
			type: ToastType.SUCCESS,
		});
	};

	return (
		<InputButtonWrapper>
			<Input readOnly={true} {...props} style={{ flexGrow: 1 }} />
			<CopyButtonWrapper>
				<ButtonEmpty
					icon={<Copy />}
					tooltip={intl.formatMessage(messages.copyButton)}
					onClick={copy}
				/>
			</CopyButtonWrapper>
		</InputButtonWrapper>
	);
};

export default CopyableInput;
