import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { spacingPx } from '@planview/pv-utilities';
import { MODAL_SMALL } from '@planview/pv-uikit';
import { Modal } from '../../../components/common/modal/Modal';
import { AppContext } from '../../../context';
import { preventEventBubbling } from '../../common/PreventEventBubbling';
import { ToastType } from '../../../types/toast';
import { Customer } from '../../../types';
import { Input } from '@planview/pv-form';

const FieldLabel = styled.div`
	margin-bottom: ${spacingPx.medium};
`;

type DeleteConfirmationDialogProps = {
	onConfirm: () => void;
	onCancel: () => void;
	organization: Customer;
};

const DeleteConfirmationDialog = (props: DeleteConfirmationDialogProps) => {
	const appContext = useContext(AppContext);
	const [deletePrompt, setDeletePrompt] = useState('');
	const { onConfirm, onCancel, organization } = props;

	const confirmHandler = () => {
		if (deletePrompt !== 'delete' && deletePrompt !== '"delete"') {
			appContext.showToast({
				message:
					'Please type "delete" in the input field to confirm deletion.',
				type: ToastType.DANGER,
			});
		} else {
			onConfirm();
		}
	};

	return (
		<Modal
			onConfirm={confirmHandler}
			onCancel={onCancel}
			confirmText="Yes"
			cancelText="No"
			headerText="Delete Organization?"
			size={MODAL_SMALL}
			{...preventEventBubbling}
		>
			<FieldLabel>
				{`Are you sure you want to delete the organization "${organization.title}"?`}
			</FieldLabel>
			<Input
				label='To confirm, type the word "delete" below'
				onChange={(value) => setDeletePrompt(value)}
				withAsterisk={true}
				disabled={false}
			/>
		</Modal>
	);
};

export default DeleteConfirmationDialog;
