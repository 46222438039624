import React, { ReactElement, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import {
	border,
	theme,
	cursor,
	overflow,
	sizePx,
	spacingPx,
} from '@planview/pv-utilities';
import Toolbar from './toolbar/Toolbar';
import AppLogo from '../../common/appLogo/AppLogo';
import {
	AppContext,
	CustomerContext,
	ProductTileContext,
	UserContext,
} from '../../../context';
import { head } from '../../../hooks/request/request';
import messages from './ProductTile.messages';
import EnvironmentTag from '../../common/environmentTag';
import { Application } from '../../../types';
import { ApiResponse } from '../../../types/api/api';
import { DotsVertical, Hidden } from '@planview/pv-icons';
import {
	ButtonEmpty,
	DropdownMenu,
	ListItem,
	Tooltip,
} from '@planview/pv-uikit';
import { AvailableApplication } from '../../../context/appContext';
import { ToastType } from '../../../types/toast';
import TileIndicator from './TileIndicator';

export const Tile = styled.div`
	${border.normal()};
	background-color: ${theme.backgroundNeutral0};
	display: flex;
	flex-direction: column;
	margin: ${spacingPx.small} ${spacingPx.small} 0 0;
	padding: 15px;
	width: 280px;
	min-height: 100px;

	&.mapped {
		${cursor.pointer};
	}
`;

export const Header = styled.div`
	display: flex;
	flex-direction: row;
	min-height: 60px;
`;

export const LogoContainer = styled.div`
	flex-grow: 1;
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
`;

const Slogan = styled.div`
	flex-grow: 1;
	margin-right: auto;
	color: ${theme.textPlaceholder};
	font-size: 14px;
	line-height: 1.54;
	padding: ${spacingPx.small};
`;

const Description = styled.div`
	font-size: 14px;
	color: ${theme.textPlaceholder};
	line-height: 1.54;
	${overflow.ellipsis};
`;

const UserDescription = styled(Description)`
	color: ${theme.textPrimary};
	font-size: ${sizePx.xsmall};
`;

const TagContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: ${spacingPx.xlarge};
	padding-top: ${spacingPx.small};
	padding-bottom: ${spacingPx.medium};
`;

interface Action {
	text: string;
	handler: () => void;
	icon?: ReactElement;
}

interface TileContentProps extends ProductTileProps {
	application: Application | AvailableApplication;
	tileType: 'mapped' | 'owned';
	headerActions?: Action[];
}

type MappedTileProps = {
	application: Application;
};

/**
 * Determines if Application is a real tenant application, not an AvailableApplication
 */
function isApplication(
	application: Application | AvailableApplication,
): application is Application {
	return (application as Application).envSelectorString !== undefined;
}

/**
 * Tile Content can include -
 * 1. Product Description/slogan for "owned" products
 * 2. Description containing the product mapping details for "mapped" products
 * 		a. Domain for the tenant mapping
 * 		b. Username	if the user has an account (or info text if the admin user has no account/username)
 * 		c. User description (Admin sets the description)
 * @param {Object} props - props for this component
 * @return {React.Component} TileContent component that displays info about the product or tenant mapping
 */
const TileContent: React.FC<TileContentProps> = (props) => {
	const intl = useIntl();
	const customerContext = useContext(CustomerContext);
	const { enableHiddenIndicator } = useContext(ProductTileContext);
	const { application, headerActions, tileType = 'mapped' } = props;
	const { app } = application;

	const tenant = isApplication(application)
		? customerContext.tenantsByEnvSelector &&
			customerContext.tenantsByEnvSelector[application.envSelectorString]
		: undefined;

	const MappedTileContent = ({ application }: MappedTileProps) => {
		const { domain, title, sandbox, showPrimaryLabel, serviceApp } =
			application;

		return (
			<Content>
				<UserDescription title={title}>{title}</UserDescription>
				<Description title={domain}>{domain}</Description>
				<TagContainer>
					{!serviceApp && (
						<EnvironmentTag
							sandbox={sandbox}
							secondarySandboxIsPrimary={showPrimaryLabel}
						/>
					)}
				</TagContainer>
			</Content>
		);
	};

	return (
		<>
			<Header>
				<LogoContainer>
					<AppLogo app={app} />
				</LogoContainer>
				{headerActions && headerActions.length && (
					<DropdownMenu
						label="test"
						trigger={(triggerProps) => (
							<ButtonEmpty
								{...triggerProps}
								aria-label={intl.formatMessage(
									messages.optionsLabel,
								)}
								onClick={(event) => event.stopPropagation()}
								icon={<DotsVertical />}
							/>
						)}
					>
						{headerActions.map((action) => (
							<ListItem
								key={action.text}
								icon={action.icon}
								label={action.text}
								onActivate={() => action.handler()}
							/>
						))}
					</DropdownMenu>
				)}
				{enableHiddenIndicator &&
					(application as Application).hideFromOverview && (
						<Tooltip
							text={intl.formatMessage(
								messages.tileHiddenFromOverviewTooltip,
							)}
						>
							<Hidden />
						</Tooltip>
					)}
				<TileIndicator tenant={tenant} />
			</Header>
			{tileType === 'mapped' ? (
				<MappedTileContent application={application as Application} />
			) : null}
			{tileType === 'owned' ? (
				<Slogan>
					{intl.formatMessage(messages[`${app.toLowerCase()}`])}
				</Slogan>
			) : null}
		</>
	);
};

export interface ProductTileProps {
	application: Application | AvailableApplication;
	tileType: 'mapped' | 'owned';
	editMode?: boolean;
	headerActions?: Action[];
}

/**
 * Product Tile containing product logo, description and action buttons
 *
 * Assumptions -
 * 1. "owned" tile type can only be displayed for an admin.
 * 2. "mapped" tile for end-user will have -
 * 		a. No toolbar on Overview page
 * 		b. Toolbar with Actions menu button with "Edit Product Description" menu item
 * 3. "mapped" tile for an admin will have -
 * 		a. No toolbar on Overview page (when user has an account or username for the tenant)
 * 		b. Toolbar with "Manage Product" button and Actions menu button with
 * 		"Sync users" and "Edit Product Description" menu  items
 *
 * Note - All the tiles shown in OverviewPage need to have ssoEnabled on the application and be mapped to the user.
 * (ssoEnabled check is handled in OverviewPage.tsx, ProductTile does not know about SSOEnabled flag.)
 *
 * @param {ProductTileProps} props - props for this component
 * @return {React.Component} ProductTile component that can be clicked on and used to edit/connect via Oauth
 */
const ProductTile: React.FC<ProductTileProps> = (props) => {
	const intl = useIntl();
	const userContext = useContext(UserContext);
	const appContext = useContext(AppContext);
	const navigate = useNavigate();
	const { application, editMode = false, tileType = 'mapped' } = props;

	const useSuccessDomain =
		application.app == 'SUCCESS' && !appContext.featureFlags.enableSuccess;

	const className = editMode ? `${tileType}-edit` : tileType;
	return (
		<Tile
			data-testid="tile"
			className={className}
			onClick={async () => {
				if (
					tileType === 'mapped' &&
					!editMode &&
					!userContext.hasImpersonator()
				) {
					const win = window.open() as Window;
					const { success } = (await head(
						'/api/login',
					)) as ApiResponse;
					if (success) {
						if (isApplication(application)) {
							const { custom, domain, navigateUrl, serviceApp } =
								application;
							win.opener = null;
							win.location.assign(
								custom && (!serviceApp || useSuccessDomain)
									? `https://${domain}`
									: navigateUrl,
							);
						}
					} else {
						win.close();
						appContext.showToast({
							message: intl.formatMessage(messages.logoutError),
							type: ToastType.DANGER,
						});
						navigate('/', { state: { redirect: true } });
					}
				}
			}}
		>
			<TileContent {...props} />
			<Toolbar {...props} editMode={editMode} />
		</Tile>
	);
};

export default ProductTile;
