import { useEffect, useRef, useState } from 'react';

declare global {
	interface Window {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		Dovetail: any;
	}
}

type DovetailComponentLibrary = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	components: Record<string, any>;
	config: DovetailConfiguration;
};

type DovetailConfiguration = {
	environment: string;
	platformaAuthTokenClosure: () => void;
	product: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let DovetailLib: any;
const createLoaderFn = () => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const promises = new Map<string, Promise<any>>();

	return (src: string, window: Window) => {
		let promise = promises.get(src);
		if (!promise) {
			promise = new Promise((resolve, reject) => {
				const script = window.document.createElement('script');
				script.src = src;
				script.onload = () => {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
					DovetailLib = window.Dovetail;
					resolve(DovetailLib);
				};
				script.onerror = (e) => {
					reject(e);
				};
				window.document.body.appendChild(script);
			});
			promises.set(src, promise);
		}
		return promise;
	};
};

let loader = createLoaderFn();

const useDovetail = (
	url: string,
	componentName: string,
	options: object,
	window = global.window,
): DovetailComponentLibrary => {
	// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
	const constructorLoaded = !!(DovetailLib && DovetailLib[componentName]);
	const [loaded, setLoaded] = useState(constructorLoaded);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const Dovetail = useRef<any>(null);

	if (!Dovetail.current && constructorLoaded) {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
		Dovetail.current = new DovetailLib[componentName](options);
	}

	useEffect(() => {
		if (!loaded) {
			loader(url, window).then(
				(lib) => {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
					Dovetail.current = new lib[componentName](options);
					setLoaded(true);
				},
				(err: Error) => {
					window.console.error(
						'Could not load Dovetail library',
						err,
					); // eslint-disable-line no-console
				},
			);
		}
	}, [loaded, url, componentName, options]); // eslint-disable-line

	// eslint-disable-next-line @typescript-eslint/no-unsafe-return
	return Dovetail.current;
};

// Hooks do not work with jest.isolateModules or ability to re-load modules
export const resetModuleForTest = () => {
	DovetailLib = null;
	loader = createLoaderFn();
};

export default useDovetail;
