/* eslint-disable max-statements */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import messages from './UsersPage.messages';
import UsersGrid from '../../../components/admin/UsersGrid';
import {
	Toolbar,
	ToolbarButtonEmpty,
	ToolbarSection,
	ToolbarSeparator,
} from '@planview/pv-toolbar';
import { AppContext, CustomerContext, UserContext } from '../../../context';
import GridActionsMenu from '../actionsMenu/GridActionsMenu';
import ToolbarActionsMenu from '../actionsMenu/ToolbarActionsMenu';
import useActions from './hooks/useActions';
import UsersFilter from './UsersFilter';
import {
	FilterContainer,
	ListWrapper,
	MainContainer,
} from '../../../components/common/Wrappers';
import { Filter, PlusCircle } from '@planview/pv-icons';
import filterMessages from '../../../components/common/filter/Filter.messages';
import { useDebounce } from '../../../hooks/useDebounce';
import NewUserDialog from '../newUserDialog/NewUserDialog';
import { DISMISS_AFTER_TEN_SECONDS } from '../../../components/common/toast/Toast';
import { requestWithErrorHandling } from '../../../hooks/request/request';
import { getUrl } from '../../../components/common/grid/dataLoader';
import { UserTabs } from './UsersTabsPage';
import { FilterBanner } from '@planview/pv-filter';
import { ToastType } from '../../../types/toast';

const Container = styled.div`
	flex-grow: 1;
	min-height: 0;
	display: flex;
`;

const UsersPage = ({ isCustomerCare, mode }) => {
	const intl = useIntl();
	const appContext = useContext(AppContext);
	const userContext = useContext(UserContext);
	const customerContext = useContext(CustomerContext);
	const grid = useRef();
	const hasSeenProvisioningErrors = useRef(false);
	const [filterVisible, setFilterVisible] = useState(false);
	const [filterParams, setFilterParams] = useState({});
	const [selectedItems, setSelectedItems] = useState([]);
	const [searchValue, setSearchValue] = useState('');
	const [lastSearchValue, setLastSearchValue] = useState('');
	const [showNewUserDialog, setShowNewUserDialog] = useState(false);

	const deactivatedFlag = mode === UserTabs.CURRENT_USERS ? false : true;

	// if customerCare, use the customerContext, otherwise use the userContext for the current customer
	const customer = isCustomerCare
		? customerContext.customer
		: userContext.customer;
	const refresh = () => grid.current.refresh();

	const provisioningCheck =
		mode === UserTabs.CURRENT_USERS &&
		customer.topDownUserManagementEnabled;

	const { menuActions, toolbarActions, modals } = useActions({
		refresh,
		isCustomerCare,
		filterParams,
		customer,
		mode,
	});

	const activeFilters =
		Object.keys(filterParams).length > 0 || !!lastSearchValue;
	const debouncedSearchValue = useDebounce(searchValue, 750);
	useEffect(
		() => {
			if (lastSearchValue !== debouncedSearchValue) {
				setLastSearchValue(debouncedSearchValue);
				grid.current.triggerSearch(debouncedSearchValue);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[debouncedSearchValue],
	);

	const handleFilterChange = (key, value) => {
		const newParams = { ...filterParams };
		if (value === null || (Array.isArray(value) && value.length === 0)) {
			delete newParams[key];
		} else {
			newParams[key] = value;
		}
		setFilterParams(newParams);
		grid.current.setFilterParams(newParams);
	};

	const showProvisioningErrorsToast = () => {
		const filterGridForErrors = () =>
			handleFilterChange('provisionStatus', ['ERROR']);
		appContext.showToast({
			dismissAfter: DISMISS_AFTER_TEN_SECONDS,
			message: intl.formatMessage(messages.provisioningErrors),
			actions: [
				{
					label: intl.formatMessage(messages.seeDetails),
					onClick: filterGridForErrors,
				},
			],
			type: ToastType.DANGER,
		});
	};

	useEffect(() => {
		const checkForProvisioningErrors = async () => {
			const isViewingOwnCustomer =
				customer.id === userContext.customer.id;
			const path = isViewingOwnCustomer
				? '/io/v1/user'
				: `/io/v1/admin/user?customerId=${customer.id}`;
			const url = getUrl({
				url: path,
				limit: 1,
				filterParams: {
					provisionStatus: ['ERROR'],
				},
				deactivated: deactivatedFlag,
			});
			const { results } = await requestWithErrorHandling({
				method: 'get',
				url,
				appContext,
				intl,
			});

			if (results?.length && !hasSeenProvisioningErrors.current) {
				hasSeenProvisioningErrors.current = true;
				showProvisioningErrorsToast();
			}
		};

		if (provisioningCheck) {
			checkForProvisioningErrors();
		}

		// Only run this once on page load
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleClearAllClick = () => {
		setFilterParams({});
		setSearchValue('');
		setLastSearchValue('');
		grid.current.resetFiltering({});
	};

	const handleClearSearchClick = () => {
		setSearchValue('');
		setLastSearchValue('');
		grid.current.triggerSearch('');
	};

	const handleCloseFilterClick = () => {
		setFilterVisible(false);
	};

	const onRefresh = ({ data }) => {
		const areProvisioningErrors = data.some(
			(user) => user.provisionStatus?.toUpperCase() === 'ERROR',
		);
		if (
			areProvisioningErrors &&
			!hasSeenProvisioningErrors.current &&
			provisioningCheck
		) {
			hasSeenProvisioningErrors.current = true;
			showProvisioningErrorsToast();
		}

		setSelectedItems(grid.current.getSelectedRows());
	};

	const filterTooltip = intl.formatMessage(
		filterMessages.filterButtonTooltip,
	);

	const selectionType = toolbarActions.length ? 'checkbox' : '';

	return (
		<React.Fragment>
			<Toolbar label="User Admin Toolbar">
				<ToolbarSection>
					<ToolbarButtonEmpty
						data-testid="toggle-filters"
						aria-label={filterTooltip}
						icon={<Filter />}
						activated={filterVisible}
						onClick={() => setFilterVisible(!filterVisible)}
						tooltip={filterTooltip}
					/>
					{mode !== UserTabs.DEACTIVATED_USERS ? (
						<>
							<ToolbarSeparator />
							<ToolbarButtonEmpty
								icon={<PlusCircle />}
								onClick={() => setShowNewUserDialog(true)}
								tooltip={intl.formatMessage(messages.addUser)}
							>
								{intl.formatMessage(messages.addUser)}
							</ToolbarButtonEmpty>
						</>
					) : null}
				</ToolbarSection>
				<ToolbarSection>
					{toolbarActions.length ? (
						<ToolbarActionsMenu
							rowData={selectedItems}
							actions={toolbarActions}
							message={messages.actionsButton}
							alignRight={true}
							hideDisabled={false}
						/>
					) : null}
				</ToolbarSection>
			</Toolbar>
			<Container>
				<FilterContainer
					aria-hidden={!filterVisible}
					aria-label="Filter Panel"
					$filterVisible={filterVisible}
				>
					{filterVisible && (
						<UsersFilter
							enableClearFilter={activeFilters}
							onClearFilterClick={handleClearAllClick}
							onClearSearchClick={handleClearSearchClick}
							onCloseFilterClick={handleCloseFilterClick}
							filterParams={filterParams}
							onChange={handleFilterChange}
							searchValue={searchValue}
							setSearchValue={(value) => {
								if (!value) {
									handleClearSearchClick();
								} else {
									setSearchValue(value);
								}
							}}
							isCustomerCare={isCustomerCare}
							mode={mode}
						/>
					)}
				</FilterContainer>
				<MainContainer $filterVisible={filterVisible}>
					{activeFilters ? (
						<FilterBanner
							matching={0}
							total={0}
							onClear={handleClearAllClick}
						/>
					) : null}
					<ListWrapper $activeFilters={activeFilters}>
						<UsersGrid
							data-testid="user-grid"
							actionsMenu={GridActionsMenu}
							actions={menuActions}
							customer={customer}
							filterParams={filterParams}
							deactivated={deactivatedFlag}
							showPasswordColumn={
								customer.ssoEnabled || isCustomerCare
							}
							selectionType={selectionType}
							onSelectionChange={(items) =>
								setSelectedItems(items)
							}
							onRefresh={onRefresh}
							clearFilters={handleClearAllClick}
							innerRef={(ref) => {
								grid.current = ref;
							}}
							mode={mode}
						/>
					</ListWrapper>
				</MainContainer>
			</Container>
			{showNewUserDialog ? (
				<NewUserDialog
					customer={customer}
					isCustomerCare={isCustomerCare}
					onCancel={() => setShowNewUserDialog(false)}
					onConfirm={() => {
						refresh();
						setShowNewUserDialog(false);
					}}
				/>
			) : null}
			{modals}
		</React.Fragment>
	);
};

export default UsersPage;
