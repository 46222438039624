import { defineMessages } from 'react-intl';

const messages = defineMessages({
	settingsButton: {
		id: 'admin.settings.settingsButton',
		description: 'Button text for the settings tile Settings button',
		defaultMessage: 'Settings',
	},
});

export default messages;
