import { EnvSelector, User } from '../index';

export interface Role {
	id: string;
	displayText: string;
	readOnly: boolean;
}

export interface AssignUserTenantRoleDto {
	userId: string;
	envSelector: EnvSelector;
	role: string;
}

export interface UserWithRole extends User {
	role: string;
}

export const hasReadOnlyRole = (users: UserWithRole[], roles: Role[]) => {
	let hasReadOnlyRole = false;

	if (roles == undefined) {
		return hasReadOnlyRole;
	}
	// one of the users has a read only role? disable action
	const readOnlyRoles = roles
		.filter((role) => role.readOnly)
		.map((role) => role.id);

	if (readOnlyRoles.length != 0) {
		for (let i = 0; i < readOnlyRoles.length; i++) {
			const userWithReadOnlyRole = users.find(
				(user) => user.role === readOnlyRoles[i],
			);
			if (userWithReadOnlyRole) {
				hasReadOnlyRole = true;
				break;
			}
		}
	}
	return hasReadOnlyRole;
};
