import React, { useContext, useState } from 'react';
import messages from '../Impersonate.messages';
import styled from 'styled-components';
import { spacingPx } from '@planview/pv-utilities';
import { requestWithErrorHandling } from '../../../../hooks/request/request';
import { IntlShape, useIntl } from 'react-intl';
import {
	AppContext,
	AppContextProps,
	CustomerContext,
	UserContext,
} from '../../../../context';
import { Modal } from '../../../../components/common/modal/Modal';
import { Checkbox, Textarea } from '@planview/pv-uikit';
import { User } from '../../../../types';
import {
	ImpersonateDto,
	ImpersonateResponse,
} from '../../../../types/api/impersonate';

const FieldLabel = styled.div`
	margin-bottom: ${spacingPx.medium};
`;

const Body = styled.div`
	max-width: 400px;
`;

const makeRequest = async (
	intl: IntlShape,
	appContext: AppContextProps,
	dataObj: ImpersonateDto,
) => {
	appContext.setLoginPollingEnabled(false);

	const { success, url } =
		await requestWithErrorHandling<ImpersonateResponse>({
			method: 'post',
			url: `/io/v1/admin/impersonate`,
			dataObj,
			appContext,
			intl,
			successMessage: messages.impersonateSuccess,
		});

	if (success) {
		window.location.href = url;
	}
};

type ConfirmationDialogProps = {
	user: User;
	onCancel: () => void;
};
const ConfirmationDialog = ({ user, onCancel }: ConfirmationDialogProps) => {
	const intl = useIntl();
	const userContext = useContext(UserContext);
	const customerContext = useContext(CustomerContext);
	const appContext = useContext(AppContext);
	const [reason, setReason] = useState('');
	const [hasPermission, setHasPermission] = useState(false);
	const [isSaving, setIsSaving] = useState(false);

	const isValid = hasPermission && Boolean(reason);

	const confirmHandler = async () => {
		const customerId = user?.customer?.id || customerContext.customer.id;
		setIsSaving(true);
		await makeRequest(intl, appContext, {
			userId: user.id,
			customerId,
			reason,
		});
		setIsSaving(false);
	};

	return (
		<Modal
			onConfirm={confirmHandler}
			onCancel={onCancel}
			confirmText={intl.formatMessage(messages.yesButton)}
			cancelText={intl.formatMessage(messages.noButton)}
			headerText={intl.formatMessage(messages.impersonateHeader)}
			disableConfirm={!isValid || isSaving}
			initialFocusId={'reason-input'}
		>
			<Body>
				<FieldLabel>
					{intl.formatMessage(messages.impersonationContent, {
						name: userContext.getUserFullName(user),
					})}
				</FieldLabel>
				<Textarea
					id={'reason-input'}
					value={reason}
					placeholder={intl.formatMessage(
						messages.impersonationReason,
					)}
					onChange={(value) => setReason(value)}
				/>
				<Checkbox
					selected={hasPermission}
					label={intl.formatMessage(
						messages.hasPermissionToImpersonate,
					)}
					onChange={(checked) => setHasPermission(checked)}
				/>
			</Body>
		</Modal>
	);
};

const useImpersonateUserAction = () => {
	const [impersonateUser, setImpersonateUser] = useState<User | null>(null);
	const impersonateAction = {
		message: messages.impersonate,
		isEnabled: (rowData: User[]) =>
			rowData.length === 1 && rowData[0].isActive,
		activateFn: (rowData: User[] | User) => {
			if (Array.isArray(rowData)) {
				setImpersonateUser(rowData[0]);
			} else if (rowData.id) {
				setImpersonateUser(rowData);
			}
		},
	};

	const impersonateModal = impersonateUser ? (
		<ConfirmationDialog
			key="impersonateDialog"
			user={impersonateUser}
			onCancel={() => setImpersonateUser(null)}
		/>
	) : null;

	return { impersonateAction, impersonateModal };
};

export default useImpersonateUserAction;
