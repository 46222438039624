import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { ListItem } from '@planview/pv-uikit';
import { requestWithErrorHandling } from '../../../hooks/request/request';
import { AppContext, UserContext } from '../../../context';
import messages from '../actionsMenu/ActionsMenu.messages';
import { DotsVertical } from '@planview/pv-icons';
import { GridCellDropdownMenu } from '@planview/pv-grid';

const handleRemoveMappingClick = async ({
	intl,
	app,
	appContext,
	userIds,
	refresh,
}) => {
	await requestWithErrorHandling({
		method: 'post',
		url: `/io/v1/user/unmap/${app.envSelectorEncodedString}`,
		dataObj: { userIds },
		appContext,
		intl,
		successMessage: messages.removeUserMappingSuccess,
	});

	refresh();
};

const handleSetRole = async ({
	intl,
	app,
	appContext,
	userIds,
	refresh,
	role,
}) => {
	const dataObj = userIds.map((userId) => ({
		userId,
		envSelector: app.envSelectorEncodedString,
		role: role.id,
	}));

	await requestWithErrorHandling({
		method: 'post',
		url: `/io/v1/user/role`,
		dataObj,
		appContext,
		intl,
		successMessage: intl.formatMessage(messages.setRoleSuccess, {
			role: role.displayText,
		}),
	});

	refresh();
};

const getRoleDropdownItems = ({ intl, app, appContext, userData, refresh }) => {
	return app.roles
		.filter(({ id }) => id !== userData.role)
		.filter(({ readOnly }) => !readOnly)
		.map((role) => {
			const { id, displayText } = role;
			const text = intl.formatMessage(messages.setRole, {
				role: displayText,
			});
			return (
				<ListItem
					key={id}
					label={text}
					onActivate={() =>
						handleSetRole({
							intl,
							app,
							appContext,
							userIds: [userData.id],
							refresh,
							role,
						})
					}
				/>
			);
		});
};

const ActionsMenu = ({ app, rowData, refresh }) => {
	const appContext = useContext(AppContext);
	const userContext = useContext(UserContext);
	const intl = useIntl();
	const { formatMessage } = intl;
	const currentLoggedInUser = userContext.user.id === rowData.id;
	const roles = app.roles || [];

	const readOnlyRoles = roles
		.filter(({ readOnly }) => readOnly)
		.map(({ id }) => id);
	const hasReadOnlyRole = readOnlyRoles.includes(rowData.role);
	const showRoleOption =
		roles.length && app.foundationApp && !hasReadOnlyRole;

	const showRemoveMappingOption =
		!currentLoggedInUser && !app.mappedToAllUsers && !hasReadOnlyRole;
	const showMenu = showRoleOption || showRemoveMappingOption;

	const trigger = {
		tooltip: formatMessage(messages.actionsToolTip),
		icon: <DotsVertical />,
	};

	return showMenu ? (
		<>
			<GridCellDropdownMenu alignRight={true} trigger={trigger}>
				{showRoleOption
					? getRoleDropdownItems({
							intl,
							app,
							appContext,
							userData: rowData,
							refresh,
						})
					: null}
				{showRemoveMappingOption ? (
					<ListItem
						label={formatMessage(messages.removeMapping)}
						onActivate={() =>
							handleRemoveMappingClick({
								intl,
								app,
								appContext,
								userIds: [rowData.id],
								refresh,
							})
						}
					/>
				) : null}
			</GridCellDropdownMenu>
		</>
	) : null;
};

ActionsMenu.displayName = 'ActionsMenu';
export default ActionsMenu;
