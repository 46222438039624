import React, { useContext } from 'react';
import { UserContext } from '../../../context';
import {
	Avatar as PVUIKitAvatar,
	AVATAR_SIZE_LARGE,
	AVATAR_SIZE_MEDIUM,
	AVATAR_SIZE_SMALL,
	AVATAR_SIZE_XLARGE,
} from '@planview/pv-uikit';
import { User } from '../../../types';

/**
 * @param {User} user the user record
 * @returns {String} initials of first and last name, as best we can determine
 */
const getInitials = (user: User | Record<string, never>): string => {
	let firstInitial = '';
	let lastInitial = '';

	if (user) {
		if (user.firstName) {
			firstInitial = user.firstName.substring(0, 1);
		}
		if (user.lastName) {
			lastInitial = user.lastName.substring(0, 1);
		}
	}

	return (firstInitial + lastInitial).toUpperCase();
};

type AvatarSize =
	| typeof AVATAR_SIZE_SMALL
	| typeof AVATAR_SIZE_MEDIUM
	| typeof AVATAR_SIZE_LARGE
	| typeof AVATAR_SIZE_XLARGE;

type AvatarProps = {
	size?: AvatarSize;
};

const Avatar = (props: AvatarProps) => {
	const { size = AVATAR_SIZE_MEDIUM, ...rest } = props;
	const userContext = useContext(UserContext);
	const initials = getInitials(userContext.user);
	return <PVUIKitAvatar size={size} initials={initials} {...rest} />;
};

export default Avatar;
