import React from 'react';
import { theme } from '@planview/pv-utilities';
import messages from '../../../context/GlobalState.messages';
import { useIntl } from 'react-intl';
import { Tag } from '@planview/pv-uikit';

type EnvironmentTagProps = {
	sandbox: boolean;
	secondarySandboxIsPrimary?: boolean;
	secondarySandboxLabel?: string;
};

const EnvironmentTag = (props: EnvironmentTagProps) => {
	const { sandbox, secondarySandboxIsPrimary, secondarySandboxLabel } = props;
	const intl = useIntl();

	let tag;
	if (!sandbox) {
		tag = (
			<Tag
				color={theme.primary500}
				label={intl.formatMessage(messages.production)}
			/>
		);
	} else {
		if (secondarySandboxIsPrimary === true) {
			tag = (
				<Tag
					color={theme.warning500}
					label={intl.formatMessage(messages.primarySandbox)}
				/>
			);
		} else {
			tag = (
				<Tag
					color={theme.warning500}
					label={
						secondarySandboxLabel
							? secondarySandboxLabel
							: intl.formatMessage(messages.sandbox)
					}
				/>
			);
		}
	}
	return <>{tag}</>;
};

export default EnvironmentTag;
