import { User } from '../../../../types';

const getUserIds = (users: User[]): string[] => {
	if (!Array.isArray(users)) {
		users = [users];
	}
	return users.map(({ id }) => id);
};

export { getUserIds };
