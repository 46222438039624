import { defineMessages } from 'react-intl';

const messages = defineMessages({
	impersonate: {
		id: 'pvadmin.users.impersonate',
		description: 'Impersonate user menu item text',
		defaultMessage: 'Impersonate user',
	},
	impersonateSuccess: {
		id: 'pvadmin.users.impersonate.success',
		description: 'Success message shown on Impersonate user request',
		defaultMessage: 'Please wait while the app is reloaded',
	},
	yesButton: {
		id: 'pvadmin.users.impersonate.confirmation.yes',
		description: 'Button text for Yes',
		defaultMessage: 'Yes',
	},
	noButton: {
		id: 'pvadmin.users.impersonate.confirmation.no',
		description: 'Button text for No',
		defaultMessage: 'No',
	},
	impersonateHeader: {
		id: 'pvadmin.users.impersonate.confirmation.header',
		description: 'Header text for impersonate user confirmation dialog',
		defaultMessage: 'Impersonate User?',
	},
	impersonationContent: {
		id: 'pvadmin.users.impersonate.confirmation.content',
		description: 'Content for impersonate user confirmation dialog',
		defaultMessage:
			'Are you sure you want to impersonate the user "{name}"?',
	},
	impersonationReason: {
		id: 'pvadmin.users.impersonate.business.reason',
		description:
			'Placeholder text for business reason in impersonate user confirmation dialog',
		defaultMessage: 'Business purpose for impersonation',
	},
	hasPermissionToImpersonate: {
		id: 'pvadmin.users.impersonate.hasPermission',
		description:
			'Label for "Has Customer Permission" checkbox impersonate user confirmation dialog',
		defaultMessage:
			'I have received customer permission to impersonate, or that permission is not required for this purpose',
	},
});

export default messages;
