import React, { useContext } from 'react';
import { IntlShape, MessageDescriptor, useIntl } from 'react-intl';
import { AppContext, AppContextProps, UserContext } from '../../../../context';
import {
	ADMIN,
	CUSTOMER_CARE,
	SUPER_ADMIN,
	USER,
} from '../../../../context/userContext';
import messages from '../UsersPage.messages';
import { requestWithErrorHandling } from '../../../../hooks/request/request';
import { getUserIds } from './userUtils';
import { UserAdmin, UserAdminRemove } from '@planview/pv-icons';
import { User } from '../../../../types';

const makeRequest = async ({
	users,
	securityLevel,
	intl,
	appContext,
	refresh,
	successMessage,
	successMessagePlural,
}: {
	users: User[];
	securityLevel: string;
	intl: IntlShape;
	appContext: AppContextProps;
	refresh: () => void;
	successMessage: MessageDescriptor;
	successMessagePlural: MessageDescriptor;
}) => {
	const userIds = getUserIds(users);
	const successMessageString = intl.formatMessage(
		userIds.length === 1 ? successMessage : successMessagePlural,
		{
			count: userIds.length,
		},
	);

	await requestWithErrorHandling({
		method: 'post',
		url: `/io/v1/user/changeSecurityLevel/${securityLevel}`,
		dataObj: { userIds },
		appContext,
		intl,
		successMessage: successMessageString,
	});
	refresh();
};

const useChangeRoleActions = ({ refresh }: { refresh: () => void }) => {
	const intl = useIntl();
	const appContext = useContext(AppContext);
	const userContext = useContext(UserContext);
	const currentLoggedInUserId = userContext.user.id;
	const requestArgs = { intl, appContext, refresh };

	return {
		makeSuperAdminAction: {
			message: messages.makeSuperAdminItem,
			isEnabled: (users: User[]) => {
				return (
					users.length > 0 &&
					users.every(
						(user) =>
							!user.isSuperAdmin &&
							user.id !== currentLoggedInUserId,
					)
				);
			},
			activateFn: (users: User[]) =>
				makeRequest({
					users,
					securityLevel: SUPER_ADMIN,
					successMessage: messages.makeSuperAdminSuccess,
					successMessagePlural: messages.makeSuperAdminSuccessPlural,
					...requestArgs,
				}),
		},
		makeCustomerCare: {
			message: messages.makeCustomerCare,
			isEnabled: (users: User[]) => {
				return (
					users.length > 0 &&
					users.every(
						(user) =>
							(user.isSuperAdmin || !user.isAdmin) &&
							user.id !== currentLoggedInUserId,
					)
				);
			},
			activateFn: (users: User[]) =>
				makeRequest({
					users,
					securityLevel: CUSTOMER_CARE,
					successMessage: messages.makeCustomerCareSuccess,
					successMessagePlural:
						messages.makeCustomerCareSuccessPlural,
					...requestArgs,
				}),
		},
		makeAdminAction: {
			icon: <UserAdmin />,
			message: messages.makeAdminItem,
			isEnabled: (users: User[]) => {
				return (
					users.length > 0 &&
					users.every(
						(user) =>
							!user.isAdmin && user.id !== currentLoggedInUserId,
					)
				);
			},
			activateFn: (users: User[]) =>
				makeRequest({
					users,
					securityLevel: ADMIN,
					successMessage: messages.makeUserAdminSuccess,
					successMessagePlural: messages.makeUserAdminSuccessPlural,
					...requestArgs,
				}),
		},
		removeAdminAction: {
			icon: <UserAdminRemove />,
			message: messages.removeAdminItem,
			isEnabled: (users: User[]) => {
				return (
					users.length > 0 &&
					users.every(
						(user) =>
							user.isAdmin && user.id !== currentLoggedInUserId,
					)
				);
			},
			activateFn: (users: User[]) =>
				makeRequest({
					users,
					securityLevel: USER,
					successMessage: messages.removeUserAdminSuccess,
					successMessagePlural: messages.removeUserAdminSuccessPlural,
					...requestArgs,
				}),
		},
		// same as removeAdminAction, but with different strings
		makeUserAction: {
			message: messages.makeUser,
			isEnabled: (users: User[]) => {
				return (
					users.length > 0 &&
					users.every(
						(user) =>
							user.isAdmin && user.id !== currentLoggedInUserId,
					)
				);
			},
			activateFn: (users: User[]) =>
				makeRequest({
					users,
					securityLevel: USER,
					successMessage: messages.makeUserRoleSuccess,
					successMessagePlural: messages.makeUserRoleSuccessPlural,
					...requestArgs,
				}),
		},
	};
};

export default useChangeRoleActions;
