import { LOG_ERROR_API_URL, logError } from './request';

function RequestError(data) {
	this.message = data.message;
	this.success = data.success || false;
	this.aborted = data.aborted;
	this.data = data;
}

/**
 * Return error object for Axios requests (post/put)
 * @param error - exception during the request
 * @param {String} url   - url used for the call
 * @returns {RequestError} error object with the message key and success flag set
 */
export const getRequestErrorForAxios = (error, url) => {
	const { status, data } = error.response || {};
	if (typeof data !== 'object') {
		// don't accidentally trigger recursion trying to re-post errors that ocurr from logging an error to the server
		if (url !== LOG_ERROR_API_URL) {
			logError(
				`getRequestErrorForAxios - unexpected response format url=${url} status=${status} data=${data}`,
			);
		}

		return new RequestError({
			message:
				status === 403
					? 'Request was forbidden. Please refresh the page and try again.'
					: 'An unexpected error has occurred. Please refresh the page and try again.',
			success: false,
		});
	}
	return new RequestError(data);
};

/**
 * Return error object for fetch() (get/del)
 * @param error - exception during the request
 * @returns {RequestError} error object with the message key and success flag set
 */
export const getRequestErrorForFetch = (error) => {
	if (typeof error !== 'object') {
		logError(
			`getRequestErrorForFetch - unexpected error format: error=${error}`,
		);

		return new RequestError({
			message:
				'An unexpected error has occurred. Please refresh the page and try again.',
			success: false,
		});
	}

	const aborted = error.name === 'AbortError';
	return new RequestError({
		...error,
		aborted,
	});
};

export default RequestError;
