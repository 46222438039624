import { defineMessages } from 'react-intl';

const messages = defineMessages({
	provisionedOnMessage: {
		id: 'admin.productBox.dialog.provisionedOn',
		description: 'Text for provisioned on date in product box',
		defaultMessage: 'Provisioned on {date}',
	},
	licenseType: {
		id: 'admin.productBox.dialog.licenseType',
		description: 'Text label for subscription type combobox',
		defaultMessage: 'Subscription type',
	},
	role: {
		id: 'admin.productBox.dialog.role',
		description: 'Text label for role combobox',
		defaultMessage: 'Product role',
	},
	readOnlyMessage: {
		id: 'admin.productBox.dialog.tooltip.readOnlyMessage',
		description:
			'Tooltip message for readonly roles in the product checkbox',
		defaultMessage:
			'{roleName} is a fixed role. To modify this role, you must open a support case.',
	},
});

export default messages;
