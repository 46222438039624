import { defineMessages } from 'react-intl';

const messages = defineMessages({
	firstNameColumn: {
		id: 'admin.productDetails.unmapped.column.firstName',
		description: 'Label for First Name column for Users Grids',
		defaultMessage: 'First name',
	},
	lastNameColumn: {
		id: 'admin.productDetails.unmapped.column.lastName',
		description: 'Label for Last Name column for Users Grids',
		defaultMessage: 'Last name',
	},
	emailColumn: {
		id: 'admin.productDetails.unmapped.column.email',
		description: 'Label for Email column for Users Grids',
		defaultMessage: 'Email',
	},
	usernameColumn: {
		id: 'admin.productDetails.unmapped.column.username',
		description: 'Label for Username column for Users Grids',
		defaultMessage: 'Username',
	},
	mapToItem: {
		id: 'admin.users.menu.mapTo',
		description: 'Map To menu item text',
		defaultMessage: 'Map To',
	},
	ignoreItem: {
		id: 'admin.users.menu.ignore',
		description: 'Ignore menu item text',
		defaultMessage: 'Ignore',
	},
	unignoreItem: {
		id: 'admin.users.menu.unignore',
		description: 'Unignore menu item text',
		defaultMessage: 'Unignore',
	},
	actionsToolTip: {
		id: 'admin.users.menu.tooltip',
		description: 'Tool tip text for actions icon in Users grid',
		defaultMessage: 'Actions',
	},
	showIgnored: {
		id: 'admin.users.showIgnored',
		description: 'Switch label to display / hide ignored users',
		defaultMessage: 'Show ignored users',
	},
	ignoreUserSuccess: {
		id: 'admin.users.ignoreUserSuccess',
		description: 'Ignore user success message',
		defaultMessage: 'Selected user ignored',
	},
	unignoreUserSuccess: {
		id: 'admin.users.unignoreUserSuccess',
		description: 'Unignore user success message',
		defaultMessage: 'Selected user unignored',
	},
});

export default messages;
