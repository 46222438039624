import React from 'react';

export type Group = {
	id: string;
	title: string;
};

export interface GroupsResponse {
	results: Group[];
}

export interface GroupsContextProps {
	systemGroups: Group[];
	systemGroupsById: Map<string, Group>;
}

export const groupsContext: GroupsContextProps = {
	systemGroups: [],
	systemGroupsById: new Map(),
};

export default React.createContext(groupsContext);
