import React, { useContext } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { AppContext, AppContextProps } from '../../../../context';
import messages from '../UsersPage.messages';
import qs from 'qs';
import { downloadUrl } from '../../../../hooks/request/request';
import { Download } from '@planview/pv-icons';
import { ToastType } from '../../../../types/toast';

const getActiveFilters = (filterParams: object) => ({
	...filterParams,
	deactivated: ['false'],
});
const exportUsers = async ({
	filterParams,
	intl,
	appContext,
}: {
	filterParams: object;
	intl: IntlShape;
	appContext: AppContextProps;
}) => {
	appContext.showToast({
		message: intl.formatMessage(messages.exportInProgress),
		type: ToastType.INFO,
	});
	const queryObj = {
		sortBy: 'lastName',
		sortDirection: 'ASC',
		filterParams: JSON.stringify(getActiveFilters(filterParams)),
		includeProducts: true, // todo mike 2024-04-24 make this user selectable
	};
	const queryString = qs.stringify(queryObj, { addQueryPrefix: true });
	await downloadUrl({
		url: `/io/v1/user/export${queryString}`,
		fileName: intl.formatMessage(messages.exportUsersFilename),
	});
};

const useExportUsersAction = ({ filterParams }: { filterParams: object }) => {
	const intl = useIntl();
	const appContext = useContext(AppContext);

	const exportUsersAction = {
		icon: <Download />,
		message: messages.exportUsersItem,
		isEnabled: () => true,
		activateFn: () => exportUsers({ filterParams, intl, appContext }),
	};

	return { exportUsersAction };
};

export default useExportUsersAction;
