import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Input, Modal, MODAL_MEDIUM } from '@planview/pv-uikit';
import messages from './OKRTokenModal.messages';
import styled from 'styled-components';
import { theme, spacingPx, text } from '@planview/pv-utilities';
import { get, logError } from '../../../../hooks/request/request';
import { AppContext } from '../../../../context';
import { OAuth2TokenResponse } from '../../../../types/api/tokens';
import { ToastType } from '../../../../types/toast';

const Body = styled.div`
	${text.regular}
	margin-bottom: ${spacingPx.small};
`;

const Url = styled.a`
	text-decoration: none;
	display: inline;
	color: ${theme.textLinkNormal} !important;

	&:hover {
		cursor: pointer;
		text-decoration: underline;
		color: ${theme.textLinkHover};
	}
`;

const EnvironmentLabel = styled.label`
	${text.semibold}
`;

type OKRTokenModalProps = {
	tenantGroupId: string;
	label: string;
	onCancel: () => void;
	onConfirm: () => void;
};

const OKRTokenModal = (props: OKRTokenModalProps) => {
	const { tenantGroupId, label, onCancel, onConfirm, ...rest } = props;
	const intl = useIntl();
	const { showToast } = useContext(AppContext);
	const [isGenerating, setIsGenerating] = useState(true);
	const [token, setToken] = useState('');
	const inputComponent = <Input readOnly={true} value={token} />;
	useEffect(() => {
		const getPtsToken = async () => {
			const urlParams = { tenantGroupId, app: 'OKRS_REPORTING' };
			const queryString = new URLSearchParams(urlParams).toString();
			const {
				id_token: idToken,
				success,
				message,
			} = (await get(
				`/io/v1/user/token?${queryString}`,
			)) as OAuth2TokenResponse;
			if (success) {
				setToken(idToken);
				setIsGenerating(false);
			} else {
				await logError(`Generate token error: ${message}`);
				let errorMessage = intl.formatMessage(
					messages.generateTokenFailure,
				);
				if (message) {
					errorMessage = `${errorMessage} ${message}`;
				}
				showToast({
					message: errorMessage,
					type: ToastType.DANGER,
				});
			}
		};

		void getPtsToken();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tenantGroupId]);

	return (
		<Modal
			headerText={intl.formatMessage(messages.okrTokenTitle)}
			disableConfirm={!!isGenerating}
			onConfirm={async () => {
				await navigator.clipboard.writeText(token);
				onConfirm();
			}}
			onCancel={onCancel}
			confirmText={intl.formatMessage(messages.copyButton)}
			cancelText={intl.formatMessage(messages.closeButton)}
			size={MODAL_MEDIUM}
			{...rest}
		>
			<Body>
				<FormattedMessage
					{...messages.usageMessage}
					values={{
						HelpDocumentationLink: (
							<Url
								target="_blank"
								rel="noopener noreferrer"
								href={intl.formatMessage(
									messages.helpDocumentationUrl,
								)}
							>
								{intl.formatMessage(messages.helpDocumentation)}
							</Url>
						),
					}}
				/>
			</Body>
			<Body>
				<FormattedMessage
					{...messages.confirmationMessage}
					values={{
						EnvironmentLabel: (
							<EnvironmentLabel>{label}</EnvironmentLabel>
						),
					}}
				/>
			</Body>
			<Body>{inputComponent}</Body>
		</Modal>
	);
};

// proptypes
OKRTokenModal.propTypes = {
	disableConfirm: PropTypes.bool,
	sandbox: PropTypes.bool,
	tenantGroupId: PropTypes.string.isRequired,
};

export default OKRTokenModal;
