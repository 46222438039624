import React, { useCallback, useContext, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import styled, { keyframes } from 'styled-components';
import {
	align,
	theme,
	motion,
	shadow,
	spacingPx,
} from '@planview/pv-utilities';
import { ButtonEmpty } from '@planview/pv-uikit';
import AppContext from '../../../context/appContext';
import { HBox } from '../Layout';
import { CheckmarkFilled, Info, Warning } from '@planview/pv-icons';
import { Toast as ToastTypeDef, ToastType } from '../../../types/toast';

const messages = defineMessages({
	dismiss: {
		id: 'app.toast.button.dismiss',
		description: 'Button text for dismiss action for a toast',
		defaultMessage: 'Dismiss',
	},
});

const Container = styled(HBox)`
	font-size: 14px;
	display: flex;
	flex-direction: column;
	position: fixed;
	top: 30px;
	z-index: 999999;
`;

const fadeIn = keyframes`
    from {
        transform: translateY(-40px);
    }
    to {
        transform: translateY(0);
    }
`;

const StyledHBox = styled(HBox)`
	${align.centerV}
	background: ${theme.backgroundNeutral0};
	flex-wrap: nowrap;
	margin-bottom: ${spacingPx.small};
	padding-right: ${spacingPx.xsmall};
	animation: ${fadeIn} 0.4s;
	${motion.fadeInDown()};
	${shadow.regular};
`;

const ActionButton = styled(ButtonEmpty)`
	color: ${theme.linkNormal};

	&:hover {
		color: ${theme.linkHover};
	}
`;

const Message = styled.div`
	flex-grow: 1;
	padding: ${spacingPx.small};
`;

const StyledIcon = styled.div`
	${align.centerV}
	align-self: stretch;
`;

const InfoIcon = styled(StyledIcon)`
	background-color: ${theme.info400};
`;

const DangerIcon = styled(StyledIcon)`
	background-color: ${theme.error400};
`;

const SuccessIcon = styled(StyledIcon)`
	background-color: ${theme.success400};
`;

const Icon = ({ type }: { type: ToastType }) => {
	switch (type) {
		case ToastType.INFO:
		default:
			return (
				<InfoIcon>
					<Info color={theme.iconInverse} />
				</InfoIcon>
			);
		case ToastType.DANGER:
			return (
				<DangerIcon>
					<Warning color={theme.iconInverse} />
				</DangerIcon>
			);
		case ToastType.SUCCESS:
			return (
				<SuccessIcon>
					<CheckmarkFilled color={theme.iconInverse} />
				</SuccessIcon>
			);
	}
};

const getToastType = (toast: ToastTypeDef) => {
	return (toast && toast.type) || ToastType.INFO;
};

export const DISMISS_AFTER_TWO_SECONDS = 2_000;
export const DISMISS_AFTER_FIVE_SECONDS = 5_000;
export const DISMISS_AFTER_TEN_SECONDS = 10_000;
export const DISMISS_AFTER_FIFTEEN_SECONDS = 15_000;

const Toast = () => {
	const intl = useIntl();
	const appContext = useContext(AppContext);
	const toast = appContext.toast;
	const [list, setList] = useState<ToastTypeDef[]>([]);
	const [toastDismiss, setToastDismiss] = useState<number>();

	// the useCallback is used so as to prevent unncessary renders
	const deleteToast = useCallback(
		(id: number) => {
			const index = list.findIndex((item) => item.id === id);
			if (index < 0) {
				return;
			}
			list.splice(index, 1);
			setList([...list]);
		},
		[list],
	);

	// Get the incoming toast from appContext and store it in list
	useEffect(() => {
		if (!toast) {
			return;
		}
		setList((currentList) => [toast, ...currentList]);
		setTimeout(() => {
			setToastDismiss(toast.id);
		}, toast.dismissAfter || DISMISS_AFTER_FIFTEEN_SECONDS);
	}, [toast]);

	// Remove any items as they hit toastDismiss
	useEffect(() => {
		if (toastDismiss) {
			deleteToast(toastDismiss);
		}
	}, [deleteToast, toastDismiss]);

	return (
		<Container>
			{list.map((item, i) => (
				<StyledHBox role="status" key={i}>
					<Icon type={getToastType(item)} />
					<Message>{item.message}</Message>
					{(item.actions || []).map((action) => (
						<ActionButton
							key={action.label}
							onClick={() => {
								action.onClick();
								deleteToast(item.id!);
							}}
						>
							{action.label}
						</ActionButton>
					))}
					<ActionButton
						aria-label={intl.formatMessage(messages.dismiss)}
						onClick={() => deleteToast(item.id!)}
					>
						{intl.formatMessage(messages.dismiss)}
					</ActionButton>
				</StyledHBox>
			))}
		</Container>
	);
};

export default Toast;
