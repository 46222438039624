import React from 'react';
import { useIntl } from 'react-intl';
import {
	FilterPanel,
	FilterSectionList,
	FilterSectionRangeDate,
	FilterSectionText,
} from '@planview/pv-filter';
import {
	ApplicationOption,
	ClientCredentialsData,
} from './ClientCredentialsTypes';
import messages from './ClientCredentials.messages';

export type ClientCredentialsFilterParams = {
	description: string | null;
	applications: Set<string> | null;
	createdAt: [Date | null, Date | null];
	createdBy: string | null;
	lastUsedAt: [Date | null, Date | null];
	deactivatedAt: [Date | null, Date | null];
	deactivatedBy: string | null;
	id: string | null;
	showDeactivated: boolean;
};

export type ClientCredentialsFilterParamsIndex =
	| 'description'
	| 'applications'
	| 'createdAt'
	| 'createdBy'
	| 'lastUsedAt'
	| 'deactivatedAt'
	| 'deactivatedBy'
	| 'id'
	| 'showDeactivated';

type ClientCredentialsFilterProps = {
	onCloseFilterClick: () => void;
	filterParams: ClientCredentialsFilterParams;
	setFilterParams: React.Dispatch<
		React.SetStateAction<ClientCredentialsFilterParams>
	>;
	applicationOptions: ApplicationOption[];
};

/**
 * Get an empty filter params
 */
export const getEmptyClientCredentialsFilterParams = ({
	showDeactivated = false,
} = {}): ClientCredentialsFilterParams => ({
	description: null,
	applications: null,
	createdAt: [null, null],
	createdBy: null,
	lastUsedAt: [null, null],
	deactivatedAt: [null, null],
	deactivatedBy: null,
	id: null,
	showDeactivated,
});

/**
 * Get a filter function for the grid
 * @param filterParams Filter params to filter on
 */
export const getClientCredentialsFilterFunction =
	(filterParams: ClientCredentialsFilterParams) =>
	(row: ClientCredentialsData) => {
		if (!filterParams.showDeactivated && row.deactivatedAt) {
			return false;
		}

		if (
			filterParams.description &&
			row.description
				.toLowerCase()
				.indexOf(filterParams.description.toLowerCase()) < 0
		) {
			return false;
		}

		if (
			filterParams.applications &&
			filterParams.applications.size > 0 &&
			!filterParams.applications.has(row.applicationDisplay)
		) {
			return false;
		}

		if (
			filterParams.createdAt[0] &&
			row.createdAt < filterParams.createdAt[0]
		) {
			return false;
		}
		if (
			filterParams.createdAt[1] &&
			row.createdAt > filterParams.createdAt[1]
		) {
			return false;
		}

		if (
			filterParams.createdBy &&
			row.createdBy
				.toLowerCase()
				.indexOf(filterParams.createdBy.toLowerCase()) < 0
		) {
			return false;
		}

		if (
			filterParams.lastUsedAt[0] &&
			(!row.lastUsedAt || row.lastUsedAt < filterParams.lastUsedAt[0])
		) {
			return false;
		}
		if (
			filterParams.lastUsedAt[1] &&
			(!row.lastUsedAt || row.lastUsedAt > filterParams.lastUsedAt[1])
		) {
			return false;
		}

		if (
			filterParams.deactivatedAt[0] &&
			(!row.deactivatedAt ||
				row.deactivatedAt < filterParams.deactivatedAt[0])
		) {
			return false;
		}
		if (
			filterParams.deactivatedAt[1] &&
			(!row.deactivatedAt ||
				row.deactivatedAt > filterParams.deactivatedAt[1])
		) {
			return false;
		}

		if (
			filterParams.deactivatedBy &&
			(!row.deactivatedBy ||
				row.deactivatedBy
					.toLowerCase()
					.indexOf(filterParams.deactivatedBy.toLowerCase()) < 0)
		) {
			return false;
		}

		if (
			filterParams.id &&
			row.id.toLowerCase().indexOf(filterParams.id.toLowerCase()) < 0
		) {
			return false;
		}

		return true;
	};

/**
 * Component to show the filter for client credentials
 * @param onCloseFilterClick callback for closing the filter
 * @param filterParams stateful filter params used to filter the grid
 * @param setFilterParams function to update the filter params state
 * @constructor
 */
const ClientCredentialsFilter = ({
	onCloseFilterClick,
	filterParams,
	setFilterParams,
	applicationOptions,
}: ClientCredentialsFilterProps) => {
	const intl = useIntl();
	const textFilterPlaceholder = intl.formatMessage(
		messages.textFilterPlaceholder,
	);

	const applicationFilterOptions = applicationOptions.map((app) => {
		return {
			id: `${app?.displayText || ''} - ${app?.tenantGroup?.title || ''}`,
			label: `${app?.displayText || ''} - ${app?.tenantGroup?.title || ''}`,
		};
	});

	return (
		<FilterPanel
			clearEnabled={true}
			onClear={() =>
				setFilterParams((currFilterParams) => {
					const { showDeactivated } = currFilterParams;
					return getEmptyClientCredentialsFilterParams({
						showDeactivated,
					});
				})
			}
			onClose={onCloseFilterClick}
		>
			<FilterSectionText
				label={intl.formatMessage(messages.descriptionLabel)}
				value={filterParams.description}
				onChange={(value) =>
					setFilterParams({ ...filterParams, description: value })
				}
				placeholder={textFilterPlaceholder}
			/>
			<FilterSectionList
				value={filterParams.applications || undefined}
				label={intl.formatMessage(messages.applicationLabel)}
				options={applicationFilterOptions}
				onChange={(values: Set<string>) =>
					setFilterParams({ ...filterParams, applications: values })
				}
			/>
			<FilterSectionRangeDate
				label={intl.formatMessage(messages.createdAtLabel)}
				value={filterParams.createdAt}
				onChange={(value) =>
					setFilterParams({ ...filterParams, createdAt: value })
				}
			/>
			<FilterSectionText
				label={intl.formatMessage(messages.createdByLabel)}
				value={filterParams.createdBy}
				onChange={(value) =>
					setFilterParams({ ...filterParams, createdBy: value })
				}
				placeholder={textFilterPlaceholder}
			/>
			<FilterSectionRangeDate
				label={intl.formatMessage(messages.lastUsedAtLabel)}
				value={filterParams.lastUsedAt}
				onChange={(value) =>
					setFilterParams({ ...filterParams, lastUsedAt: value })
				}
			/>
			{filterParams.showDeactivated && (
				<FilterSectionRangeDate
					label={intl.formatMessage(messages.deactivatedAtLabel)}
					value={filterParams.deactivatedAt}
					onChange={(value) =>
						setFilterParams((currFilterParams) => {
							const { showDeactivated, deactivatedBy } =
								currFilterParams;
							const updatedShowDeactivated =
								showDeactivated ||
								!!value[0] ||
								!!value[1] ||
								!!deactivatedBy;
							return {
								...filterParams,
								deactivatedAt: value,
								showDeactivated: updatedShowDeactivated,
							};
						})
					}
				/>
			)}
			{filterParams.showDeactivated && (
				<FilterSectionText
					label={intl.formatMessage(messages.deactivatedByLabel)}
					value={filterParams.deactivatedBy}
					onChange={(value) =>
						setFilterParams((currFilterParams) => {
							const { showDeactivated, deactivatedAt } =
								currFilterParams;
							const updatedShowDeactivated =
								showDeactivated ||
								!!value ||
								!!deactivatedAt[0] ||
								!!deactivatedAt[1];
							return {
								...currFilterParams,
								deactivatedBy: value,
								showDeactivated: updatedShowDeactivated,
							};
						})
					}
					placeholder={textFilterPlaceholder}
				/>
			)}
			<FilterSectionText
				label={intl.formatMessage(messages.idLabel)}
				value={filterParams.id}
				onChange={(value) =>
					setFilterParams({ ...filterParams, id: value })
				}
				placeholder={textFilterPlaceholder}
			/>
		</FilterPanel>
	);
};

export default ClientCredentialsFilter;
