import { defineMessages } from 'react-intl';

const messages = defineMessages({
	featureNotEnabled: {
		id: 'admin.notificationsPage.featureNotEnabled',
		description:
			'Text to display when a user tries to access the notifications page with the feature turned off',
		defaultMessage: 'Notifications feature is not enabled',
	},
	noProductionApplication: {
		id: 'admin.notificationsPage.noProductionApplication',
		description:
			'Message shown when a user tries to view notifications without any production applications PV Admin',
		defaultMessage:
			'You need to enable a production application to use this feature',
	},
});

export default messages;
