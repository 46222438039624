import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { SIZE_XLARGE, Spinner } from '@planview/pv-uikit';
import { AppContext, UserContext } from '../../../context';
import { getActiveSession } from '../../../hooks/request/request';
import { useResolvedPath } from 'react-router-dom';

type PVAdminProtectedRouteProps = {
	element: ReactElement;
};

const PVAdminProtectedRoute = (props: PVAdminProtectedRouteProps) => {
	const { element } = props;
	const userContext = useContext(UserContext);
	const appContext = useContext(AppContext);
	const [isLoading, setIsLoading] = useState(true);
	const path = useResolvedPath('').pathname;
	const isPvAdminRoute = /\/pvadmin/.test(path);
	const { isAuthenticated = false, hasCustomerCareAccess } = userContext;

	useEffect(() => {
		const { isAuthenticated } = userContext;
		setIsLoading(!isAuthenticated);
		if (isAuthenticated) {
			return;
		}

		void getActiveSession(userContext, appContext).finally(() => {
			setIsLoading(false);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (isLoading) {
		return <Spinner size={SIZE_XLARGE} />;
	}

	if (!isAuthenticated || !isPvAdminRoute || !hasCustomerCareAccess()) {
		window.location.replace('/');
		return null;
	}

	return element;
};

export default PVAdminProtectedRoute;
