import React from 'react';
import { injectIntl } from 'react-intl';
import { SortDirection } from 'react-virtualized';
import { AppContext } from '../../../context';
import { get } from '../../../hooks/request/request';
import { getUrl } from './dataLoader';
import messages from './Grid.messages';
import './Grid.css';
import {
	Grid as PVDSGrid,
	GridEmpty,
	GridEmptyNoMatches,
	useGridRow,
} from '@planview/pv-grid';
import { size } from '@planview/pv-utilities';
import { flushSync } from 'react-dom';
import { ToastType } from '../../../types/toast';

export const PAGE_LIMIT = 1000;

const NO_DATA = [];
const LOADING_ROWS = 20;

class Grid extends React.Component {
	static contextType = AppContext;

	constructor(props) {
		super(props);
		const {
			preferencesAdapter,
			data = null,
			selectionType = props.selectionType,
			sortBy = 'lastName',
			sortDirection = SortDirection.ASC,
			filterParams,
			deactivated,
			limit = PAGE_LIMIT,
			showActionsColumn = true,
		} = this.props;

		this.state = {
			columns: this._processColumns(showActionsColumn),
			data,
			count: 0,
			rowCount: limit, // initial count until we load the data
			selectedRowIds: new Set(),
			selectCheckboxHeader: false,
			selectionType,
			defaultSort: [
				{
					columnId: sortBy,
					direction: sortDirection.toLowerCase(),
				},
			],
			sortBy,
			sortDirection,
			filterParams,
			deactivated,
			limit,
			searchQuery: '',
			preferencesAdapter,
		};

		this.controller = new AbortController();
		this.currentLoadingUrl = '';
	}

	componentDidMount() {
		this._loadData();
	}

	// eslint-disable-next-line camelcase
	UNSAFE_componentWillMount() {
		const { innerRef } = this.props;
		innerRef && innerRef(this);
	}

	componentWillUnmount() {
		this.controller.abort();
	}

	render() {
		const {
			columns,
			data,
			defaultSort,
			sortBy,
			sortDirection,
			selectionType,
			selectedRowIds,
			preferencesAdapter,
		} = this.state;

		let selectionMode = 'none';
		if (selectionType === 'radio') {
			selectionMode = 'single';
		} else if (selectionType === 'checkbox') {
			selectionMode = 'multi';
		}

		return (
			<PVDSGrid
				columns={columns}
				rows={data || NO_DATA}
				loading={data === null ? LOADING_ROWS : false}
				emptyContent={this.emptyContentRenderer(
					this.state.searchQuery,
					this.state.filterParams,
				)}
				sort={[
					{
						columnId: sortBy,
						direction: sortDirection.toLowerCase(),
					},
				]}
				sortMode="external"
				multiColumnSort={false}
				defaultSort={defaultSort}
				onSortChange={this._sort}
				onCellChange={this.props.onCellChange}
				selection={selectedRowIds}
				selectionMode={selectionMode}
				onSelectionChange={this._onSelectionChange}
				preferencesAdapter={preferencesAdapter}
			/>
		);
	}

	emptyContentRenderer = (searchQuery, filterParams) => {
		const { formatMessage } = this.props.intl;
		const message = formatMessage(messages.noRowsText);

		if (searchQuery || (filterParams && Object.keys(filterParams).length)) {
			return (
				<GridEmptyNoMatches onClearFilters={this.props.clearFilters} />
			);
		} else {
			return <GridEmpty label={message} />;
		}
	};

	clearSelection = () => {
		this.setState({
			...this.state,
			selectedRowIds: new Set(),
		});
	};

	getSelectedRows = () => {
		return this.state.data.filter(
			(item) => item && this.state.selectedRowIds.has(item.id),
		);
	};

	refresh = async (args) => {
		return this._loadData({ ...args, clearCache: true });
	};

	resetFiltering = (filterParams = {}) => {
		const searchQuery = '';

		this.setState({
			...this.state,
			searchQuery,
			filterParams,
		});
		this._loadData({ searchQuery, filterParams, clearCache: true });
	};

	triggerSearch = (searchQuery) => {
		this.setState({
			...this.state,
			searchQuery,
		});
		this._loadData({ searchQuery, clearCache: true });
	};

	setFilterParams = (filterParams) => {
		this.setState({
			...this.state,
			filterParams,
		});
		this._loadData({ filterParams, clearCache: true });
	};

	_loadData = (args = {}) => {
		const {
			searchQuery = this.state.searchQuery,
			filterParams = this.state.filterParams,
			deactivatedFlag = this.state.deactivated,
			clearCache = false,
		} = args;

		const { showToast, setNeedsLogin } = this.context;
		const url = getUrl({
			...this.props,
			...this.state,
			...args,
			filterParams,
			deactivatedFlag,
		});

		// Prevents duplicate requests
		if (this.currentLoadingUrl === url) {
			return;
		}

		// abort any current request
		this.controller.abort();
		this.controller = new AbortController();

		// clearCache is true for search and sort, since the grid needs to be repainted
		if (clearCache) {
			this._clearData();
		}

		this.currentLoadingUrl = url;

		return get(url, {
			signal: this.controller.signal,
		})
			.then((response) => {
				if (!response || response.aborted) {
					return;
				}

				const {
					success,
					results,
					count,
					nextResultsKey,
					httpStatusCode,
				} = response;

				if (httpStatusCode === 401) {
					setNeedsLogin(true);
					return;
				}

				// Reset the variable on returned response
				this.currentLoadingUrl = '';

				if (!success) {
					showToast &&
						showToast({
							message: this.props.intl.formatMessage(
								messages.loadDataError,
							),
							type: ToastType.DANGER,
						});
					this.setState({
						...this.state,
						rowCount: 0,
						count: 0,
						data: [],
					});
					return;
				}

				const newState = {
					...this.state,
					data: results,
					rowCount: results.length,
					count,
					nextResultsKey,
					searchQuery,
					filterParams,
				};
				flushSync(() => {
					this.setState(newState);
				});

				this._onRefresh(newState);
			})
			.catch((err) => {
				console.warn('Grid caught exception', err);
			});
	};

	_actionsCellRenderer = ({ rowData, tabIndex }) => {
		const { actionsMenu, actions } = this.props;
		const showActionsMenu =
			typeof rowData === 'object' && Object.keys(rowData).length !== 0;
		const args = {
			rowData,
			refresh: this.refresh,
			actions,
			tabIndex,
		};

		// Don't show the actions menu if there is no data available for the row (e.g., when loading)
		if (actionsMenu && showActionsMenu) {
			return React.createElement(actionsMenu, args);
		}

		return null;
	};

	_onRefresh = (newState) => {
		if (this.props.onRefresh) {
			this.props.onRefresh({
				data: newState.data,
				count: newState.count,
				limit: newState.limit,
			});
		}
	};

	_onSelectionChange = (ids) => {
		this.setState((s) => ({ ...s, selectedRowIds: ids }));
		if (this.props.onSelectionChange) {
			const selectedRows = this.state.data.filter(
				(item) => item && ids.has(item.id),
			);
			this.props.onSelectionChange(selectedRows);
		}
	};

	_processColumns = (showActionsColumn) => {
		const { columns } = this.props;

		// Apply Sort info renderer
		const processedColumns = [...columns];

		if (showActionsColumn) {
			processedColumns.push({
				id: 'actions',
				label: '',
				width: size.small,
				resizable: false,
				movable: false,
				sortable: false,
				sticky: 'right',
				cell: {
					focusStrategy: 'inline',
					Renderer: ({ tabIndex, rowId }) => {
						const rowData = useGridRow(rowId);
						return this._actionsCellRenderer({ rowData, tabIndex });
					},
				},
			});
		}

		return processedColumns;
	};

	_sort = (newSort) => {
		const { columnId, direction } = newSort[0];

		const sortBy = columnId;
		const sortDirection = direction.toUpperCase();

		this.setState({
			...this.state,
			sortBy,
			sortDirection,
		});

		this._loadData({ sortBy, sortDirection, clearCache: true });
	};

	_clearData = () => {
		this.setState({
			data: null,
			selectedRowIds: new Set(),
		});

		// Clear the cache on the infinite loader and reload the rows
		this.infiniteLoader &&
			this.infiniteLoader.resetLoadMoreRowsCache(false);
	};
}

export default injectIntl(Grid);
