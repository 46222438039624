import React, { useContext } from 'react';
import messages from './NewUserDialog.messages';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { AppContext } from '../../../context';
import { theme, spacingPx, text } from '@planview/pv-utilities';
import styled from 'styled-components';
import { VBox } from '../../../components/common/Layout';
import ProductCheckBox from './ProductCheckBox';
import { Application, EnvSelector } from '../../../types';
import { ProvisionStateDto, UserUpsertDto } from '../../../types/api/users';

const AssignProductsContainer = styled(VBox)`
	margin-top: ${spacingPx.xsmall};
`;

const SectionHeader = styled.div`
	${text.regular}
	font-weight: 600;
`;

const SectionSubHeader = styled.div`
	${text.regular}
	color: ${theme.textSecondary};
	margin-top: ${spacingPx.xsmall};
`;

const showAssignProducts = (applications: Application[]) => {
	return (
		applications &&
		applications.some((application) => application.userAssignmentSupported)
	);
};

const areEqual = (envSelector: EnvSelector, otherEnvSelector: EnvSelector) =>
	envSelector.application === otherEnvSelector.application &&
	envSelector.tenantId === otherEnvSelector.tenantId;

const getProvisionStateDto = (
	provisionStates: ProvisionStateDto[] | null | undefined,
	envSelector: EnvSelector,
) => {
	if (provisionStates) {
		return provisionStates.find((listItem) =>
			areEqual(listItem.envSelector, envSelector),
		);
	}
};

export type AssignProductsSectionProps = {
	user: UserUpsertDto;
	setUser: (user: UserUpsertDto) => void;
	isCustomerCare?: boolean;
	applicationSelectionMessage?: MessageDescriptor;
	noDefaultOptions: boolean;
};

export const AssignProductsSection = ({
	user,
	setUser,
	isCustomerCare,
	applicationSelectionMessage = messages.applicationSelectionMessage,
	noDefaultOptions,
}: AssignProductsSectionProps) => {
	const { applications, featureFlags } = useContext(AppContext);

	if (isCustomerCare || !showAssignProducts(applications)) {
		return null;
	}

	return (
		<AssignProductsContainer>
			<SectionHeader>
				<FormattedMessage {...messages.productEnvironments} />
			</SectionHeader>
			<SectionSubHeader>
				<FormattedMessage {...applicationSelectionMessage} />
			</SectionSubHeader>
			{applications
				.filter(
					({ userAssignmentSupported, custom }) =>
						!custom && userAssignmentSupported,
				)
				.map((application, idx) => (
					<ProductCheckBox
						key={idx}
						application={application}
						user={user}
						provisionState={getProvisionStateDto(
							user.provisionStates,
							application.envSelector,
						)}
						updateUser={(updatedUser: UserUpsertDto) => {
							setUser(updatedUser);
						}}
						featureFlags={featureFlags}
						noDefaultOptions={noDefaultOptions}
					/>
				))}
		</AssignProductsContainer>
	);
};
