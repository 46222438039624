import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { injectIntl, useIntl } from 'react-intl';
import { ListItem, RIGHT_ALIGNED, Switch } from '@planview/pv-uikit';
import Grid from '../../../components/common/grid/Grid';
import { GridSearchInput } from '../../../components/common/input/GridSearchInput';
import { AppContext } from '../../../context';
import { requestWithErrorHandling } from '../../../hooks/request/request';
import MapUsersDialog from '../mapUsersDialog/MapUsersDialog';
import MapToNewUserDialog from '../newUserDialog/MapToNewUserDialog';
import messages from './UnmappedUsersGrid.messages';
import { TbFill, Toolbar } from '../../../components/common/toolbar/Toolbar';
import { DotsVertical } from '@planview/pv-icons';
import { theme } from '@planview/pv-utilities';
import {
	GridCellDropdownMenu,
	useLocalStoragePreferences,
} from '@planview/pv-grid';

const Container = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	overflow: hidden;
	background-color: ${theme.backgroundNeutral0};
`;

Switch.displayName = 'Switch'; // Temporary fix

const Actions = ({ actions }) => {
	const intl = useIntl();

	const trigger = {
		tooltip: intl.formatMessage(messages.actionsToolTip),
		icon: <DotsVertical />,
	};

	return (
		<GridCellDropdownMenu alignRight={true} trigger={trigger}>
			{Array.isArray(actions) ? (
				actions.map(({ handler, show = true, text }, idx) =>
					show ? (
						<ListItem key={idx} label={text} onActivate={handler} />
					) : null,
				)
			) : (
				<ListItem label={actions.text} onActivate={actions.handler} />
			)}
		</GridCellDropdownMenu>
	);
};

const getUnmappedUsersGridColumns = (intl) => {
	const { formatMessage } = intl;
	return [
		{
			id: 'firstName',
			label: formatMessage(messages.firstNameColumn),
			width: 300,
		},
		{
			id: 'lastName',
			label: formatMessage(messages.lastNameColumn),
			width: 300,
		},
		{
			id: 'email',
			label: formatMessage(messages.emailColumn),
			width: 300,
		},
		{
			id: 'username',
			label: formatMessage(messages.usernameColumn),
			width: 200,
		},
	];
};

const UnmappedUsersGrid = ({ ...props }) => {
	const { app, intl } = props;
	const grid = useRef();
	const appContext = useContext(AppContext);
	const { formatMessage } = intl;
	const [selectedUser, setSelectedUser] = useState(null);
	const [searchValue, setSearchValue] = useState('');
	const [showMapUserDialog, setShowMapUserDialog] = useState(false);
	const [showNewUserDialog, setShowNewUserDialog] = useState(false);
	const [showIgnored, setShowIgnored] = useState(false);

	const columns = getUnmappedUsersGridColumns(intl);

	const preferencesKey = columns.map((c) => c.id).join('-');
	const preferencesAdapter = useLocalStoragePreferences(preferencesKey);

	const refresh = () => {
		grid.current.refresh({
			urlParams: { showIgnored: showIgnored },
		});
	};

	const openMapUserDialog = (selectedUser) => {
		setSelectedUser(selectedUser);
		setShowMapUserDialog(true);
	};

	const closeMapUserDialog = () => {
		setSelectedUser(null);
		setShowMapUserDialog(false);
	};

	const openNewUserDialog = () => {
		setShowMapUserDialog(false);
		setShowNewUserDialog(true);
	};

	const closeNewUserDialog = () => {
		setSelectedUser(null);
		setShowNewUserDialog(false);
	};

	const getActionsMenuItems = (rowData) => {
		const { formatMessage } = intl;
		let actions;

		if (!showIgnored) {
			actions = [
				{
					text: formatMessage(messages.mapToItem),
					handler: () => {
						openMapUserDialog(rowData);
					},
				},
				{
					text: formatMessage(messages.ignoreItem),
					handler: () => {
						handleIgnoreUserClick(rowData);
					},
				},
			];
		} else {
			actions = {
				text: formatMessage(messages.unignoreItem),
				handler: () => {
					handleUnignoreUserClick(rowData);
				},
			};
		}

		return actions;
	};

	const handleIgnoreUserClick = async (rowData) => {
		await requestWithErrorHandling({
			method: 'post',
			url: `/io/v1/user/ignore/${app.envSelectorEncodedString}`,
			dataObj: { userIds: [rowData.id] },
			appContext: appContext,
			intl: intl,
			successMessage: messages.ignoreUserSuccess,
		});

		refresh();
	};

	const handleUnignoreUserClick = async (rowData) => {
		await requestWithErrorHandling({
			method: 'post',
			url: `/io/v1/user/unignore/${app.envSelectorEncodedString}`,
			dataObj: { userIds: [rowData.id] },
			appContext: appContext,
			intl: intl,
			successMessage: messages.unignoreUserSuccess,
		});

		refresh();
	};

	useEffect(() => {
		grid.current.refresh({
			urlParams: { showIgnored: showIgnored },
		});
	}, [showIgnored]);

	const ActionsMenu = ({ rowData }) => {
		return <Actions actions={getActionsMenuItems(rowData)} />;
	};

	return (
		<>
			<Toolbar>
				<GridSearchInput
					value={searchValue}
					onChange={(searchQuery) => {
						setSearchValue(searchQuery);
					}}
					triggerSearch={(searchQuery) => {
						grid.current.triggerSearch(searchQuery);
					}}
				/>
				<TbFill />
				<Switch
					alignment={RIGHT_ALIGNED}
					label={formatMessage(messages.showIgnored)}
					onChange={(enabled) => {
						setShowIgnored(enabled);
					}}
				/>
			</Toolbar>
			<Container>
				<Grid
					columns={columns}
					actionsMenu={ActionsMenu}
					selectionType="none"
					url={`/io/v1/user/unmapped/${app.envSelectorEncodedString}`}
					urlParams={{ showIgnored: showIgnored }}
					preferencesAdapter={preferencesAdapter}
					clearFilters={() => {
						setSearchValue('');
						grid.current.resetFiltering();
					}}
					innerRef={(ref) => {
						grid.current = ref;
					}}
					{...props}
				/>
			</Container>
			{showMapUserDialog ? (
				<MapUsersDialog
					app={app}
					user={selectedUser}
					onCancel={closeMapUserDialog}
					showNewUserDialog={openNewUserDialog}
					onConfirm={() => {
						refresh();
						closeMapUserDialog;
					}}
				/>
			) : null}
			{showNewUserDialog ? (
				<MapToNewUserDialog
					app={app}
					user={selectedUser}
					onCancel={closeNewUserDialog}
					onConfirm={() => {
						refresh();
						closeNewUserDialog;
					}}
				/>
			) : null}
		</>
	);
};

export default injectIntl(UnmappedUsersGrid);
