import { defineMessages } from 'react-intl';

const messages = defineMessages({
	refresh: {
		id: 'app.modal.refresh',
		description: 'Refresh button text for Refresh App Modal',
		defaultMessage: 'Refresh',
	},
	header: {
		id: 'app.modal.refresh.header',
		description: 'Header text for Refresh App Modal',
		defaultMessage: 'New Version Available!',
	},
	content: {
		id: 'app.modal.refresh.content',
		description: 'Content for Refresh App Modal',
		defaultMessage:
			'Refresh your browser to update to the latest Planview Admin release.',
	},
	loginButton: {
		id: 'app.modal.refresh.login.button',
		description: 'Sign in button text for Refresh App Modal',
		defaultMessage: 'Sign in',
	},
	loginHeader: {
		id: 'app.modal.refresh.login.header',
		description: 'Header text for Refresh App Modal',
		defaultMessage: 'Sign in required',
	},
	loginContent: {
		id: 'app.modal.refresh.login.content',
		description: 'Content for Refresh App modal',
		defaultMessage: 'Session expired. Please sign in again.',
	},
});

export default messages;
