import React, { useContext, useState } from 'react';
import { AppContext } from '../../../../context';
import { FormattedMessage, useIntl } from 'react-intl';
import { Upload } from '@planview/pv-icons';
import { Modal, MODAL_MEDIUM } from '@planview/pv-uikit';
import { AssignProductsSection } from '../../newUserDialog/AssignProductsSection';
import messages from '../UsersPage.messages';
import modalMessages from '../../../../components/common/modal/Modal.messages';
import styled from 'styled-components';
import { spacingPx, text } from '@planview/pv-utilities';
import { post } from '../../../../hooks/request/request';
import { Customer } from '../../../../types';
import {
	ImportUserResponse,
	UserTenantAssignment,
	UserUpsertDto,
} from '../../../../types/api/users';
import { ToastType } from '../../../../types/toast';
import { isMissingRoles } from '../../../../helpers/util';

type ImportUserDialogProps = {
	isCustomerCare?: boolean;
	onConfirm: () => void;
	onCancel: () => void;
	customer: Customer;
};

const initialUser: UserUpsertDto = {
	id: '',
	userTenantAssignments: [],
	firstName: '',
	lastName: '',
	email: '',
	isAdmin: false,
	localAuthOverrideEnabled: false,
};

const postImport = async ({
	selectedFile,
	userTenantAssignments,
}: {
	selectedFile: string | Blob;
	userTenantAssignments: UserTenantAssignment[];
}) => {
	const formData = new FormData();
	formData.append('file', selectedFile);
	userTenantAssignments.forEach((userTenantAssignment) =>
		formData.append(
			'userTenantAssignment',
			JSON.stringify(userTenantAssignment),
		),
	);

	const url = '/io/v1/user/import';
	return (await post(url, formData, false, {
		'Content-Type': 'multipart/form-data',
	})) as ImportUserResponse;
};

const Info = styled.div`
	${text.regular}
	margin-bottom: ${spacingPx.xsmall};
`;

const FileSection = styled.div`
	margin-top: ${spacingPx.medium};
	margin-bottom: ${spacingPx.medium};
`;

const ImportUserDialog: React.FC<ImportUserDialogProps> = (props) => {
	const intl = useIntl();
	const { onConfirm, onCancel, isCustomerCare } = props;
	const appContext = useContext(AppContext);
	const { showToast } = appContext;
	const [user, setUser] = useState(initialUser);
	const [isSaving, setIsSaving] = useState(false);
	const [selectedFile, setSelectedFile] = React.useState<
		string | Blob | null
	>(null);

	const handleFileSelect: React.ChangeEventHandler<HTMLInputElement> = (
		event,
	) => {
		if (event.target.files) {
			setSelectedFile(event.target.files[0]);
		}
	};

	const missingRoles = isMissingRoles(user.userTenantAssignments);

	const isFormValid = selectedFile !== null;

	const doImport = async () => {
		if (!isFormValid) {
			return;
		}

		setIsSaving(true);
		const { success, message } = await postImport({
			selectedFile,
			userTenantAssignments: user.userTenantAssignments,
		});

		showToast({
			message,
			type: success ? ToastType.SUCCESS : ToastType.DANGER,
		});

		setIsSaving(false);
		if (success) {
			onConfirm();
		}
	};
	return (
		<Modal
			id="new-user-modal"
			size={MODAL_MEDIUM}
			// eslint-disable-next-line @typescript-eslint/no-misused-promises
			onConfirm={() => doImport()}
			onCancel={onCancel}
			cancelText={intl.formatMessage(modalMessages.cancel)}
			confirmText={intl.formatMessage(messages.importUsersButtonText)}
			headerText={intl.formatMessage(messages.importUsersDialogHeader)}
			disableConfirm={isSaving || !isFormValid || missingRoles}
		>
			<Info>
				<FormattedMessage {...messages.importUsersDialogText} />
				<br />
				<a
					href="/io/v1/user/import/template.csv"
					download="template.csv"
				>
					<FormattedMessage {...messages.downloadTemplateLink} />
				</a>
			</Info>
			<FileSection>
				<input
					type="file"
					onChange={handleFileSelect}
					accept="text/csv"
					data-testid="file-input"
				/>
			</FileSection>
			<AssignProductsSection
				user={user}
				setUser={setUser}
				isCustomerCare={isCustomerCare}
				applicationSelectionMessage={
					messages.importUsersApplicationSelectionMessage
				}
				noDefaultOptions={false}
			/>
		</Modal>
	);
};

const useImportUsersAction = ({
	customer,
	isCustomerCare,
}: {
	customer: Customer;
	isCustomerCare: boolean;
}) => {
	const [showImportUserDialog, setShowImportUserDialog] = useState(false);

	const importUsersAction = {
		icon: <Upload />,
		message: messages.importUsersItem,
		isEnabled: () => true,
		activateFn: () => setShowImportUserDialog(true),
	};

	const importUsersModal = showImportUserDialog ? (
		<ImportUserDialog
			key="importUserDialog"
			customer={customer}
			isCustomerCare={isCustomerCare}
			onCancel={() => setShowImportUserDialog(false)}
			onConfirm={() => {
				setShowImportUserDialog(false);
			}}
		/>
	) : null;

	return { importUsersAction, importUsersModal };
};

export default useImportUsersAction;
