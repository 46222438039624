import React, { useContext, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Modal, MODAL_MEDIUM } from '@planview/pv-uikit';
import { post } from '../../../hooks/request/request';
import { AppContext, UserContext } from '../../../context';
import messages from './NewUserDialog.messages';
import { UserDetailsForm, useUserDetailsForm } from './UserDetailsForm';
import { ToastType } from '../../../types/toast';

const initialUser = {
	id: '',
	email: '',
	firstName: '',
	lastName: '',
	envSelectors: [],
	isAdmin: false,
	localAuthOverrideEnabled: false,
	isActive: false,
	customerSsoEnabled: false,
	name: '',
	passwordUser: false,
};

const MapToNewUserDialog = (props) => {
	const { app, intl, onConfirm } = props;
	const userContext = useContext(UserContext);

	const showLocalAuthOverride = userContext.user.customerSsoEnabled;
	const [isSaving, setIsSaving] = useState(false);

	const form = useUserDetailsForm(
		{
			...initialUser,
			...props.user,
		},
		intl,
	);

	const fireRequest = async (showToast) => {
		const userDto = {
			...form.values,
			id: props.user.id,
			unmappedUserId: props.user.id,
		};

		const { success, message } = await post(
			`/io/v1/user/mapNew/${app.envSelectorEncodedString}`,
			userDto,
			false,
		);

		showToast({
			message,
			type: success ? ToastType.SUCCESS : ToastType.DANGER,
		});

		return success;
	};

	const save = async ({ showToast }) => {
		const validationResult = form.validate();
		if (validationResult.hasErrors || isSaving) {
			return;
		}

		setIsSaving(true);

		const success = await fireRequest(showToast);

		if (success && onConfirm) {
			onConfirm();
		} else {
			setIsSaving(false);
		}
	};

	const isFormValid = !Object.entries(form.errors).length;

	return (
		<AppContext.Consumer>
			{(appContext) => (
				<Modal
					id="map-to-new-user-modal"
					size={MODAL_MEDIUM}
					onConfirm={() => save(appContext)}
					onCancel={props.onCancel}
					cancelText={intl.formatMessage(messages.cancelButton)}
					confirmText={intl.formatMessage(messages.mapButton)}
					headerText={intl.formatMessage(messages.mapHeader)}
					// Using form.errors over form.isValid() here so that users can see form errors
					disableConfirm={isSaving || !isFormValid}
				>
					<UserDetailsForm
						form={form}
						showLocalAuthOverride={showLocalAuthOverride}
					/>
				</Modal>
			)}
		</AppContext.Consumer>
	);
};

MapToNewUserDialog.displayName = 'MapToNewUserDialog';
export default injectIntl(MapToNewUserDialog);
