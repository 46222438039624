import React, { useContext, useState } from 'react';
import { AppContext, UserContext } from '../../../../context';
import messages from '../UsersPage.messages';
import { Cancel, Refresh } from '@planview/pv-icons';
import { useIntl } from 'react-intl';
import {
	post,
	requestWithErrorHandling,
} from '../../../../hooks/request/request';
import { ConfirmationModal } from '../../../../components/common/modal/Modal';
import { DESTRUCTIVE } from '@planview/pv-uikit';
import { getUserIds } from './userUtils';
import { User } from '../../../../types';
import {
	CompositeStatusDtoResponse,
	UserUpsertDto,
} from '../../../../types/api/users';
import { ToastType } from '../../../../types/toast';
import EditUserDialog from '../../newUserDialog/EditUserDialog';
import AddProductsDialog from '../../newUserDialog/AddProductsDialog';

type DeactivateConfirmationDialogProps = {
	users: User[];
	onCancel: () => void;
	onConfirm: () => void;
};

const DeactivateConfirmationDialog = (
	props: DeactivateConfirmationDialogProps,
) => {
	const { users, onCancel, onConfirm } = props;

	const intl = useIntl();
	// eslint-disable-next-line @typescript-eslint/unbound-method
	const { formatMessage } = intl;

	const appContext = useContext(AppContext);
	const userContext = useContext(UserContext);
	const { customer } = userContext;
	const [disableConfirm, setDisableConfirm] = useState(false);

	const headerAndConfirmMessage =
		users.length === 1
			? formatMessage(messages.deactivateHeader)
			: formatMessage(messages.deactivateHeaderPlural);

	let message = '';
	if (users.length === 1) {
		message = formatMessage(
			customer.topDownUserManagementEnabled
				? messages.deactivateContent
				: messages.deactivateContentBottomUp,
			{
				email: users[0].email,
			},
		);
	} else {
		message = formatMessage(
			customer.topDownUserManagementEnabled
				? messages.deactivateContentPlural
				: messages.deactivateContentPluralBottomUp,
			{
				count: users.length,
			},
		);
	}

	const deactivate = async () => {
		setDisableConfirm(true);
		const userIds = getUserIds(users);

		let result = (await post(`/io/v1/user/deactivate`, {
			userIds,
		})) as CompositeStatusDtoResponse;

		if (!result.success) {
			result = result.data as CompositeStatusDtoResponse;
		}
		const { successCount, failureCount, failureMessages } = result;
		if (successCount) {
			appContext.showToast({
				message:
					successCount > 1
						? intl.formatMessage(
								messages.deactivateUserSuccessPlural,
								{
									count: successCount,
								},
							)
						: intl.formatMessage(messages.deactivateUserSuccess),
				type: ToastType.SUCCESS,
			});
		}

		if (failureCount) {
			failureMessages.forEach((message) => {
				appContext.showToast({ message, type: ToastType.DANGER });
			});
		}
		setDisableConfirm(false);
		onConfirm();
	};

	return (
		<ConfirmationModal
			type={DESTRUCTIVE}
			onConfirm={deactivate}
			onCancel={onCancel}
			confirmText={headerAndConfirmMessage}
			cancelText={formatMessage(messages.noButton)}
			headerText={headerAndConfirmMessage}
			message={message}
			disableConfirm={disableConfirm}
		/>
	);
};

const useUserActivationActions = ({ refresh }: { refresh: () => void }) => {
	const intl = useIntl();
	const appContext = useContext(AppContext);
	const userContext = useContext(UserContext);

	const [selectedUsers, setSelectedUsers] = useState<User[] | null>(null);
	const [activateUser, setActivateUser] = useState<UserUpsertDto | null>(
		null,
	);
	const [showDeactivateModal, setShowDeactivateModal] = useState(false);
	const [showBulkActivateModal, setShowBulkActivateModal] = useState(false);

	const isCurrentLoggedInUser = (user: User) =>
		user.id === userContext.user.id;

	const activateAction = {
		icon: <Refresh />,
		message: messages.activateUserItem,
		isEnabled: (users: User[]) => users.length === 1,
		activateFn: async (users: User[]) => {
			const { id } = users[0];
			const { success, ...user } =
				await requestWithErrorHandling<UserUpsertDto>({
					method: 'get',
					url: `/io/v1/user/${id}`,
					appContext,
					intl,
				});
			if (success) {
				setActivateUser(user);
			}
		},
	};

	const activateModal = activateUser ? (
		<EditUserDialog
			key="activateUserDialog"
			user={activateUser}
			onCancel={() => {
				setActivateUser(null);
			}}
			onConfirm={() => {
				refresh();
				setActivateUser(null);
			}}
			activateMode={true}
		/>
	) : null;

	const bulkActivateAction = {
		icon: <Refresh />,
		message: messages.activateUserItemPlural,
		isEnabled: (users: User[]) => users.length,
		activateFn: (users: User[]) => {
			setSelectedUsers(users);
			setShowBulkActivateModal(true);
		},
	};

	const bulkActivateModal =
		showBulkActivateModal && selectedUsers ? (
			<AddProductsDialog
				key="bulkActivateDialog"
				selectedUsers={selectedUsers}
				isCustomerCare={false}
				activateMode={true}
				onCancel={() => {
					setSelectedUsers(null);
				}}
				onConfirm={() => {
					refresh();
					setSelectedUsers(null);
				}}
			/>
		) : null;

	const deactivateAction = {
		icon: <Cancel />,
		message: messages.deactivateUserItem,
		isEnabled: (users: User[]) => {
			return users.length > 0 && !users.some(isCurrentLoggedInUser);
		},
		activateFn: (users: User[]) => {
			setSelectedUsers(users);
			setShowDeactivateModal(true);
		},
	};

	const deactivateModal =
		showDeactivateModal && selectedUsers ? (
			<DeactivateConfirmationDialog
				key="deactivateDialog"
				users={selectedUsers}
				onCancel={() => {
					setSelectedUsers(null);
				}}
				onConfirm={() => {
					refresh();
					setSelectedUsers(null);
				}}
			/>
		) : null;

	return {
		activateAction,
		activateModal,
		bulkActivateAction,
		bulkActivateModal,
		deactivateAction,
		deactivateModal,
	};
};

export default useUserActivationActions;
