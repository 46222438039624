import isEmail from 'validator/lib/isEmail';
import isURL from 'validator/lib/isURL';
import messages from './validation.messages';
import { IntlShape } from 'react-intl';
export const minPasswordLength = 12;

/*
 * Returns a validation function for the given input type, with localized validation messages
 */
export const getValidationFunction = (
	{
		type,
		required,
	}: {
		type: string;
		required: boolean;
	},
	intl: IntlShape,
) => {
	switch (type) {
		case 'email':
			return (value: string) => {
				let error = '';
				if (!value) {
					error = intl.formatMessage(messages.requiredEmail);
				} else {
					const valid = isEmail(value);
					if (!valid) {
						error = intl.formatMessage(messages.invalidEmail);
					}
				}
				return error;
			};

		case 'password':
			return (value: string) => {
				let error = '';
				if (!value) {
					error = intl.formatMessage(messages.requiredPassword);
				} else if (value.length < minPasswordLength) {
					error = intl.formatMessage(messages.passwordLength);
				}
				return error;
			};

		case 'url':
			return (value = '') => {
				let error = '';
				const valid = isURL(value, {
					protocols: ['http', 'https'],
					require_protocol: true, // eslint-disable-line camelcase
				});
				// Allow localhost for dev servers
				const localHost = /https?:\/\/localhost:\d+/.test(value);
				if (!valid && !localHost) {
					error = intl.formatMessage(messages.invalidUrl);
				}
				return error;
			};

		default:
			return (value: string) => {
				if (required) {
					return value === ''
						? intl.formatMessage(messages.requiredField)
						: '';
				}
				return '';
			};
	}
};
