import React, { useContext } from 'react';
import { AppContext } from '../../../context';
import { useIntl } from 'react-intl';
import { ConfirmationModal } from '../../../components/common/modal/Modal';
import { DESTRUCTIVE } from '@planview/pv-uikit';
import { requestWithErrorHandling } from '../../../hooks/request/request';
import { UpdatedTenantsResultDto } from '../../../types/api/tenants';
import { Tenant } from '../../../types';
import messages from '../../../components/common/modal/Modal.messages';

type DeleteCustomProductDialogProps = {
	tenant?: Tenant;
	setShowDeleteCustomProduct: (showDeleteCustomProduct: boolean) => void;
};

const DeleteCustomProductDialog = (props: DeleteCustomProductDialogProps) => {
	const { tenant, setShowDeleteCustomProduct } = props;
	const appContext = useContext(AppContext);
	const intl = useIntl();

	return tenant ? (
		<ConfirmationModal
			type={DESTRUCTIVE}
			onConfirm={async () => {
				const { success, applications } =
					await requestWithErrorHandling<UpdatedTenantsResultDto>({
						method: 'delete',
						url: `/io/v1/admin/tenant/${tenant.customerId}/${tenant.envSelector.toString()}`,
						appContext,
						intl,
						successMessage:
							'Custom product has been deleted successfully. Please refresh the page to see the changes.',
					});

				if (success) {
					appContext.addApplications(applications);
					setShowDeleteCustomProduct(false);
				}
			}}
			onCancel={() => setShowDeleteCustomProduct(false)}
			confirmText={intl.formatMessage(messages.delete)}
			cancelText={intl.formatMessage(messages.cancel)}
			headerText="Delete custom product"
			message="This action will delete the custom product from this organization."
		/>
	) : null;
};

export default DeleteCustomProductDialog;
