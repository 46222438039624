import React, { useContext, useState } from 'react';
import messages from '../../actionsMenu/ActionsMenu.messages';
import { default as serverMessages } from '../../../../messages/server';
import { Application, EnvSelector, User } from '../../../../types';
import { AppContext } from '../../../../context';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { spacingPx, text } from '@planview/pv-utilities';
import {
	AssignUserTenantRoleDto,
	hasReadOnlyRole,
	Role,
	UserWithRole,
} from '../../../../types/api/roles';
import { ComboboxOption, Modal, MODAL_SMALL } from '@planview/pv-uikit';
import { post } from '../../../../hooks/request/request';
import { ToastType } from '../../../../types/toast';
import { Combobox, Label } from '@planview/pv-form';
import { ApiResponse } from '../../../../types/api/api';
import { Edit } from '@planview/pv-icons';

const PromptContainer = styled.div`
	${text.regular}
	margin-bottom: calc(${spacingPx.small} + 6px);
`;

type EditRoleActionProps = {
	application: Application;
	refresh: () => void;
};

type EditRoleModalProps = {
	onConfirm: () => void;
	onCancel: () => void;
	refresh: () => void;
	users: UserWithRole[];
	envSelector: EnvSelector;
	roles: Role[];
};

const EditRoleModal = (props: EditRoleModalProps) => {
	const appContext = useContext(AppContext);
	const intl = useIntl();
	const { users, envSelector, onConfirm, onCancel, refresh, roles } = props;

	const roleId = users[0]?.role;
	const hasSharedUserRole =
		!!roleId && users.every((user) => user.role === roleId);
	const role = hasSharedUserRole
		? roles.find((role) => role.id === roleId)
		: undefined;

	const [selectedRoleName, setSelectedRoleName] = useState<string>(
		roleId ?? null,
	);
	const [option, setOption] = useState<ComboboxOption | null>(
		role
			? ({
					label: role.displayText,
					value: role.id,
				} as ComboboxOption)
			: null,
	);

	const sendEditRoleRequest = async (
		userIds: string[],
		envSelector: EnvSelector,
		roleName: string,
	) => {
		const url = '/io/v1/user/role';
		const dto: AssignUserTenantRoleDto[] = userIds.map((userId) => {
			return {
				userId,
				envSelector,
				role: roleName,
			};
		});

		const { success, message } = (await post(url, dto)) as ApiResponse;
		appContext.showToast(
			success
				? {
						message:
							message || userIds.length == 1
								? intl.formatMessage(messages.updateRoleSuccess)
								: intl.formatMessage(
										messages.updateRolePluralSuccess,
									),
						type: ToastType.SUCCESS,
					}
				: {
						message:
							message ||
							intl.formatMessage(serverMessages.unexpectedError),
						type: ToastType.DANGER,
					},
		);
	};

	const filteredRoles = roles.filter((role) => !role.readOnly);
	const options = filteredRoles.map((role): ComboboxOption => {
		const { displayText, id } = role;
		return {
			label: displayText,
			value: id,
		};
	});

	const prompt =
		users.length > 1
			? messages.assignRolePromptPlural
			: messages.assignRolePrompt;

	return (
		<Modal
			headerText={intl.formatMessage(messages.editRole)}
			confirmText={intl.formatMessage(messages.save)}
			cancelText={intl.formatMessage(messages.cancel)}
			size={MODAL_SMALL}
			onConfirm={async () => {
				if (selectedRoleName) {
					await sendEditRoleRequest(
						users.map(({ id }) => id),
						envSelector,
						selectedRoleName,
					);
					onConfirm();
					refresh();
				}
			}}
			onCancel={onCancel}
			disableConfirm={
				!option || (roleId === selectedRoleName && hasSharedUserRole)
			}
		>
			<PromptContainer>
				<FormattedMessage {...prompt} />
			</PromptContainer>
			<Label>
				<FormattedMessage {...messages.role} />
			</Label>
			<Combobox
				value={option}
				onChange={(nextState) => {
					setOption(nextState);
					setSelectedRoleName(nextState?.value as string);
				}}
				options={options}
				clearable={false}
			/>
		</Modal>
	);
};

const useEditRoleAction = ({ application, refresh }: EditRoleActionProps) => {
	const { envSelector, roles } = application;
	const [showEditRole, setShowEditRole] = useState(false);
	const [selectedUsers, setSelectedUsers] = useState<UserWithRole[]>([]);

	const editRoleAction = {
		message: messages.editRole,
		icon: <Edit />,
		activateFn: (users: User[]) => {
			setSelectedUsers(users as UserWithRole[]);
			setShowEditRole(true);
		},
		isEnabled: (users: UserWithRole[]) =>
			users.length && !hasReadOnlyRole(users, roles),
	};

	const editRoleModal = showEditRole ? (
		<EditRoleModal
			key="editRoleModal"
			users={selectedUsers}
			envSelector={envSelector}
			roles={roles}
			onConfirm={() => setShowEditRole(false)}
			onCancel={() => setShowEditRole(false)}
			refresh={refresh}
		/>
	) : null;

	return { editRoleAction, editRoleModal };
};

export default useEditRoleAction;
