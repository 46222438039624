import { defineMessages } from 'react-intl';

const messages = defineMessages({
	notificationHeader: {
		id: 'admin.settings.notificationHeader',
		description: 'Header for notification settings in the settings tab',
		defaultMessage: 'Email settings',
	},
	notificationHeaderTooltip: {
		id: 'admin.settings.notificationHeaderTooltip',
		description: 'Text for notification settings page header tooltip',
		defaultMessage:
			'This is a self-service page allowing you to configure whether or not you want users to receive welcome emails.',
	},
	sendInvitationEmailsLabel: {
		id: 'admin.settings.sendInvitationEmails',
		description: 'Label for radio option to send welcome emails',
		defaultMessage: 'Send welcome emails',
	},
	notificationsInstructions: {
		id: 'admin.settings.notificationsInstructions',
		description: 'Intructions for the notifications settings tab',
		defaultMessage:
			'Enabling "Send welcome emails" will send an email to every new user when created, imported, or synced from other tenants. This can result in a large volume of emails. For more information, see <a>Planview Admin\'s email rules</a>.',
	},
	saveHeader: {
		id: 'admin.settings.email.confirmation.header',
		description: 'Header text for save email config confirmation dialog',
		defaultMessage: 'Save email settings?',
	},
	yesButton: {
		id: 'admin.settings.email.confirmation.yes',
		description: 'Button text for Yes',
		defaultMessage: 'Yes',
	},
	yesButtonWhileSaving: {
		id: 'admin.settings.email.confirmation.yesButtonWhileSaving',
		description: 'Button text after clicking yes and save is occurring',
		defaultMessage: 'Saving...',
	},
	enableEmailContent: {
		id: 'admin.settings.email.confirmation.enableEmailContent',
		description: 'Content for enable welcome email confirmation dialog',
		defaultMessage:
			'This will enable welcome emails for your account. Planview Admin will start sending emails to new users as described in our documentation.',
	},
	disableEmailContent: {
		id: 'admin.settings.email.confirmation.disableEmailContent',
		description: 'Content for disable welcome email confirmation dialog',
		defaultMessage:
			'This will disable welcome emails for your account. Planview Admin will no longer send emails to new users as described in our documentation.',
	},
});

export default messages;
