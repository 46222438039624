import { defineMessages } from 'react-intl';

const messages = defineMessages({
	okrTokenTitle: {
		id: 'admin.settings.okrTokenModal.title',
		description: 'Title for the OKR token modal',
		defaultMessage: 'Authentication token generated',
	},
	confirmationMessage: {
		id: 'admin.settings.okrTokenModal.confirmationMessage',
		description:
			'Description of token generation. {EnvironmentLabel} represents the product and environment such as platform-dev-e1',
		defaultMessage: 'Token generated for: {EnvironmentLabel}',
	},
	closeButton: {
		id: 'admin.settings.okrTokenModal.closeButton',
		description: 'Label for close button',
		defaultMessage: 'Close',
	},
	copyButton: {
		id: 'admin.settings.okrTokenModal.copyButton',
		description: 'Label for copy token button',
		defaultMessage: 'Copy token',
	},
	generateTokenFailure: {
		id: 'admin.settings.generateTokenFailure',
		description: 'Toast message for failure to generate a token',
		defaultMessage:
			'Sorry, something went wrong. Failed to generate a token.',
	},
	helpDocumentation: {
		id: 'admin.settings.okrTokenModal.helpDocumentation',
		description: 'Text to display for OKRs help page url',
		defaultMessage: 'help documentation',
	},
	helpDocumentationUrl: {
		id: 'admin.settings.okrTokenModal.helpDocumentationUrl',
		description: 'Url leading to OKRs help page',
		defaultMessage:
			'https://success.planview.com/Shared_capabilities/Planview_OKRs/Accessing_Planview_OKR_Data_with_OData',
	},
	usageMessage: {
		id: 'admin.settings.okrTokenModal.usageMessage',
		description:
			'Description of token usage. {HelpDocumentationLink} represents the hyperlink that leads to the OKRs help documentation site.',
		defaultMessage:
			'Use the token generated here for authenticating into an OData reporting service. See {HelpDocumentationLink} for more details.',
	},
});

export default messages;
