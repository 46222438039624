import messages from '../../actionsMenu/ActionsMenu.messages';
import { default as serverMessages } from '../../../../messages/server';
import { Application, EnvSelector, User } from '../../../../types';
import { Modal } from '../../../../components/common/modal/Modal';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { MODAL_SMALL } from '@planview/pv-uikit';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../../../context';
import styled from 'styled-components';
import { spacingPx, text } from '@planview/pv-utilities';
import { Cancel } from '@planview/pv-icons';
import { post } from '../../../../hooks/request/request';
import { ApiResponse } from '../../../../types/api/api';
import { ToastType } from '../../../../types/toast';
import { BulkDeprovisionDto } from '../../../../types/api/users';
import { hasReadOnlyRole, UserWithRole } from '../../../../types/api/roles';

const PromptContainer = styled.div`
	${text.regular}
	margin-bottom: calc(${spacingPx.small} + 6px);
`;

type RemoveUserActionProps = {
	application: Application;
	refresh: () => void;
};

type RemoveUsersConfirmationModalProps = {
	onConfirm: () => void;
	onCancel: () => void;
	refresh: () => void;
	users: User[];
	envSelector: EnvSelector;
};

const RemoveUserConfirmationModal = (
	props: RemoveUsersConfirmationModalProps,
) => {
	const appContext = useContext(AppContext);
	const intl = useIntl();
	const { users, envSelector, onConfirm, onCancel, refresh } = props;

	const sendRemoveUsersRequest = async (
		userIds: string[],
		envSelector: EnvSelector,
	) => {
		const url = '/io/v1/user/deprovision';

		const dto: BulkDeprovisionDto = {
			userIds,
			envSelectorToRemove: envSelector,
		};

		const { success, message } = (await post(url, dto)) as ApiResponse;

		appContext.showToast(
			success
				? {
						message:
							message || userIds.length == 1
								? intl.formatMessage(
										messages.removeUserFromProductToastSuccess as MessageDescriptor,
									)
								: intl.formatMessage(
										messages.removeUserFromProductToastSuccessPlural as MessageDescriptor,
									),
						type: ToastType.SUCCESS,
					}
				: {
						message:
							message ||
							intl.formatMessage(serverMessages.unexpectedError),
						type: ToastType.DANGER,
					},
		);
	};

	const header =
		users.length > 1
			? (messages.removeUserFromProductModalHeaderPlural as MessageDescriptor)
			: (messages.removeUserFromProductModalHeader as MessageDescriptor);

	const prompt =
		users.length > 1
			? (messages.removeUserFromProductModalBodyPlural as MessageDescriptor)
			: (messages.removeUserFromProductModalBody as MessageDescriptor);

	return (
		<Modal
			headerText={intl.formatMessage(header)}
			confirmText={intl.formatMessage(
				messages.removeUserFromProductModalButton as MessageDescriptor,
			)}
			cancelText={intl.formatMessage(messages.cancel)}
			size={MODAL_SMALL}
			onConfirm={async () => {
				// if (selectedRoleName) {
				await sendRemoveUsersRequest(
					users.map(({ id }) => id),
					envSelector,
				);
				onConfirm();
				refresh();
				// }
			}}
			onCancel={onCancel}
		>
			<PromptContainer>
				<FormattedMessage {...prompt} />
			</PromptContainer>
		</Modal>
	);
};

const useRemoveUserActions = ({
	application,
	refresh,
}: RemoveUserActionProps) => {
	const { envSelector, roles } = application;
	const [showRemoveUserConfirmation, setShowRemoveUserConfirmation] =
		useState(false);
	const [selectedUsers, setSelectedUsers] = useState<User[]>([]);

	const removeUserModal = showRemoveUserConfirmation ? (
		<RemoveUserConfirmationModal
			key={'removeUserConfirmationModal'}
			onConfirm={() => setShowRemoveUserConfirmation(false)}
			onCancel={() => setShowRemoveUserConfirmation(false)}
			refresh={refresh}
			users={selectedUsers}
			envSelector={envSelector}
		/>
	) : null;

	const activateFn = (users: User[]) => {
		setSelectedUsers(users);
		setShowRemoveUserConfirmation(true);
	};

	return {
		removeUserAction: {
			message: messages.removeUserFromProductMenu as MessageDescriptor,
			icon: <Cancel />,
			isEnabled: (users: UserWithRole[]) =>
				users.length === 1 && !hasReadOnlyRole(users, roles),
			isHidden: (users: User[]) => users.length !== 1,
			activateFn,
		},
		removeUsersAction: {
			message:
				messages.removeUserFromProductMenuPlural as MessageDescriptor,
			icon: <Cancel />,
			isEnabled: (users: UserWithRole[]) =>
				users.length > 1 && !hasReadOnlyRole(users, roles),
			isHidden: (users: User[]) => users.length === 1,
			activateFn,
		},
		removeUserModal,
	};
};

export default useRemoveUserActions;
