import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { spacingPx, text } from '@planview/pv-utilities';
import { injectIntl, IntlShape } from 'react-intl';
import { logError } from '../../../hooks/request/request';
import serverMessages from '../../../messages/server';

const ErrorMessage = styled.div`
	${text.h2}
	padding: ${spacingPx.small};
`;

interface State {
	hasError: boolean;
}

interface Props {
	intl: IntlShape;
	children: ReactNode;
}

/**
 * React component to act as an error boundary for other components. See
 * https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary.
 */
class ErrorBoundary extends React.Component<Props, State> {
	state = {
		hasError: false,
	};

	static getDerivedStateFromError(): State {
		// Update state so next render shows fallback UI.
		return { hasError: true };
	}

	async componentDidCatch(error: Error) {
		await logError('Error rendering Dovetail component', error);
	}

	render() {
		if (this.state.hasError) {
			const intl = this.props.intl;

			// render error
			return (
				<ErrorMessage data-testid="error-boundary">
					{intl.formatMessage(serverMessages.unexpectedError)}
				</ErrorMessage>
			);
		}

		return this.props.children;
	}
}

export default injectIntl(ErrorBoundary);
