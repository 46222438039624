import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { AppContext } from '../../../../context';
import { requestWithErrorHandling } from '../../../../hooks/request/request';
import messages from '../UsersPage.messages';
import EditUserDialog from '../../newUserDialog/EditUserDialog';
import { Edit } from '@planview/pv-icons';
import { User } from '../../../../types';
import { UserUpsertDto } from '../../../../types/api/users';

const useEditUserAction = ({ refresh }: { refresh: () => void }) => {
	const [editUser, setEditUser] = useState<UserUpsertDto | null>(null);
	const intl = useIntl();
	const appContext = useContext(AppContext);

	const editAction = {
		icon: <Edit />,
		message: messages.editUserItem,
		isEnabled: (users: User[]) => users.length === 1,
		activateFn: async (users: User[]) => {
			const { id } = users[0];
			const { success, ...user } =
				await requestWithErrorHandling<UserUpsertDto>({
					method: 'get',
					url: `/io/v1/user/${id}`,
					appContext,
					intl,
				});
			if (success) {
				setEditUser(user);
			}
		},
	};

	const editModal = editUser ? (
		<EditUserDialog
			key="editUserDialog"
			user={editUser}
			onCancel={() => setEditUser(null)}
			onConfirm={() => {
				refresh();
				setEditUser(null);
			}}
		/>
	) : null;

	return {
		editAction,
		editModal,
	};
};

export default useEditUserAction;
