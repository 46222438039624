import { createContext } from 'react';

export interface ProductTileContextProps {
	enableHiddenIndicator: boolean;
}

const defaultProductTileContext: ProductTileContextProps = {
	enableHiddenIndicator: false,
};

const ProductTileContext = createContext<ProductTileContextProps>(
	defaultProductTileContext,
);

export default ProductTileContext;
