const APP_VERSION_HEADER = 'x-pvid-implementation-version';
const CSRF_TOKEN_HEADER = 'x-csrf-token';

/**
 * Variable to store CSRF Token, with getters/setters
 */
let CSRF_TOKEN_VALUE;
const getCsrfToken = () => {
	return CSRF_TOKEN_VALUE;
};
const setCsrfToken = (token) => {
	CSRF_TOKEN_VALUE = token;
};

/**
 * Get headers object with CSRF headers.
 * {
 *     'X-Requested-With': 'XMLHttpRequest',
 *     'x-csrf-token': '<tokenvalue>'
 * }
 * @returns {Object} headers object to send in the request
 */
export const getRequestHeaders = () => {
	const csrfToken = getCsrfToken();
	const headers = {
		'X-Requested-With': 'XMLHttpRequest',
	};
	if (csrfToken) {
		headers[CSRF_TOKEN_HEADER] = csrfToken;
	}
	return headers;
};

/**
 * Get the value of the header from the response object.
 * @param {String} header - Header name to return the value for
 * @param {Object} response - HTTP response
 * @returns {String} header value
 */
const getResponseHeader = (header, response) => {
	const { headers = {} } = response;
	let value;
	// Cater to both fetch and axios
	if (typeof headers.get === 'function') {
		value = headers.get(header) || headers.get(header.toUpperCase());
	} else {
		value = headers[header] || headers[header.toUpperCase()];
	}
	return value;
};

/**
 * Check if there is a CSRF token sent in the response header. If so, store it and use it for subsequent requests
 * @param {Object} response - HTTP response
 * @returns {void}
 */
export const checkForCsrfToken = (response) => {
	const csrfToken = getResponseHeader(CSRF_TOKEN_HEADER, response);
	if (csrfToken) {
		setCsrfToken(csrfToken);
	}
};

/**
 * Sets success flag to the response object. We rely on this flag to take action in the UI.
 * @param {Object} data - JSON data returned by the server
 * @param {Object} response - HTTP response
 * @returns {Object} modified data object with success flag set
 */
const _setSuccessFlag = (data, response) => {
	const hasSuccessProperty = Object.hasOwnProperty.call(data, 'success');
	const successResponse =
		response.ok || (response.status >= 200 && response.status < 300);
	if (!hasSuccessProperty) {
		data.success = successResponse;
	} else {
		data.httpStatusCode = response.status;
	}
	return data;
};

/**
 * Adds "_appVersion" attribute to the response object. We rely on this to do a force refresh in the UI.
 * @param {Object} data - JSON data returned by the server
 * @param {Object} response - HTTP response
 * @returns {Object} modified data object with success flag set
 */
const _setAppVersion = (data, response) => {
	const appVersion = getResponseHeader(APP_VERSION_HEADER, response);
	if (appVersion) {
		data._appVersion = appVersion;
	}
	return data;
};

/**
 * Sets custom response attributes (success, _appVersion etc.) that are used throughout the app for request/response handling.
 * @param {Object} data - JSON data returned by the server
 * @param {Object} response - HTTP response
 * @returns {Object} modified data object with success flag set
 */
export const setCustomResponseAttributes = (data, response) => {
	const processedData = { ...data };
	_setSuccessFlag(processedData, response);
	_setAppVersion(processedData, response);
	return processedData;
};
