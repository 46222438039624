import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Navigate, useLocation, useResolvedPath } from 'react-router-dom';
import qs from 'qs';
import { SIZE_XLARGE, Spinner } from '@planview/pv-uikit';
import { AppContext, UserContext } from '../../../context';
import { getActiveSession } from '../../../hooks/request/request';
import { checkForUrlParams } from './BaseRoute';

type ProtectedRouteProps = {
	exempt?: boolean;
	element: ReactElement;
};

const ProtectedRoute = (props: ProtectedRouteProps) => {
	const { exempt, element } = props;
	const userContext = useContext(UserContext);
	const appContext = useContext(AppContext);
	const intl = useIntl();
	const location = useLocation();
	const [isLoading, setIsLoading] = useState(true);
	const path = useResolvedPath('').pathname;

	useEffect(() => {
		const { isAuthenticated } = userContext;
		setIsLoading(!isAuthenticated);
		if (isAuthenticated) {
			return;
		}

		void getActiveSession(userContext, appContext).finally(() => {
			const { search } = location;
			setIsLoading(false);
			checkForUrlParams({
				intl,
				search,
				appContext,
			});
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Deletes the error URL param and passes the rest to the child components. We don't want the error processed on
	 * every Route.
	 */
	const filterSearch = () => {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { error, ...rest } = qs.parse(location.search, {
			ignoreQueryPrefix: true,
		});
		return qs.stringify(rest, { addQueryPrefix: true });
	};

	if (isLoading) {
		return <Spinner size={SIZE_XLARGE} />;
	}

	const allowedAdminRoute = () => {
		const { isAdmin, isCustomerCareViewingPvCustomer } = userContext;
		return isAdmin && !isCustomerCareViewingPvCustomer();
	};

	const isAdminRoute = /\/admin/.test(path);
	const { isAuthenticated } = userContext;
	let redirectRoute = null;

	// 1. Check if the user is authenticated and if the route is exempt from admin/home route checks (e.g., Change Password)
	if (isAuthenticated && exempt) {
		return element;
	}

	// 2. Check if the user is authenticated. If not, redirect to Login page.
	if (!isAuthenticated) {
		redirectRoute = '/';

		// 3. Check if the user is an admin and if we are requesting a home page. If true, redirect to admin home page.
	} else if (allowedAdminRoute() && !isAdminRoute) {
		redirectRoute = '/admin';

		// 4. Check if the user is not an admin and if we are requesting an admin page. If true, redirect to home page.
	} else if (!allowedAdminRoute() && isAdminRoute) {
		redirectRoute = '/home';
	}

	if (redirectRoute) {
		return (
			<Navigate
				to={{
					pathname: redirectRoute,
					search: filterSearch(),
				}}
				state={{ redirect: true }}
			/>
		);
	}

	return element;
};

export default ProtectedRoute;
