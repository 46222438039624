import React from 'react';
import styled from 'styled-components';
import { text, theme, spacingPx } from '@planview/pv-utilities';
import { Modal } from '../../../common/modal/Modal';
import modalMessages from '../../../common/modal/Modal.messages';
import messages from './LinkModal.messages';
import { FormattedMessage, useIntl } from 'react-intl';
import { Application } from '../../../../types';

const Info = styled.div`
	margin: ${spacingPx.medium} 0;
`;

const Link = styled.a`
	${text.regular};
	color: ${theme.textLinkNormal};
	:link,
	:visited {
		color: ${theme.textLinkHover};
	}
`;

type LinkModalProps = {
	application: Application;
	onToggle: (value: boolean) => void;
};

const LinkModal = ({ application, onToggle }: LinkModalProps) => {
	const intl = useIntl();
	const { title, navigateUrl } = application;

	const onClose = () => onToggle(false);
	return (
		<Modal
			onConfirm={async () => {
				await navigator.clipboard.writeText(navigateUrl);
				onClose();
			}}
			onCancel={onClose}
			confirmText={intl.formatMessage(messages.copyButton)}
			cancelText={intl.formatMessage(modalMessages.close)}
			headerText={intl.formatMessage(messages.linkHeader)}
		>
			<Info>
				<FormattedMessage {...messages.linkInfo} values={{ title }} />
			</Info>
			<Info>
				<Link href={navigateUrl}>{navigateUrl}</Link>
			</Info>
		</Modal>
	);
};

export default LinkModal;
