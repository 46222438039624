import { defineMessages } from 'react-intl';

const messages = defineMessages({
	filterButtonTooltip: {
		id: 'common.filter.filterButtonTooltip',
		description: 'Tooltip for the button used to show the filter panel',
		defaultMessage: 'Show filters panel',
	},
	filterHeader: {
		id: 'common.filter.filterHeader',
		description: 'Header for the filter panel',
		defaultMessage: 'Filter',
	},
	clearFilters: {
		id: 'common.filter.clearFilters',
		description: 'Link to clear filters',
		defaultMessage: 'Clear filters',
	},
	clearAll: {
		id: 'common.filter.clearAll',
		description: 'Clear the checkboxes within a section of filter',
		defaultMessage: 'Clear',
	},
	filterPlaceholder: {
		id: 'common.filter.filterPlaceholder',
		description:
			'Placeholder text for filter a list of checkboxes, when there are more than 10',
		defaultMessage: 'Type to search',
	},
	selectAll: {
		id: 'common.filter.selectAll',
		description: 'Select all items in a checklist filter',
		defaultMessage: 'Select all',
	},
	filteredView: {
		id: 'common.filter.bar.filteredView',
		description:
			'Indicator label in the filter bar when filters are applied',
		defaultMessage: 'Filtered view',
	},
	yesFilter: {
		id: 'common.filter.yes',
		description: 'Checkbox label for when a value is yes/true',
		defaultMessage: 'Yes',
	},
	noFilter: {
		id: 'common.filter.no',
		description: 'Checkbox label for when a value is no/false',
		defaultMessage: 'No',
	},
	typeToSearch: {
		id: 'common.filter.typeToSearch',
		description: 'Placeholder for Search Input used to filter grids',
		defaultMessage: 'Type to search',
	},
});

export default messages;
