import React, { useContext } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { Body, Modal } from '../../../common/modal/Modal';
import { AppContext, AppContextProps } from '../../../../context';
import { requestWithErrorHandling } from '../../../../hooks/request/request';
import messages from './SetPrimaryModal.messages';
import { Application } from '../../../../types';
import { DESTRUCTIVE } from '@planview/pv-uikit';
import { UpdatedTenantsResultDto } from '../../../../types/api/tenants';

const handleSetPrimary = async ({
	intl,
	appContext,
	application,
}: {
	intl: IntlShape;
	appContext: AppContextProps;
	application: Application;
}) => {
	const { title, envSelectorEncodedString } = application;

	const successMessage = intl.formatMessage(
		messages.setPrimarySandboxSuccess,
		{
			title,
		},
	);

	const { success, applications = [] } =
		await requestWithErrorHandling<UpdatedTenantsResultDto>({
			method: 'post',
			url: `/io/v1/tenant/${envSelectorEncodedString}/setPrimary`,
			appContext,
			intl,
			successMessage,
		});

	if (success && applications) {
		appContext.addApplications(applications);
	}
};

type SetPrimaryModalProps = {
	application: Application;
	onToggle: (show: boolean) => void;
};

const SetPrimaryModal = ({ application, onToggle }: SetPrimaryModalProps) => {
	const appContext = useContext(AppContext);
	const intl = useIntl();

	return (
		<Modal
			type={DESTRUCTIVE}
			onConfirm={() => {
				onToggle(false);
				void handleSetPrimary({ intl, appContext, application });
			}}
			onCancel={() => onToggle(false)}
			confirmText={intl.formatMessage(messages.setPrimaryButton)}
			cancelText={intl.formatMessage(messages.noButton)}
			headerText={intl.formatMessage(messages.setPrimarySandboxHeader)}
		>
			<Body>
				<FormattedMessage
					{...messages.setPrimaryContentSandbox}
					values={{ title: application.title }}
				/>
			</Body>
			<FormattedMessage {...messages.setPrimaryContentSandboxWarning} />
		</Modal>
	);
};

export default SetPrimaryModal;
