import React, { useContext } from 'react';
import { AppContext } from '../../../context';
import { useIntl } from 'react-intl';
import { useForm } from '@mantine/form';
import { ComboboxOption, MODAL_MEDIUM } from '@planview/pv-uikit';
import { Modal } from '../../../components/common/modal/Modal';
import { UpdateCustomProductDto } from '../../../types/api/tenants';
import { Tenant } from '../../../types';
import messages from '../organization/NewOrganizationDialog.messages';
import { preventEventBubbling } from '../../common/PreventEventBubbling';
import { Combobox, Form, Input } from '@planview/pv-form';
import { SectionHeader } from '../../../components/common/Dialog';
import { requestWithErrorHandling } from '../../../hooks/request/request';

type EditCustomProductDialogProps = {
	customerId: string;
	tenant: Tenant;
	setShowEditCustomProduct: (showEditCustomProduct: boolean) => void;
};

const PRODUCTION_OPTION: ComboboxOption = {
	label: 'Production',
	value: 'Production',
};

const SANDBOX_OPTION: ComboboxOption = {
	label: 'Sandbox',
	value: 'Sandbox',
};

const EditCustomProductDialog = (props: EditCustomProductDialogProps) => {
	const { customerId, tenant, setShowEditCustomProduct } = props;
	const appContext = useContext(AppContext);
	const intl = useIntl();
	const form = useForm({
		initialValues: {
			name: tenant.title,
			url: tenant.url,
			associatedProduct: {
				label: tenant.productName,
				value: tenant.application,
			} as ComboboxOption,
			tenantGroup: tenant.sandbox ? SANDBOX_OPTION : PRODUCTION_OPTION,
		},
		validateInputOnBlur: true,
		validate: {
			name: (value) => (!value ? 'Please enter a name' : null),
			url: (value) => (!value ? 'Please enter a URL' : null),
			associatedProduct: (value) =>
				!value ? 'Please select a product' : null,
			tenantGroup: (value) =>
				!value ? 'Please select a tenant group' : null,
		},
	});

	return (
		<Modal
			id="edit-custom-product-dialog"
			size={MODAL_MEDIUM}
			onConfirm={async () => {
				const { name, url, tenantGroup } = form.values;
				if (tenantGroup) {
					const dataObj: UpdateCustomProductDto = {
						customerId,
						name,
						url,
						sandbox: tenantGroup.value === 'Sandbox',
					};

					const { success } = await requestWithErrorHandling({
						method: 'put',
						url: `/io/v1/admin/tenant/custom/${tenant.envSelector.toString()}`,
						dataObj,
						appContext,
						intl,
						successMessage:
							'Custom product has been updated successfully. Please refresh the page to see the changes.',
					});

					if (success) {
						setShowEditCustomProduct(false);
					}
				}
			}}
			onCancel={() => {
				setShowEditCustomProduct(false);
			}}
			confirmText={intl.formatMessage(messages.editButton)}
			cancelText={intl.formatMessage(messages.cancelButton)}
			headerText="Edit custom product"
			disableConfirm={!form.isValid()}
			{...preventEventBubbling}
		>
			<Form label="custom-product-form">
				<SectionHeader>
					<Combobox
						label="Associated product"
						id="custom-product-associated"
						disabled={true}
						options={appContext.availableApplications.map(
							(application) => {
								const { app: value, productName: label } =
									application;
								return {
									label,
									value,
								};
							},
						)}
						{...form.getInputProps('associatedProduct')}
					/>
				</SectionHeader>
				<SectionHeader>
					<Input
						label="Name"
						id="custom-product-name"
						withAsterisk={true}
						{...form.getInputProps('name')}
					/>
				</SectionHeader>
				<SectionHeader>
					<Input
						label="URL"
						id="custom-product-url"
						withAsterisk={true}
						{...form.getInputProps('url')}
					/>
				</SectionHeader>
				<SectionHeader>
					<Combobox
						label="Tenant group"
						id="custom-product-tenant-group"
						withAsterisk={true}
						options={[PRODUCTION_OPTION, SANDBOX_OPTION]}
						clearable={false}
						{...form.getInputProps('tenantGroup')}
					/>
				</SectionHeader>
			</Form>
		</Modal>
	);
};

export default EditCustomProductDialog;
