import React, { useContext, useState } from 'react';
import { IntlShape, MessageDescriptor, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { border, spacingPx } from '@planview/pv-utilities';
import { AppContext, AppContextProps } from '../../../../context';
import { ButtonEmpty } from '../../../common/button/Button';
import AddProductModal from '../modal/AddProductModal';
import EditModal from '../modal/EditModal';
import DeleteModal from '../modal/DeleteModal';
import { requestWithErrorHandling } from '../../../../hooks/request/request';
import messages from './Toolbar.messages';
import { Edit, Info, PlusCircle } from '@planview/pv-icons';
import productDetailMessages from '../../../../containers/admin/productDetails/ProductDetailsPage.messages';
import LinkModal from '../modal/LinkModal';
import ActionsMenu, { ActionProp } from './ActionsMenu';
import { isAppDeletable, updateApplications } from '../../../../helpers/util';
import { Application } from '../../../../types';
import { AvailableApplication } from '../../../../context/appContext';
import SetPrimaryModal from '../modal/SetPrimaryModal';

const TileSeparator = styled.hr`
	${border.normal()};
	border-width: 1px 0 0 0;
	margin: 0;
	opacity: 0.4;
`;

const Button = styled(ButtonEmpty)`
	width: fit-content;
	flex: 1;
	margin-bottom: 0;
	margin-right: ${spacingPx.small};
`;

const ButtonToolbar = styled.div`
	display: flex;
	justify-content: 'flex-end';
	padding-top: ${spacingPx.small};
`;

const MultilineButtonToolbar = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: 'flex-end';
	padding-top: ${spacingPx.small};
`;

const openNewWindow = (url: string, windowName: string) => {
	window.open(url, windowName, 'noopener');
};

/**
 * Only the available applications (that are enabled in the yml config) have a config that the admin needs to input.
 * We show the Connect dialog for this config.
 * @param appContext - App global context object
 * @param app - App identifier/Enum e.g., PPMPRO, PROJECTPLACE etc.
 * @return {boolean} true if the app is supported by Planview Admin
 */
const hasConnectConfig = (
	appContext: AppContextProps,
	appName: string,
): boolean => {
	return appContext.availableApplications.some(
		(application) => application.app === appName,
	);
};

const handleSyncClick = async ({
	intl,
	appContext,
	application,
}: {
	intl: IntlShape;
	appContext: AppContextProps;
	application: Application;
}) => {
	const envSelectorEncodedString = application.envSelectorEncodedString;
	await requestWithErrorHandling({
		method: 'post',
		url: `/io/v1/user/sync/${envSelectorEncodedString}`,
		appContext,
		intl,
		infoMessage: messages.tenantSyncInfo,
	});
};

export interface ActivateProductResponseDto {
	state: boolean;
}

const handleActivateTenantClick = async ({
	intl,
	activationState,
	appContext,
	app,
}: {
	intl: IntlShape;
	activationState: boolean;
	appContext: AppContextProps;
	app: Application;
}) => {
	const infoMessage = activationState
		? productDetailMessages.activateSuccess
		: productDetailMessages.deactivateSuccess;
	const requestData = {
		state: activationState,
	};
	const { success, state } =
		await requestWithErrorHandling<ActivateProductResponseDto>({
			method: 'put',
			url: `/io/v1/tenant/${app.envSelectorEncodedString}/usePlanviewId`,
			dataObj: requestData,
			appContext,
			intl,
			infoMessage,
		});
	if (success) {
		updateApplications(appContext, {
			...app,
			ssoEnabled: state,
		});
	}
};

const toggleHideFromOverview = async ({
	intl,
	appContext,
	application,
}: {
	intl: IntlShape;
	appContext: AppContextProps;
	application: Application;
}) => {
	const envSelectorEncodedString = application.envSelectorEncodedString;

	const { success } = await requestWithErrorHandling({
		method: 'post',
		url: `/io/v1/tenant/${envSelectorEncodedString}/toggleHideFromOverview`,
		appContext,
		intl,
	});

	if (success) {
		updateApplications(appContext, {
			...application,
			hideFromOverview: !application.hideFromOverview,
		});
	}
};

/**
 * Toolbar for "owned" tile type. Contains "Add Product" for tenant mapping and "Learn More" links
 */
const OwnedToolbar = ({
	availableApplication,
}: {
	availableApplication: AvailableApplication;
}) => {
	const appContext = useContext(AppContext);
	const [showConnectModal, setShowConnectModal] = useState(false);
	const { app } = availableApplication;
	const intl = useIntl();

	// @ts-expect-error dynamic message resolution isn't ts friendly
	const learnMoreUrlMsg = messages[
		`learnMoreUrl_${app.toLowerCase()}`
	] as MessageDescriptor;
	return (
		<>
			<TileSeparator />
			<MultilineButtonToolbar>
				<Button
					icon={<PlusCircle />}
					message={messages.addProduct}
					onClick={() => {
						const hasConfig = hasConnectConfig(appContext, app);
						setShowConnectModal(hasConfig);
					}}
				/>
				{learnMoreUrlMsg ? (
					<Button
						icon={<Info />}
						onClick={() => {
							const url = intl.formatMessage(learnMoreUrlMsg);
							openNewWindow(url, `learnMore_${app}`);
						}}
						message={messages.learnMore}
					/>
				) : (
					<div>&nbsp;</div>
				)}
				{showConnectModal ? (
					<AddProductModal
						id="add-product"
						availableApplication={availableApplication}
						onToggle={setShowConnectModal}
					/>
				) : null}
			</MultilineButtonToolbar>
		</>
	);
};

/**
 * Toolbar for "mapped" tile type. Contains -
 * 1. Product Description, "Manage Product" and Actions Tooltip for admin (in Products Page)
 * 2. Product Description, Actions Tooltip for end user (in Products Page)
 * 3. Product Description and is clickable (in Overview Page)
 * @param editMode - true for Products Page, false for Overview Page
 * @param application - Application object
 * @return Toolbar component for "mapped" tile type in edit mode and "null" when in non-edit mode.
 */
const MappedToolbar = ({
	editMode,
	application,
}: {
	editMode: boolean;
	application: Application;
}) => {
	const intl = useIntl();
	const navigate = useNavigate();
	const appContext = useContext(AppContext);
	const { featureFlags } = appContext;
	const [showEditModal, setShowEditModal] = useState(false);
	const [showLinkModal, setShowLinkModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showSetPrimaryModal, setShowSetPrimaryModal] = useState(false);
	const { application: applicationName, tenantId } = application.envSelector;

	const manageProduct = () =>
		navigate(`/admin/products/${applicationName}/${tenantId}`, {
			state: { redirect: true },
		});

	const actions = [
		{
			text: intl.formatMessage(messages.manageProduct),
			handler: () => manageProduct(),
		},
		{
			text: intl.formatMessage(messages.syncUsers),
			handler: () => {
				void handleSyncClick({
					intl,
					appContext,
					application,
				});
			},
			show: application.showUserSync,
		},
		{
			text: intl.formatMessage(
				productDetailMessages.activateTenantButton,
			),
			handler: () => {
				void handleActivateTenantClick({
					intl,
					activationState: true,
					appContext,
					app: application,
				});
			},
			show: !application.ssoEnabled && application.showPlanviewAdminSSO,
		},
		{
			text: intl.formatMessage(messages.getLinkToProduct),
			handler: () => setShowLinkModal(true),
			disabled: !application.ssoEnabled,
		},
		{
			text: intl.formatMessage(messages.editProductDescription),
			handler: () => setShowEditModal(true),
			show: !application.foundationApp,
		},
		{
			text: intl.formatMessage(messages.setPrimarySandbox),
			handler: () => setShowSetPrimaryModal(true),
			show: Boolean(application.setPrimarySandboxSupported),
		},
		{
			text: application.hideFromOverview
				? intl.formatMessage(messages.showOnOverview)
				: intl.formatMessage(messages.hideFromOverview),
			handler: () =>
				void toggleHideFromOverview({ intl, appContext, application }),
		},
	] as ActionProp[];
	if (isAppDeletable(featureFlags, application)) {
		actions.push({
			text: intl.formatMessage(messages.delete),
			handler: () => setShowDeleteModal(true),
		});
	}

	return editMode ? (
		<>
			<TileSeparator />
			<ButtonToolbar>
				<Button
					icon={<Edit />}
					onClick={() => manageProduct()}
					message={messages.manageProduct}
				/>
				<ActionsMenu actions={actions} />
			</ButtonToolbar>
			{showLinkModal && (
				<LinkModal
					application={application}
					onToggle={setShowLinkModal}
				/>
			)}
			{showEditModal && (
				<EditModal
					application={application}
					onToggle={setShowEditModal}
				/>
			)}
			{showDeleteModal && (
				<DeleteModal
					application={application}
					onToggle={setShowDeleteModal}
				/>
			)}
			{showSetPrimaryModal && (
				<SetPrimaryModal
					application={application}
					onToggle={setShowSetPrimaryModal}
				/>
			)}
		</>
	) : null;
};

export type TileType = 'mapped' | 'owned';

export type ToolbarProps = {
	tileType: TileType;
	editMode: boolean;
	application: Application | AvailableApplication;
};

/**
 * Renders Toolbar with buttons and actions menu icon, depending on the user and the page on which the product tile is displayed on.
 * This only checks and renders buttons for admin/end-user and for edit/non-edit modes (Products Page/Overview page respectively).
 * This doesn't check if the admin user has a username or owns an account. That is handled in ProductTile.js
 * @param props Props passed to ProductTile.js
 * @return Toolbar component for "mapped" or "owned" tile type.
 */
const Toolbar = (props: ToolbarProps) => {
	const { tileType = 'mapped', editMode, application } = props;

	// tileType can be 'owned' or 'mapped' only.
	return tileType === 'owned' ? (
		<OwnedToolbar
			availableApplication={application as AvailableApplication}
		/>
	) : (
		<MappedToolbar
			editMode={editMode}
			application={application as Application}
		/>
	);
};

export default Toolbar;
