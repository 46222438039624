import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { SIZE_SMALL } from '@planview/pv-uikit';
import { sizePx, spacingPx } from '@planview/pv-utilities';
import { ButtonEmpty } from '../../../components/common/button/Button';
import messages from './AdvancedConfiguration.messages';
import { PlusCircle, Trash } from '@planview/pv-icons';
import { Checkbox, Combobox, Input } from '@planview/pv-form';
import { HelpIcon } from './SSOSettings';

const Container = styled.div`
	margin-bottom: ${spacingPx.medium};
`;

const SectionTitle = styled.div`
	display: flex;
	align-items: center;
	font-weight: 600;
	line-height: ${sizePx.smallCompact};
	padding-bottom: ${spacingPx.small};
`;

const SectionInput = styled.div`
	display: flex;
	align-items: center;
`;

const ComboboxDiv = styled.div`
	margin-right: ${spacingPx.small};
	width: 100%;

	&:last-of-type {
		margin-right: 0;
	}
`;

const RequiredCheckbox = styled(Checkbox)`
	margin-right: ${spacingPx.small};
`;

const Attribute = styled(Input)`
	margin-right: ${spacingPx.small};
	margin-bottom: 0;
`;

const DeleteAttributeButton = styled(ButtonEmpty)`
	height: ${sizePx.small};
`;

const AdvancedConfiguration = ({
	samlRequestedAttributes,
	setSamlRequestedAttributes,
	metadataAttribute,
	setMetadataAttribute,
	samlNameIDPolicyFormat,
	setSamlNameIDPolicyFormat,
	samlNameIdLookupType,
	setSamlNameIdLookupType,
}) => {
	const intl = useIntl();
	const showSamlNameIDPolicyFormat = samlNameIdLookupType === 'EMAIL';

	const updateAttribute = (updatedValue, idx) => {
		const attrs = [...samlRequestedAttributes];
		const attr = attrs[idx];
		attrs[idx] = {
			...attr,
			...updatedValue,
		};
		setSamlRequestedAttributes(attrs);
	};

	const samlNameIdLookUpOptions = [
		{
			label: intl.formatMessage(messages.lookupTypeOptionEmailAddress),
			value: 'EMAIL',
		},
		{
			label: intl.formatMessage(messages.lookupTypeOptionLoginAlias),
			value: 'LOGIN_ALIAS',
		},
		{
			label: intl.formatMessage(
				messages.lookupTypeOptionUsernameFromEmail,
			),
			value: 'LOGIN_ALIAS_FROM_EMAIL',
		},
	];

	const samlNameIdPolicyFormatOptions = [
		{
			label: 'urn:oasis:names:tc:SAML:1.1:nameid-format:unspecified',
			value: 'urn:oasis:names:tc:SAML:1.1:nameid-format:unspecified',
		},
		{
			label: 'urn:oasis:names:tc:SAML:1.1:nameid-format:emailAddress',
			value: 'urn:oasis:names:tc:SAML:1.1:nameid-format:emailAddress',
		},
	];

	return (
		<Container data-testid="advanced-configuration">
			<SectionInput>
				<ComboboxDiv>
					<Combobox
						label={{
							text: intl.formatMessage(
								messages.selectLookupTypeField,
							),
							iconTooltip: intl.formatMessage(
								messages.helpTooltipSamlNameIDLookupType,
							),
						}}
						options={samlNameIdLookUpOptions}
						value={samlNameIdLookUpOptions.find(
							(option) => option.value === samlNameIdLookupType,
						)}
						onChange={(value) =>
							setSamlNameIdLookupType(value.value)
						}
						clearable={false}
					/>
				</ComboboxDiv>
				{showSamlNameIDPolicyFormat && (
					<ComboboxDiv>
						<Combobox
							label={{
								text: intl.formatMessage(
									messages.selectNameIDPolicyFormat,
								),
								iconTooltip: intl.formatMessage(
									messages.helpTooltipSamlNameIDFormat,
								),
							}}
							options={samlNameIdPolicyFormatOptions}
							value={samlNameIdPolicyFormatOptions.find(
								(option) =>
									option.value === samlNameIDPolicyFormat,
							)}
							onChange={(value) =>
								setSamlNameIDPolicyFormat(value.value)
							}
							clearable={false}
						/>
					</ComboboxDiv>
				)}
			</SectionInput>

			<SectionInput>
				<Input
					label={{
						text: intl.formatMessage(
							messages.samlUsernameAttribute,
						),
						iconTooltip: intl.formatMessage(
							messages.helpTooltipsamlUsernameAttribute,
						),
					}}
					value={metadataAttribute}
					onChange={(value) => setMetadataAttribute(value)}
				/>
			</SectionInput>

			<div>
				<SectionTitle>
					<FormattedMessage
						{...messages.additionalAttributesHeader}
					/>
					{HelpIcon(
						intl.formatMessage(
							messages.helpTooltipAdditionalAttributes,
						),
					)}
				</SectionTitle>

				{samlRequestedAttributes.map((attribute, idx) => (
					<SectionInput key={idx}>
						<RequiredCheckbox
							selected={attribute.required}
							size={SIZE_SMALL}
							onChange={(enabled) =>
								updateAttribute({ required: enabled }, idx)
							}
							label={intl.formatMessage(messages.requiredHeader)}
						/>
						<Attribute
							value={attribute.name}
							label={{
								text: intl.formatMessage(messages.nameHeader),
								iconTooltip: intl.formatMessage(
									messages.helpTooltipAdditionalAttributesName,
								),
							}}
							required
							nativeProps={{
								autoComplete: 'off',
							}}
							onChange={(value) =>
								updateAttribute({ name: value }, idx)
							}
						/>
						<Attribute
							value={attribute.nameFormat}
							label={{
								text: intl.formatMessage(
									messages.nameFormatHeader,
								),
								iconTooltip: intl.formatMessage(
									messages.helpTooltipAdditionalAttributesNameFormat,
								),
							}}
							required
							nativeProps={{
								autoComplete: 'off',
							}}
							onChange={(value) =>
								updateAttribute({ nameFormat: value }, idx)
							}
						/>
						<Attribute
							value={attribute.friendlyName}
							label={{
								text: intl.formatMessage(
									messages.friendlyNameHeader,
								),
								iconTooltip: intl.formatMessage(
									messages.helpTooltipAdditionalAttributesFriendlyName,
								),
							}}
							nativeProps={{
								autoComplete: 'off',
							}}
							onChange={(value) =>
								updateAttribute({ friendlyName: value }, idx)
							}
						/>
						<DeleteAttributeButton
							icon={<Trash />}
							onClick={() => {
								const attrs = [...samlRequestedAttributes];
								attrs.splice(idx, 1);
								setSamlRequestedAttributes(attrs);
							}}
						/>
					</SectionInput>
				))}
				<ButtonEmpty
					icon={<PlusCircle />}
					message={intl.formatMessage(messages.addAttribute)}
					onClick={() => {
						const attrs = [...samlRequestedAttributes];
						attrs.push({
							required: false,
							name: '',
							nameFormat: '',
							friendlyName: '',
						});
						setSamlRequestedAttributes(attrs);
					}}
				/>
			</div>
		</Container>
	);
};

export default AdvancedConfiguration;
