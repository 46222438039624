import React from 'react';
import { useIntl } from 'react-intl';
import messages from './ActionsMenu.messages';
import { DotsVertical } from '@planview/pv-icons';
import { ListItem } from '@planview/pv-uikit';
import { GridCellDropdownMenu } from '@planview/pv-grid';

/**
 * The actions for a user, used for row items in the user grid
 *
 * @param {Array/Object} rowData		array or single row of data for which actions are to be shown
 * @param {Object[]} actions			array of action configurations. Each element must have a message, isEnabled function, and onActivate function
 * @param {boolean} [alignRight=true]	align the menu to the right of its trigger, defaults true
 * @param {Object} [message=null]		optional message object to show instead of the vertical dots for the trigger
 * @param {boolean} [hideDisabled=true] true(default) to hide disabled items. false will show all action items
 * @return {JSX.Element} the actions menu
 */
const GridActionsMenu = ({
	tabIndex,
	rowData,
	actions,
	alignRight = true,
	message = null,
	hideDisabled = true,
}) => {
	const { formatMessage } = useIntl();
	const rowDataArray = Array.isArray(rowData) ? rowData : [rowData];

	const trigger =
		message != null
			? { label: formatMessage(message), withCaret: true }
			: {
					tooltip: formatMessage(messages.actionsToolTip),
					'aria-label': formatMessage(messages.rowActionsLabel),
					icon: <DotsVertical />,
				};

	return actions.length ? (
		<GridCellDropdownMenu
			alignRight={alignRight}
			trigger={trigger}
			label={formatMessage(messages.actionsToolTip)}
			tabIndex={tabIndex}
		>
			{
				actions
					.map((action) => {
						const isEnabled = action.isEnabled(rowDataArray);
						if (hideDisabled && !isEnabled) {
							return null;
						}

						return (
							<ListItem
								key={action.message.id}
								label={formatMessage(action.message)}
								icon={action.icon}
								disabled={!isEnabled}
								onActivate={() =>
									action.activateFn(rowDataArray)
								}
							/>
						);
					})
					.filter((item) => item) // remove nulls
			}
		</GridCellDropdownMenu>
	) : null;
};

GridActionsMenu.displayName = 'ActionsMenu';
export default GridActionsMenu;
