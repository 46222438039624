import { defineMessages } from 'react-intl';

const messages = defineMessages({
	editHeader: {
		id: 'admin.newUser.dialog.header.editUser',
		description: 'Header for Edit User dialog',
		defaultMessage: 'Edit user',
	},
	addUserHeader: {
		id: 'admin.newUser.dialog.header.addUserHeader',
		description: 'Header for New User (singular) dialog',
		defaultMessage: 'Add user',
	},
	mapHeader: {
		id: 'admin.newUser.dialog.header.mapUser',
		description: 'Header for New User dialog',
		defaultMessage: 'Map user',
	},
	addButton: {
		id: 'admin.newUser.dialog.button.add',
		description: 'Button text for Add',
		defaultMessage: 'Add',
	},
	editButton: {
		id: 'admin.newUser.dialog.button.save',
		description: 'Button text for Edit user dialog',
		defaultMessage: 'Save',
	},
	mapButton: {
		id: 'admin.newUser.dialog.button.map',
		description: 'Button text for Map',
		defaultMessage: 'Map',
	},
	cancelButton: {
		id: 'admin.newUser.dialog.button.cancel',
		description: 'Button text for Cancel',
		defaultMessage: 'Cancel',
	},
	email: {
		id: 'admin.newUser.dialog.email',
		description: 'Email input label',
		defaultMessage: 'Email',
	},
	firstName: {
		id: 'admin.newUser.dialog.firstName',
		description: 'First name input label',
		defaultMessage: 'First name',
	},
	lastName: {
		id: 'admin.newUser.dialog.lastName',
		description: 'Last name input label',
		defaultMessage: 'Last name',
	},
	loginAlias: {
		id: 'admin.newUser.dialog.loginAlias',
		description: 'Login alias input label',
		defaultMessage: 'Login alias',
	},
	invalidEmail: {
		id: 'admin.newUser.dialog.invalidEmail',
		description:
			'Error message to show when a valid email is not provided for a new user',
		defaultMessage: 'Please enter a valid email address',
	},
	invalidName: {
		id: 'admin.newUser.dialog.invalidName',
		description:
			'Error message to show when a valid name (first or last) is not provided for a new user',
		defaultMessage: 'Please enter first and last name',
	},
	localAuthOverride: {
		id: 'admin.newUser.dialog.enablePassword',
		description:
			'Require password for a Single Sign On user (overriding SSO behavior)',
		defaultMessage: 'Require password (bypass SSO)',
	},
	selectLocale: {
		id: 'admin.newUser.dialog.locale',
		description: 'Locale input label',
		defaultMessage: 'Language and Country',
	},
	productEnvironments: {
		id: 'admin.newUser.dialog.productEnvironments',
		description: 'Header label for a list of applications',
		defaultMessage: 'Product environments',
	},
	applicationSelectionMessage: {
		id: 'admin.newUser.dialog.applicationSelectionMessage',
		description:
			'Subheader for the list of applications that can be assigned to a user',
		defaultMessage:
			'This user will be given access to the following products and applications:',
	},
	userAppsProvisionIntiated: {
		id: 'admin.newUser.dialog.userAppsProvisionIntiated',
		description:
			'Toast message to appear after a user has successfully added or changed applications to an existing user',
		defaultMessage:
			'Application provisioning initiated, provisioning may take some time to display on users',
	},
	userAppsDeprovisionIntiated: {
		id: 'admin.newUser.dialog.userAppsDeprovisionIntiated',
		description:
			'Toast message to appear after a user has successfully removed applications for an existing user',
		defaultMessage:
			'Application deprovisioning initiated, deprovisioning may take some time to display on users',
	},
	addToBusinessUnitHeader: {
		id: 'admin.addToBusinessUnit.dialog.header',
		description: 'Header for Add to Business Unit modal',
		defaultMessage: 'User exists elsewhere',
	},
	addToBusinessUnitConfirm: {
		id: 'admin.addToBusinessUnit.dialog.confirm',
		description: 'Button text for Add to Business Unit modal confirmation',
		defaultMessage: 'Add existing user',
	},
});

export default messages;
