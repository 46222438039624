import React from 'react';
import { useIntl } from 'react-intl';
import { Input as BaseInput } from '@planview/pv-uikit';
import { Filter } from '@planview/pv-icons';
import messages from './Input.messages';

const debounce = function (func, delay) {
	let _debounce;
	return function () {
		const args = arguments;
		clearTimeout(_debounce);
		_debounce = setTimeout(() => func.apply(this, args), delay); // eslint-disable-line
	};
};

// Search Input for Grids. To be used only in conjunction with a grid.
export const GridSearchInput = ({ onChange, triggerSearch, ...rest }) => {
	const { formatMessage } = useIntl();
	const debouncedTriggerSearch = debounce(triggerSearch, 750);

	const handleOnChange = (inputValue) => {
		const callback = onChange;
		if (triggerSearch) {
			debouncedTriggerSearch(inputValue);
		}
		if (callback) {
			return callback(inputValue);
		}
	};

	return (
		<BaseInput
			icon={<Filter />}
			{...rest}
			nativeProps={{
				'aria-label': formatMessage(messages.typeToSearch),
			}}
			placeholder={formatMessage(messages.typeToSearch)}
			onChange={handleOnChange}
		/>
	);
};
