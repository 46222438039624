import React from 'react';

export interface DovetailContextProps {
	dovetailEnvironment: string;
	dovetailUrl: string;
	notificationBackendUrl: string;
}

export const defaultDovetailContext: DovetailContextProps = {
	dovetailEnvironment: '',
	dovetailUrl: '',
	notificationBackendUrl: '',
};

export default React.createContext(defaultDovetailContext);
