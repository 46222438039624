import React, { useContext, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { ConfirmationModal } from '../../../common/modal/Modal';
import { DESTRUCTIVE } from '@planview/pv-uikit';
import { AppContext, AppContextProps } from '../../../../context';
import { requestWithErrorHandling } from '../../../../hooks/request/request';
import messages from './DeleteModal.messages';
import { Application } from '../../../../types';
import { UpdatedTenantsResultDto } from '../../../../types/api/tenants';

const handleDelete = async ({
	intl,
	appContext,
	application,
}: {
	intl: IntlShape;
	appContext: AppContextProps;
	application: Application;
}) => {
	const { success, applications = [] } =
		await requestWithErrorHandling<UpdatedTenantsResultDto>({
			method: 'delete',
			url: `/io/v1/tenant/${application.envSelectorEncodedString}`,
			appContext,
			intl,
			successMessage: messages.deleteSuccess,
		});

	if (success && applications) {
		appContext.addApplications(applications);
	}
};

type DeleteModalProps = {
	application: Application;
	onToggle: (value: boolean) => void;
};

const DeleteModal = ({ application, onToggle }: DeleteModalProps) => {
	const appContext = useContext(AppContext);
	const intl = useIntl();
	const [disableConfirm, setDisableConfirm] = useState(false);
	const { title, primary } = application;

	let message = intl.formatMessage(messages.deleteContent, {
		title,
	});

	if (!primary) {
		message += ' ';
		message += intl.formatMessage(messages.deleteContentSandbox);
	}

	return (
		<ConfirmationModal
			type={DESTRUCTIVE}
			onConfirm={async () => {
				setDisableConfirm(true);
				await handleDelete({ intl, appContext, application });
				onToggle(false);
			}}
			disableConfirm={disableConfirm}
			onCancel={() => onToggle(false)}
			confirmText={intl.formatMessage(messages.deleteButton)}
			cancelText={intl.formatMessage(messages.noButton)}
			headerText={intl.formatMessage(messages.deleteHeader)}
			message={message}
		/>
	);
};

export default DeleteModal;
