import { defineMessages } from 'react-intl';

const messages = defineMessages({
	pageTitle: {
		id: 'home.products.pageTitle',
		description:
			'Header text within products tab for the area of products that have already been added',
		defaultMessage: 'Our Products',
	},
	addProductTitle: {
		id: 'home.products.addProductTitle',
		description:
			'Header text within products tab for the area of products to add',
		defaultMessage: 'Add Another Planview Product',
	},
	section: {
		id: 'home.products.section.products',
		description: 'Header text of the home products products section',
		defaultMessage: 'Products',
	},
	noProducts: {
		id: 'home.products.section.noProducts',
		description: 'Display text when no products are available',
		defaultMessage: 'You have no products yet.',
	},
	noAvailableProducts: {
		id: 'home.products.section.noAvailableProducts',
		description: 'Display text when no products are available',
		defaultMessage:
			'There are no available products for you to map. Please contact customer support.',
	},
	showService: {
		id: 'home.products.section.showService',
		description:
			'Display text to show Planview Service tiles on Overview tab',
		defaultMessage: 'Show Planview Services tiles on Overview tab',
	},
});

export default messages;
