import { useForm } from '@mantine/form';
import { useIntl } from 'react-intl';
import { default as validationMessages } from '../../helpers/validation.messages';

export const OrgHierarchyForm = () => {
	const intl = useIntl();
	const orgHierarchyForm = useForm({
		initialValues: {
			orgStructure: {
				label: '',
				value: '',
			},
			orgParent: {
				label: '',
				value: '',
			},
		},
		validateInputOnBlur: true,
		validate: {
			orgStructure: (val) =>
				val.value === ''
					? intl.formatMessage(validationMessages.requiredField)
					: null,
			orgParent: (val) =>
				val.value === ''
					? intl.formatMessage(validationMessages.requiredField)
					: null,
		},
	});
	return orgHierarchyForm;
};
