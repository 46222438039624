import { defineMessages } from 'react-intl';

const messages = defineMessages({
	deleteButton: {
		id: 'home.productTile.modal.delete.yes',
		description: 'Button text for delete',
		defaultMessage: 'Delete',
	},
	noButton: {
		id: 'home.productTile.modal.delete.no',
		description: 'Button text for No',
		defaultMessage: 'No',
	},
	deleteHeader: {
		id: 'home.productTile.modal.delete.header',
		description:
			'Header text for delete product instance confirmation dialog',
		defaultMessage: 'Delete Product Instance?',
	},
	deleteContent: {
		id: 'home.productTile.modal.delete.content',
		description: 'Content for delete product instance confirmation dialog',
		defaultMessage:
			'This will delete the product instance with the title "{title}".',
	},
	deleteContentSandbox: {
		id: 'home.productTile.modal.delete.deleteContentSandbox',
		description: 'Additional caution about deleting a tenant',
		defaultMessage:
			'Since this is a non-primary sandbox, this will remove data from associated applications such as OKRs.',
	},
	deleteSuccess: {
		id: 'home.productTile.modal.delete.success',
		description:
			'Text of success / non failure response to Delete product instance',
		defaultMessage: 'The product instance has been deleted.',
	},
});

export default messages;
