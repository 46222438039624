import React from 'react';
import { Customer } from '../types';
import { TenantsByEnvSelector } from '../types/api/tenants';

export interface CustomerContextProps {
	customer: Customer;
	tenantsByEnvSelector: TenantsByEnvSelector;
}

export const defaultCustomerState: Customer = {
	id: '',
	title: '',
	domain: '',
	topDownUserManagementEnabled: false,
	uofpBranchCode: '',
	orgTags: [],
	orgHierarchyEnabled: false,
	ssoEnabled: false,
	regulatoryRegion: 'US',
	samlNameIdLookupType: '',
	tenants: [],
	emailEnabled: false,
	hideServiceTiles: false,
};

export const defaultCustomerContext: CustomerContextProps = {
	customer: defaultCustomerState,
	tenantsByEnvSelector: {},
};

export default React.createContext<CustomerContextProps>(
	defaultCustomerContext,
);
