import React, { useContext, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { AppContext, AppContextProps } from '../../../../context';
import { Modal } from '../../../common/modal/Modal';
import { requestWithErrorHandling } from '../../../../hooks/request/request';
import messages from './EditModal.messages';
import { Input } from '@planview/pv-form';
import { Application } from '../../../../types';
import { TenantUpdateDto } from '../../../../types/api/tenants';
import { updateApplications } from '../../../../helpers/util';

/**
 * Update application's title
 */
const updateApplication = async (
	intl: IntlShape,
	appContext: AppContextProps,
	application: Application,
) => {
	const updateDto = {
		title: application.title,
	} as TenantUpdateDto;

	const { success } = await requestWithErrorHandling({
		method: 'put',
		url: `/io/v1/tenant/${application.envSelectorEncodedString}`,
		dataObj: updateDto,
		appContext,
		intl,
		successMessage: messages.editDescriptionSuccess,
	});
	return success;
};

type EditModalProps = {
	application: Application;
	onToggle: (value: boolean) => void;
};

const EditModal = ({ application, onToggle }: EditModalProps) => {
	const intl = useIntl();
	const [title, setTitle] = useState(application.title);
	const [saving, setSaving] = useState(false);
	const appContext = useContext(AppContext);

	return (
		<Modal
			disableConfirm={title === '' || saving}
			onConfirm={async () => {
				setSaving(true);
				const updatedApplication = {
					...application,
					title,
				};
				const success = await updateApplication(
					intl,
					appContext,
					updatedApplication,
				);

				// Update applications in the context to reflect the new description
				if (success) {
					updateApplications(appContext, updatedApplication);
				}

				onToggle(false);
			}}
			onCancel={() => onToggle(false)}
			headerText={intl.formatMessage(messages.editHeader)}
		>
			<Input
				defaultValue={title}
				onChange={(value) => setTitle(value)}
				label={intl.formatMessage(messages.inputLabel)}
				selected
			/>
		</Modal>
	);
};

export default EditModal;
