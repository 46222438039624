import React from 'react';
import { OrgTag } from '../types/api/orgTags';

export interface OrgTagsContextProps {
	availableOrgTags: OrgTag[];
	availableOrgTagsByName: Map<string, OrgTag>;
}

export const defaultOrgTagsContext: OrgTagsContextProps = {
	availableOrgTags: [],
	availableOrgTagsByName: new Map(),
};

export default React.createContext<OrgTagsContextProps>(defaultOrgTagsContext);
