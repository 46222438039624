import React, { useContext, useEffect, useState } from 'react';
import { IntlShape, MessageDescriptor, useIntl } from 'react-intl';
import {
	Toolbar,
	ToolbarButtonEmpty,
	ToolbarSection,
} from '@planview/pv-toolbar';
import messages from './OrganizationsPage.messages';
import NewOrganizationDialog from './NewOrganizationDialog';
import { Filter, PlusCircle } from '@planview/pv-icons';
import ToolbarActionsMenu from './ToolbarActionsMenu';
import { GridActionsMenuComponent } from '@planview/pv-grid';
import { AppContext, AppContextProps } from '../../../context';
import { Input, ListItem } from '@planview/pv-uikit';
import {
	CustomerDto,
	CustomerListResponse,
} from '../../../types/api/customers';
import { get, requestWithErrorHandling } from '../../../hooks/request/request';
import styled from 'styled-components';
import DeleteConfirmationDialog from './DeleteConfirmationDialog';
import EditOrganizationDialogWrapper from './EditOrganizationDialog';
import OrganizationsGrid from './OrganizationsGrid';
import useResendMessagesForTenantAction from '../organizationInfo/hooks/useResendTenantsAction';

const Container = styled.div`
	display: flex;
	overflow: hidden;
	height: 100%;
`;

const LOADING_ROWS = 50;

const toggleDeleteOrganization = async ({
	intl,
	appContext,
	row,
	refresh,
}: {
	intl: IntlShape;
	appContext: AppContextProps;
	row: CustomerDto;
	refresh: () => Promise<void>;
}) => {
	const orgId = row.id;
	const pendingDeletion = !row.pendingDeletionTime;
	const successMessage = (
		pendingDeletion
			? row.parent
				? messages.deleteParentOrgSuccess
				: messages.deleteOrgSuccess
			: messages.restoreOrgSuccess
	) as MessageDescriptor;

	await requestWithErrorHandling({
		method: 'post',
		url: `/io/v1/admin/customer/${orgId}/togglePendingDeletion`,
		dataObj: { pendingDeletion },
		appContext,
		intl,
		successMessage,
	});

	// Refresh grid
	void refresh();
};

const OrganizationsPage = () => {
	const intl = useIntl();
	const appContext = useContext(AppContext);
	const { enableCcOrgInfoTools } = appContext.featureFlags;

	const [showNewOrgDialog, setShowNewOrgDialog] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [loading, setLoading] = useState<boolean | number>(LOADING_ROWS);
	const [rows, setRows] = useState<CustomerDto[]>([]);
	const [orgToEdit, setOrgToEdit] = useState<CustomerDto | null>(null);
	const [orgToDelete, setOrgToDelete] = useState<CustomerDto | null>(null);

	const { setResendMessagesOrg, resendMessagesModal } =
		useResendMessagesForTenantAction();

	const loadCustomers = async () => {
		setLoading(LOADING_ROWS);
		const { results } = (await get(
			'/io/v1/admin/customer',
		)) as CustomerListResponse;

		setRows(results);
		setLoading(false);
	};

	const ActionsMenu = React.useCallback<
		GridActionsMenuComponent<CustomerDto>
	>(
		({ row }) => {
			const deleteLabel = row.pendingDeletionTime
				? 'Restore'
				: 'Delete organization';
			return (
				<>
					<ListItem
						label="Edit organization"
						onActivate={() => setOrgToEdit(row)}
					/>
					<ListItem
						label={deleteLabel}
						onActivate={() => {
							if (row.pendingDeletionTime) {
								void toggleDeleteOrganization({
									intl,
									appContext,
									row,
									refresh: loadCustomers,
								});
							} else {
								setOrgToDelete(row);
							}
						}}
					/>
					{!enableCcOrgInfoTools ? (
						<ListItem
							label="Resend messages to all tenants"
							onActivate={() => {
								setResendMessagesOrg(row);
							}}
						/>
					) : null}
				</>
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	const addOrgLabel = intl.formatMessage(messages.addOrg);

	useEffect(() => {
		void loadCustomers();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Toolbar label="Organizations Page Toolbar">
				<ToolbarSection>
					<Input
						icon={<Filter />}
						value={searchValue}
						onChange={(value) => {
							setSearchValue(value);
						}}
						placeholder="Search by name, ID or parent org name"
						style={{ width: '300px' }}
					/>
					<ToolbarButtonEmpty
						icon={<PlusCircle />}
						onClick={() => setShowNewOrgDialog(true)}
						tooltip={addOrgLabel}
					>
						{addOrgLabel}
					</ToolbarButtonEmpty>
				</ToolbarSection>
				<ToolbarSection>
					<ToolbarActionsMenu />
				</ToolbarSection>
			</Toolbar>
			<Container>
				<OrganizationsGrid
					actionsMenu={ActionsMenu}
					searchValue={searchValue}
					onClearFilters={() => setSearchValue('')}
					loading={loading}
					rows={rows}
				/>
			</Container>
			{showNewOrgDialog && (
				<NewOrganizationDialog
					onCancel={() => setShowNewOrgDialog(false)}
					onConfirm={() => {
						setShowNewOrgDialog(false);
						void loadCustomers();
					}}
				/>
			)}
			{orgToEdit && (
				<EditOrganizationDialogWrapper
					onCancel={() => {
						setOrgToEdit(null);
					}}
					onConfirm={() => {
						setOrgToEdit(null);
						void loadCustomers();
					}}
					organization={orgToEdit}
				/>
			)}
			{orgToDelete ? (
				<DeleteConfirmationDialog
					onCancel={() => {
						setOrgToDelete(null);
					}}
					onConfirm={() => {
						setOrgToDelete(null);
						void toggleDeleteOrganization({
							intl,
							appContext,
							row: orgToDelete,
							refresh: loadCustomers,
						});
					}}
					organization={orgToDelete}
				/>
			) : null}
			{resendMessagesModal}
		</>
	);
};

export default OrganizationsPage;
