import { Dropdown, DropdownListItem } from '@planview/pv-uikit';
import { ButtonEmpty } from '../../../common/button/Button';
import { DotsVertical } from '@planview/pv-icons';
import React from 'react';

export interface ActionProp {
	show?: boolean;
	disabled?: boolean;
	handler: () => void;
	text: string;
}

type ActionsMenuProps = {
	actions: ActionProp[];
};

const ActionsMenu = ({ actions }: ActionsMenuProps) => (
	<Dropdown
		alignRight={true}
		trigger={(active, _, triggerProps) => (
			<ButtonEmpty
				message={undefined}
				activated={active}
				icon={<DotsVertical />}
				{...triggerProps}
			/>
		)}
	>
		{actions
			.filter(({ show = true }) => show)
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			.map(({ handler, show, ...rest }, idx) => (
				<DropdownListItem key={idx} {...rest} onActivate={handler} />
			))}
	</Dropdown>
);
ActionsMenu.displayName = 'ActionsMenu';

export default ActionsMenu;
