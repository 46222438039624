import { defineMessages } from 'react-intl';

const messages = defineMessages({
	tabSSOSettings: {
		id: 'admin.settings.tabSSOSettings',
		description: 'Header for account sso settings tab',
		defaultMessage: 'SSO',
	},
	tabNotificationSettings: {
		id: 'admin.settings.tabNotificationSettings',
		description: 'Header for notification settings tab',
		defaultMessage: 'Notifications',
	},
	tabServicesSettings: {
		id: 'admin.settings.tabServicesSettings',
		description: 'Header for services settings tab',
		defaultMessage: 'Services',
	},
	tabClientCredentialsSettings: {
		id: 'admin.settings.tabClientCredentialsSettings',
		description: 'Header for OAuth2 credentials settings tab',
		defaultMessage: 'OAuth2 credentials',
	},
});

export default messages;
