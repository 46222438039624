import { defineMessages } from 'react-intl';

const messages = defineMessages({
	typeToSearch: {
		id: 'admin.users.grid.input.typeToSearch',
		description: 'Placeholder for Search Input used to filter Admin grids',
		defaultMessage: 'Type to search',
	},
	copyButton: {
		id: 'input.copyButton',
		description: 'Tooltip for the button to copy text to clipboard',
		defaultMessage: 'Copy to clipboard',
	},
	copyToClipboardMessage: {
		id: 'input.copyToClipboardMessage',
		description: 'Toast message for successful copy to clipboard',
		defaultMessage: 'Text copied to clipboard',
	},
	copyToClipboardFailure: {
		id: 'input.copyToClipboardFailure',
		description: 'Toast message for failure to copy to clipboard',
		defaultMessage:
			'Sorry, something went wrong. Failed to copy text to clipboard.',
	},
});

export default messages;
