import { defineMessages } from 'react-intl';

const messages = defineMessages({
	actionsToolTip: {
		id: 'admin.users.grid.actionsMenu.tooltip',
		description: 'Tool tip text for actions icon in Users grid',
		defaultMessage: 'Actions',
	},
	rowActionsLabel: {
		id: 'admin.users.grid.rowActionsLabel',
		description:
			'Aria Label for the row actions button (with vertical dots) in the users grid',
		defaultMessage: 'Row Actions',
	},
	removeMapping: {
		id: 'admin.users.menu.removeMapping',
		description: 'Remove Mapping menu item text',
		defaultMessage: 'Remove Mapping',
	},
	removeUserMappingSuccess: {
		id: 'admin.users.removeUserMappingSuccess',
		description:
			'Text of success / non failure response to make remove mapping for user',
		defaultMessage:
			'The selected users have been unmapped from this product instance.',
	},
	setRole: {
		id: 'admin.users.menu.setRole',
		description:
			'Set Role menu item text. Shown for applications that support multiple rows assigned by Planview Admin',
		defaultMessage: 'Set Role: {role}',
	},
	setRoleSuccess: {
		id: 'admin.users.setRoleSuccess',
		description: 'Text of success response to changing the role for a user',
		defaultMessage: 'The selected user has been assigned the {role} role.',
	},
	setLicenseTypeSuccess: {
		id: 'admin.users.setLicenseTypeSuccess',
		description:
			'Text of success response to changing the subscription type for a user',
		defaultMessage:
			'The selected user has been assigned the {licenseType} subscription type.',
	},
	editLicenseType: {
		id: 'admin.license.editLicenseType',
		description: 'Header for edit subscription type action and dialog',
		defaultMessage: 'Edit subscription type',
	},
	editRole: {
		id: 'admin.role.editRole',
		description: 'Header for edit role action and dialog',
		defaultMessage: 'Edit role',
	},
	save: {
		id: 'admin.license.save',
		description: 'Save',
		defaultMessage: 'Save',
	},
	cancel: {
		id: 'admin.license.cancel',
		description: 'Cancel',
		defaultMessage: 'Cancel',
	},
	licenseType: {
		id: 'admin.license.licenseType',
		description: 'Header for subscription type dropdown menu',
		defaultMessage: 'Subscription type',
	},
	role: {
		id: 'admin.role.role',
		description: 'Header for role dropdown menu',
		defaultMessage: 'Role',
	},
	assignPrompt: {
		id: 'admin.license.assignPrompt',
		description: 'Prompt for user to choose a subscription type to assign',
		defaultMessage: 'Assign selected user the following:',
	},
	assignPromptPlural: {
		id: 'admin.license.assignPromptPlural',
		description:
			'Prompt for user to choose a subscription type to assign multiple users via bulk option',
		defaultMessage: 'Assign selected users the following:',
	},
	assignRolePrompt: {
		id: 'admin.role.assignPrompt',
		description: 'Prompt for user to choose a role to assign',
		defaultMessage: 'Assign selected user the following:',
	},
	assignRolePromptPlural: {
		id: 'admin.role.assignPromptPlural',
		description:
			'Prompt for user to choose a role to assign multiple users via bulk option',
		defaultMessage: 'Assign selected users the following:',
	},
	updateRoleSuccess: {
		id: 'admin.role.update.success',
		defaultMessage: 'Selected user successfully updated.',
		description: 'Text shown when a role was updated successfully',
	},
	updateRolePluralSuccess: {
		id: 'admin.role.update.successPlural',
		defaultMessage: 'Selected users successfully updated.',
		description:
			'Text shown when a role was updated successfully for multiple users',
	},
	removeUserFromProductMenu: {
		id: 'admin.product.users.menu.removeUserFromProduct',
		description: 'Remove user from product',
		defaultMessage: 'Remove user',
	},
	removeUserFromProductMenuPlural: {
		id: 'admin.product.users.menu.removeUserFromProductPlural',
		description: 'Remove users (plural) from product',
		defaultMessage: 'Remove users',
	},
	removeUserFromProductModalBody: {
		id: 'admin.product.users.modal.body.removeUserFromProduct',
		description: 'Confirmation message to remove user from product',
		defaultMessage:
			'The selected user will be removed from this product. Do you want to continue?',
	},
	removeUserFromProductModalBodyPlural: {
		id: 'admin.product.users.modal.body.removeUserFromProductPlural',
		description:
			'Confirmation message to remove users (plural) from product',
		defaultMessage:
			'The selected users will be removed from this product. Do you want to continue?',
	},
	removeUserFromProductModalHeader: {
		id: 'admin.product.users.modal.header.removeUserFromProduct',
		description: 'Confirmation modal header to remove user from product',
		defaultMessage: 'Remove user from product',
	},
	removeUserFromProductModalHeaderPlural: {
		id: 'admin.product.users.modal.header.removeUserFromProductPlural',
		description:
			'Confirmation modal header to remove users (plural) from product',
		defaultMessage: 'Remove users from product',
	},
	removeUserFromProductModalButton: {
		id: 'admin.product.users.modal.button.removeUsersFromProduct',
		description: 'Confirmation modal button to remove users from product',
		defaultMessage: 'Remove',
	},
	removeUserFromProductToastSuccess: {
		id: 'admin.product.users.toast.success.removeUserFromProduct',
		description: 'Success message shown after removing user from product',
		defaultMessage: 'The user was successfully removed from this product.',
	},
	removeUserFromProductToastSuccessPlural: {
		id: 'admin.product.users.toast.success.removeUserFromProductPlural',
		description:
			'Success message shown after removing users (plural) from product',
		defaultMessage:
			'The users were successfully removed from this product.',
	},
});

export default messages;
