import { defineMessages } from 'react-intl';

const messages = defineMessages({
	cancel: {
		id: 'app.modal.cancel',
		description: 'Cancel button text in a modal',
		defaultMessage: 'Cancel',
	},
	save: {
		id: 'app.modal.save',
		description: 'Save button text in a modal',
		defaultMessage: 'Save',
	},
	delete: {
		id: 'app.modal.delete',
		description: 'Delete button text in a modal',
		defaultMessage: 'Delete',
	},
	continueText: {
		id: 'app.modal.continueText',
		description:
			'Text for confirmation dialog. Usually displayed below the action consequence text',
		defaultMessage: 'Are you sure you want to continue?',
	},
	close: {
		id: 'app.modal.close',
		description: 'Button text for Close button in a modal',
		defaultMessage: 'Close',
	},
	required: {
		id: 'app.modal.required',
		description: 'Label indicating a form field is required',
		defaultMessage: 'Required',
	},
});

export default messages;
