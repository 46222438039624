import qs from 'qs';

export const getUrl = ({
	url,
	urlParams = {},
	sortBy,
	sortDirection,
	searchQuery,
	limit,
	filterParams,
	deactivated,
} = {}) => {
	// Query params server understands
	const queryObj = {
		q: searchQuery,
		sortBy,
		sortDirection,
		limit,
		deactivated,
		...urlParams,
	};

	if (filterParams && Object.keys(filterParams).length > 0) {
		queryObj.filterParams = JSON.stringify(filterParams);
	}

	const queryString = qs.stringify(queryObj);
	if (url.indexOf('?') > 0) {
		return `${url}&${queryString}`;
	}
	return `${url}?${queryString}`;
};
