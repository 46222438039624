import { defineMessages } from 'react-intl';

const messages = defineMessages({
	licenseTypeHeader: {
		id: 'admin.licenseInfo.licenseTypeHeader',
		description:
			'Header label for license type details. {LicenseType} represents the type of subscription.',
		defaultMessage: '{LicenseType} subscribers',
	},
	total: {
		id: 'admin.licenseInfo.total',
		description: 'Total',
		defaultMessage: 'Total',
	},
	unlimited: {
		id: 'admin.licenseInfo.unlimited',
		description: 'Unlimited',
		defaultMessage: 'Unlimited',
	},
	used: {
		id: 'admin.licenseInfo.used',
		description: 'Used',
		defaultMessage: 'Used',
	},
});

export default messages;
