import { defineMessages } from 'react-intl';

const messages = defineMessages({
	addHeader: {
		id: 'pvadmin.newOrg.dialog.header.addOrg',
		description: 'Header for New Organization dialog',
		defaultMessage: 'Add organization',
	},
	editHeader: {
		id: 'pvadmin.editOrg.dialog.header.editOrg',
		description: 'Header for Edit Org dialog',
		defaultMessage: 'Edit organization',
	},
	addButton: {
		id: 'pvadmin.newOrg.dialog.button.add',
		description: 'Button text for Add',
		defaultMessage: 'Add',
	},
	editButton: {
		id: 'pvadmin.editOrg.dialog.button.edit',
		description: 'Button text for saving changes',
		defaultMessage: 'Save',
	},
	cancelButton: {
		id: 'pvadmin.newOrg.dialog.button.cancel',
		description: 'Button text for Cancel',
		defaultMessage: 'Cancel',
	},
	email: {
		id: 'pvadmin.newOrg.dialog.email',
		description: 'Email input label',
		defaultMessage: 'Email',
	},
	firstName: {
		id: 'pvadmin.newOrg.dialog.firstName',
		description: 'First name input label',
		defaultMessage: 'First name',
	},
	lastName: {
		id: 'pvadmin.newOrg.dialog.lastName',
		description: 'Last name input label',
		defaultMessage: 'Last name',
	},
	createOrgSuccess: {
		id: 'pvadmin.newOrg.dialog.create.success',
		description: 'Success message on creating a new organization',
		defaultMessage: 'The organization has been created.',
	},
	editOrgSuccess: {
		id: 'pvadmin.newOrg.dialog.edit.success',
		description: 'Success message on editing a new organization',
		defaultMessage: 'The organization has been updated.',
	},
	selectRegion: {
		id: 'pvadmin.newOrg.dialog.select.region',
		description: 'Dropdown field label for regulatory region',
		defaultMessage: 'Select a regulatory region',
	},
	regRegion: {
		id: 'pvadmin.newOrg.dialog.regulatoryRegion',
		description: 'Header label for regulatory region',
		defaultMessage: 'Regulatory region',
	},
	organizationTitle: {
		id: 'pvadmin.newOrg.dialog.organizationName',
		description: 'Header label for organization name',
		defaultMessage: 'Organization name',
	},
	organizationDomain: {
		id: 'pvadmin.newOrg.dialog.organizationDomain',
		description: 'Header label for organization domain',
		defaultMessage: 'Organization domain',
	},
	organizationDomainHelpTooltip: {
		id: 'pvadmin.newOrg.dialog.organizationDomainHelpTooltip',
		description: 'Tooltip help for organization domain restrictions',
		defaultMessage:
			'This field can only have alphanumeric characters or dashes.',
	},
	orgAdminHeader: {
		id: 'pvadmin.newOrg.dialog.orgAdminHeader',
		description: 'Organization Administrator info section header',
		defaultMessage: 'ORGANIZATION ADMINISTRATOR INFO',
	},
	topDownUserManagementHeader: {
		id: 'pvadmin.newOrg.dialog.topDownUserManagement',
		description: 'Header label for top down user management selection',
		defaultMessage: 'Top-Down User Management',
	},
	topDownUserManagementWarningMessage: {
		id: 'pvadmin.newOrg.dialog.topDownUserManagementWarningMessage',
		description: 'Subheader for top down user management selection',
		defaultMessage:
			'Enabling this has downstream impact on this organization’s workflow. Please proceed with caution.',
	},
	topDownUserManagementCheckBox: {
		id: 'pvadmin.newOrg.dialog.topDownUserManagementCheckBox',
		description: 'Enable top down user management for an organization',
		defaultMessage: 'Enable top-down user management',
	},
	branchCodeLabel: {
		id: 'pvadmin.newOrg.dialog.branchCodeLabel',
		description: 'Label for University of Planview Branch Code',
		defaultMessage: 'SFDC ID number / UP branch code',
	},
	orgTagLabel: {
		id: 'pvadmin.newOrg.dialog.orgTagLabel',
		description: 'Organization Tag',
		defaultMessage: 'Organization Tag',
	},
	productLicensesHeader: {
		id: 'pvadmin.newOrg.dialog.productLicensesHeader',
		description: 'Product subscriptions section header',
		defaultMessage: 'PRODUCT SUBSCRIPTIONS',
	},
	productLicensesDescription: {
		id: 'pvadmin.newOrg.dialog.productLicensesDescription',
		description:
			'Product subscription note that gives details on form input behavior',
		defaultMessage:
			"Note: Subscribed products are automatically added to the organization's instance of PV Admin.",
	},
	productEnabledLabel: {
		id: 'pvadmin.newOrg.dialog.productEnabledLabel',
		description: 'Label on Ideaplace switch in product licenses section',
		defaultMessage: 'Enabled',
	},
	productDisabledLabel: {
		id: 'pvadmin.newOrg.dialog.productDisabledLabel',
		description: 'Label on Ideaplace switch in product licenses section',
		defaultMessage: 'Disabled',
	},
	disableTeamTapLicensesHeader: {
		id: 'pvadmin.editOrg.dialog.disableTeamTapLicensesHeader',
		description:
			'Header and confirm text for disable teamtap confirmation modal',
		defaultMessage: 'Disable TeamTap subscriptions',
	},
	disableTeamTapLicensesMessage: {
		id: 'pvadmin.editOrg.dialog.disableTeamTapLicensesMessage',
		description: 'Body text for disable teamtap confirmation modal',
		defaultMessage:
			'Are you sure you want to disable IdeaPlace TeamTap subscriptions?',
	},
	disableTeamTapLicensesWarning: {
		id: 'pvadmin.editOrg.dialog.disableTeamTapLicensesWarning',
		description: 'Body warning text for disable teamtap confirmation modal',
		defaultMessage:
			'Disabling TeamTap subscriptions will also remove IdeaPlace from this organization and deprovision all existing users from IdeaPlace.',
	},
	roadmapsToZeroHeader: {
		id: 'pvadmin.editOrg.dialog.roadmapsToZeroHeader',
		description:
			'Header and confirm text for setting roadmaps to zero confirmation modal',
		defaultMessage: 'Set Roadmaps Enterprise subscriptions to zero',
	},
	roadmapsToZeroMessage: {
		id: 'pvadmin.editOrg.dialog.roadmapsToZeroMessage',
		description:
			'Body text for setting roadmaps to zero confirmation modal',
		defaultMessage:
			'Are you sure you want to set the number of Roadmaps Enterprise subscriptions to zero?',
	},
	roadmapsToZeroWarning: {
		id: 'pvadmin.editOrg.dialog.roadmapsToZeroWarning',
		description:
			'Body warning text for setting roadmaps to zero confirmation modal',
		defaultMessage:
			'Setting Roadmaps Enterprise subscriptions to zero will update all existing users in Roadmaps to use the Teams subscription.',
	},
	orgHierarchyOrgStructureHeader: {
		id: 'pvadmin.editOrg.dialog.orgHierarchyOrgStructureHeader',
		description:
			'Header text for organization structure portion of org hierarchy settings',
		defaultMessage: 'Organizational structure',
	},
	cautionText: {
		id: 'pvadmin.editOrg.dialog.cautionText',
		description: 'Warning label for edit org settings',
		defaultMessage: 'Caution',
	},
	orgHierarchyOrgStructureWarning: {
		id: 'pvadmin.editOrg.dialog.orgHierarchyOrgStructureWarning',
		description:
			'Warning text for organization structure portion of org hierarchy settings',
		defaultMessage:
			'Converting a standalone organization to a child organization is an irreversible change.',
	},
	orgHierarchyParentWarning: {
		id: 'pvadmin.editOrg.dialog.orgHierarchyParentWarning',
		description:
			'Warning text for parent portion of org hierarchy settings',
		defaultMessage:
			'Once a parent organization has been specified it cannot be changed.',
	},
	orgHierarchyOrgStructureStandaloneOption: {
		id: 'pvadmin.editOrg.dialog.orgHierarchyOrgStructureStandaloneOption',
		description:
			'Label text for organization structure standalone option of org hierarchy settings',
		defaultMessage: 'Standalone organization',
	},
	orgHierarchyOrgStructureChildOption: {
		id: 'pvadmin.editOrg.dialog.orgHierarchyOrgStructureChildOption',
		description:
			'Label text for organization structure child option of org hierarchy settings',
		defaultMessage: 'Child organization',
	},
	orgHierarchyParentHeader: {
		id: 'pvadmin.editOrg.dialog.orgHierarchyParentHeader',
		description: 'Header text for parent portion of org hierarchy settings',
		defaultMessage: 'Parent',
	},
	orgHierarchyParentBoxLabel: {
		id: 'pvadmin.editOrg.dialog.orgHierarchyParentBoxLabel',
		description: 'Label text for parent combobox of org hierarchy settings',
		defaultMessage: 'Find or add parent organization',
	},
	orgHierarchyMessage: {
		id: 'pvadmin.editOrg.dialog.orgHierarchyMessage',
		description: 'Body text for org hierarchy confirmation modal',
		defaultMessage:
			'Are you sure you want to proceed with the irreversible changes?',
	},
	orgHierarchyWarning: {
		id: 'pvadmin.editOrg.dialog.orgHierarchyWarning',
		description: 'Body warning text for org hierarchy confirmation modal',
		defaultMessage:
			'Once specified as a child of a parent organization, the child organization cannot be reverted back to a standalone organization nor change its parent.',
	},
	orgHierarchyHeader: {
		id: 'pvadmin.editOrg.dialog.orgHierarchyHeader',
		description: 'Header text for org hierarchy confirmation modal',
		defaultMessage: 'Confirm to proceed?',
	},
	orgHierarchyConfirmationButton: {
		id: 'pvadmin.editOrg.dialog.orgHierarchyConfirmationButton',
		description:
			'Confirmation button text for org hierarchy confirmation modal',
		defaultMessage: 'Proceed with irreversible changes',
	},
	orgHierarchyCheckboxLabel: {
		id: 'pvadmin.editOrg.dialog.orgHierarchyCheckboxLabel',
		description: 'Checkbox text for org hierarchy confirmation modal',
		defaultMessage:
			'I understand that the stated changes are irreversible and confirm to proceed anyway',
	},
	orgHierarchyCheckboxWarning: {
		id: 'pvadmin.editOrg.dialog.orgHierarchyCheckboxWarning',
		description:
			'Toast text for unchecked box in org hierarchy confirmation modal',
		defaultMessage:
			'Please select the checkbox that you understand the changes are irreversible before proceeding.',
	},
});

export default messages;
