import React from 'react';

// Temp fix for modals that are rendering out of the grid to address focus bug
export const preventEventBubbling = {
	onKeyDown: (e: React.KeyboardEvent) => {
		e.stopPropagation();
	},
	onClick: (e: React.MouseEvent) => {
		e.stopPropagation();
	},
	onFocus: (e: React.FocusEvent) => {
		e.stopPropagation();
	},
};
