import styled from 'styled-components';
import { theme, spacingPx } from '@planview/pv-utilities';

export const PageHeader = styled.div`
	font-size: 24px;
	padding: ${spacingPx.small} 0 0 0;
`;
PageHeader.displayName = 'PageHeader';

export const FilterContainer = styled.div<{ $filterVisible?: boolean }>`
	height: 100%;
	width: 280px;
	flex-shrink: 0;
	transform: ${(props) =>
		props.$filterVisible ? 'translateX(0)' : 'translateX(-280px)'};
	transition: transform ease-in 0.25s;
	display: flex;
	flex-direction: row;
`;
FilterContainer.displayName = 'FilterContainer';

export const MainContainer = styled.div<{
	$filterVisible?: boolean;
	$detailsPaneVisible?: boolean;
}>`
	height: 100%;
	background: ${theme.backgroundNeutral0};
	max-width: calc(
		100% -
			${(props) =>
				props.$filterVisible
					? props.$detailsPaneVisible
						? '280px - 460px'
						: '280px'
					: props.$detailsPaneVisible
						? '460px'
						: '0px'}
	);
	min-width: calc(
		100% -
			${(props) =>
				props.$filterVisible
					? props.$detailsPaneVisible
						? '280px - 460px'
						: '280px'
					: props.$detailsPaneVisible
						? '460px'
						: '0px'}
	);
	transform: ${(props) =>
		props.$filterVisible ? 'translateX(0)' : 'translateX(-280px)'};
	transition: all ease-in 0.25s;
`;
MainContainer.displayName = 'MainContainer';

export const ListWrapper = styled.div<{ $activeFilters?: boolean }>`
	height: ${(props) =>
		props.$activeFilters ? `calc(100% - ${spacingPx.large})` : '100%'};
`;
