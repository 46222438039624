import { defineMessages } from 'react-intl';

const messages = defineMessages({
	missingProductsMessage: {
		id: 'admin.settings.missing.products',
		description:
			'Message displayed when user has no products that require settings',
		defaultMessage:
			'You have not added any products that require associated service settings.',
	},
});

export default messages;
