import React, {
	Dispatch,
	lazy,
	SetStateAction,
	Suspense,
	useState,
} from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import qs from 'qs';
import {
	align,
	border,
	theme,
	color,
	sizePx,
	spacingPx,
} from '@planview/pv-utilities';
import { Footer } from '../../components/common/Layout';
import { PlanviewNavHeaderLogo } from '../../assets';
import BackgroundImage from '../../assets/login_bg.jpg';
import LoginForm from './forms/login/LoginForm';
import { SIZE_LARGE, Spinner } from '@planview/pv-uikit';
import isEmail from 'validator/lib/isEmail';
import { useLocation } from 'react-router-dom';

const ChangePasswordForm = lazy(
	() => import('./forms/changePassword/ChangePasswordForm'),
);
const CreateAccountForm = lazy(
	() => import('./forms/createAccount/CreateAccountForm'),
);
const ForgotPasswordForm = lazy(
	() => import('./forms/forgotPassword/ForgotPasswordForm'),
);
const ResetPasswordForm = lazy(
	() => import('./forms/resetPassword/ResetPasswordForm'),
);

const BackgroundContainer = styled.div`
	${align.centerV}
	background: url(${
		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
		BackgroundImage
	}) no-repeat center center fixed;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	flex-basis: auto;
	flex-direction: column;
	flex-grow: 1;
	flex-shrink: 0;
	flex-wrap: nowrap;
	justify-content: flex-start;
	width: 100%;
	width: -moz-available;
	width: -webkit-fill-available;
	width: stretch;
	color-scheme: light;
`;

const LoginContainer = styled.div`
	display: flex;
	flex-direction: column;
	background-color: ${theme.backgroundNeutral50};
	${border.normal()};
	flex: none;
	margin: ${spacingPx.medium};
	max-width: 400px;
	padding: ${spacingPx.medium};
	width: 100%;
	width: -moz-available;
	width: -webkit-fill-available;
	width: stretch;
`;

const PlanviewHeader = styled.div`
	height: ${sizePx.medium};
	margin-bottom: ${spacingPx.medium};
	margin-top: ${spacingPx.large};
`;

const StyledFooter = styled(Footer)`
	margin-bottom: ${spacingPx.small};
	margin-top: auto;
	color: ${color.textInverse};
`;

const messages = defineMessages({
	copyright: {
		id: 'login.loginFooter.copyright',
		description:
			'Common copyright footer text. \\u00A9 is the unicode character for copyright',
		defaultMessage:
			'Copyright \u00A9 2006-{currentYear} by Planview, Inc. All rights reserved.',
	},
});

const LoginHeader = () => {
	return (
		<PlanviewHeader>
			<PlanviewNavHeaderLogo height={'40px'} width={'140px'} />
		</PlanviewHeader>
	);
};

const LoginFooter = () => {
	return (
		<StyledFooter>
			<FormattedMessage
				{...messages.copyright}
				values={{
					currentYear: new Date().getFullYear(),
				}}
			/>
		</StyledFooter>
	);
};

type FormProps = {
	type: string;
	username: string;
	setUsername: Dispatch<SetStateAction<string>>;
};

const Form = (props: FormProps) => {
	const { type, username, setUsername } = props;
	switch (type) {
		case 'change-password':
			return <ChangePasswordForm />;
		case 'forgot-password':
			return <ForgotPasswordForm username={username} />;
		case 'reset-password':
			return <ResetPasswordForm />;
		case 'create-account':
			return <CreateAccountForm />;
		case 'invite-account':
			return <CreateAccountForm emailInvite={true} />;
		case 'login':
		default:
			return <LoginForm username={username} setUsername={setUsername} />;
	}
};

const parseUsername = (search: string) => {
	const { username = '' } = qs.parse(search, {
		ignoreQueryPrefix: true,
	});

	const usernameString = username as string;
	return isEmail(usernameString) ? usernameString : '';
};

type LoginPageProps = {
	type: string;
};

const LoginPage = (props: LoginPageProps) => {
	const { type } = props;
	const location = useLocation();
	const { search } = location;
	const [username, setUsername] = useState(parseUsername(search));

	return (
		<BackgroundContainer className="pvds-static">
			<LoginHeader />
			<LoginContainer>
				<Suspense fallback={<Spinner size={SIZE_LARGE} />}>
					<Form
						type={type}
						username={username}
						setUsername={setUsername}
					/>
				</Suspense>
			</LoginContainer>
			<LoginFooter />
		</BackgroundContainer>
	);
};

export default LoginPage;
