import { defineMessages } from 'react-intl';

const messages = defineMessages({
	editHeader: {
		id: 'home.productTile.modal.edit.header',
		description: 'Tile edit modal header text',
		defaultMessage: 'Edit Product Description',
	},
	inputLabel: {
		id: 'home.productTile.modal.edit.input.label',
		description: 'Tile edit modal body text',
		defaultMessage: 'Product description',
	},
	editDescriptionSuccess: {
		id: 'home.productTile.modal.edit.success',
		description: 'Success message on edit product description',
		defaultMessage: 'The product description has been updated.',
	},
});

export default messages;
