import React from 'react';
import { useIntl } from 'react-intl';
import { Settings } from '@planview/pv-icons';
import EnvironmentTag from '../../../../components/common/environmentTag';
import messages from '../SettingsTile.messages';
import { ButtonGhost } from '@planview/pv-uikit';
import { useNavigate } from 'react-router-dom';
import SettingsTile from '../SettingsTile';
import AppLogo from '../../../../components/common/appLogo/AppLogo';

type PlanviewSettingsTileProps = {
	sandbox: boolean;
	tenantGroupId: string;
	showPrimaryLabel: boolean;
	title: string;
	imageScale: number;
};

const PlanviewSettingsTile = (props: PlanviewSettingsTileProps) => {
	const {
		sandbox,
		tenantGroupId,
		showPrimaryLabel = false,
		title,
		imageScale,
	} = props;

	const intl = useIntl();
	const navigate = useNavigate();

	const settingsTileIcon = <AppLogo app={'PLANVIEW_ME'} scale={imageScale} />;
	const settingsTileEnvLabel = (
		<EnvironmentTag
			sandbox={sandbox}
			secondarySandboxIsPrimary={showPrimaryLabel}
			secondarySandboxLabel={title}
		/>
	);

	const settingsTileActionButtons = (
		<ButtonGhost
			icon={<Settings />}
			onClick={() => {
				navigate(
					`/admin/settings/services/launchpad/config/${tenantGroupId}`,
					{ state: { redirect: true } },
				);
			}}
		>
			{intl.formatMessage(messages.settingsButton)}
		</ButtonGhost>
	);

	return (
		<>
			<SettingsTile
				icon={settingsTileIcon}
				environmentLabel={settingsTileEnvLabel}
				actionButtons={settingsTileActionButtons}
			/>
		</>
	);
};

export default PlanviewSettingsTile;
