import { defineMessages } from 'react-intl';

const messages = defineMessages({
	copyLink: {
		id: 'home.overview.copyLink',
		description: 'Menu text for the option to copy a link to a product',
		defaultMessage: 'Copy link',
	},
	copyToClipboardSuccess: {
		id: 'home.overview.copyToClipboardSuccess',
		description: 'Success message after copying to clipboard',
		defaultMessage: 'Product link copied to clipboard',
	},
	copyToClipboardError: {
		id: 'home.overview.copyToClipboardError',
		description: 'Error message trying to copy to clipboard',
		defaultMessage: 'Could not copy product link to clipboard',
	},
	pageTitle: {
		id: 'home.overview.pageTitle',
		description: 'Header text of the home overview tab',
		defaultMessage: 'Overview',
	},
	products: {
		id: 'home.overview.section.products',
		description: 'Header text of the home overview products section',
		defaultMessage: 'Products',
	},
	services: {
		id: 'home.overview.section.services',
		description: 'Header text of the home overview services section',
		defaultMessage: 'Services',
	},
	primarySandboxes: {
		id: 'home.overview.section.primarySandboxes',
		description:
			'Header text of the home overview primary sandboxes section',
		defaultMessage: 'Primary Sandboxes',
	},
	sandboxes: {
		id: 'home.overview.section.sandboxes',
		description: 'Header text of the home overview primary sandboxes',
		defaultMessage: 'Sandboxes',
	},
	manageProducts: {
		id: 'home.overview.section.manageProducts',
		description: 'Link to manage products page',
		defaultMessage: 'Manage Products',
	},
	noProducts: {
		id: 'home.overview.section.noProducts',
		description:
			'Display text when no products are available, for an end-user',
		defaultMessage:
			'There are no products added to Planview Admin or you have not been added to any products. Please contact your administrator to complete your Planview Admin configuration.',
	},
	noProductsAdmin: {
		id: 'home.overview.section.admin.noProducts',
		description:
			'Display text when no products are available, for an admin user that has permission to connect to products',
		defaultMessage:
			'There are no products added to Planview Admin, the products are hidden, or you have not been added to any products.',
	},
	primarySandboxHelpTooltip: {
		id: 'home.overview.section.primarySandboxHelpTooltip',
		description: 'Help text for primary sandbox section tooltip',
		defaultMessage:
			'These are the sandboxes that can share data with other products.',
	},
	sandboxHelpTooltip: {
		id: 'home.overview.section.sandboxHelpTooltip',
		description: 'Help text for other sandbox section tooltip',
		defaultMessage:
			'These sandboxes cannot share data with other products and are useful for testing in isolation.',
	},
});

export default messages;
