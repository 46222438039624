import { Modal } from '../../../components/common/modal/Modal';
import messages from '../organization/NewOrganizationDialog.messages';
import { preventEventBubbling } from '../../common/PreventEventBubbling';
import { Combobox, Form, Input } from '@planview/pv-form';
import { SectionHeader } from '../../../components/common/Dialog';
import { useIntl } from 'react-intl';
import React, { useContext } from 'react';
import { AppContext } from '../../../context';
import { ComboboxOption, MODAL_MEDIUM } from '@planview/pv-uikit';
import { useForm } from '@mantine/form';
import { requestWithErrorHandling } from '../../../hooks/request/request';
import { ApplicationName } from '../../../types';
import { CreateCustomProductDto } from '../../../types/api/tenants';

type AddCustomProductDialogProps = {
	customerId: string;
	setShowAddCustomProduct: (showAddCustomProduct: boolean) => void;
};

const AddCustomProductDialog = (props: AddCustomProductDialogProps) => {
	const { customerId, setShowAddCustomProduct } = props;
	const appContext = useContext(AppContext);
	const intl = useIntl();
	const form = useForm({
		initialValues: {
			name: '',
			url: '',
			associatedProduct: null as ComboboxOption | null,
			tenantGroup: null as ComboboxOption | null,
		},
		validateInputOnBlur: true,
		validate: {
			name: (value) => (!value ? 'Please enter a name' : null),
			url: (value) => (!value ? 'Please enter a URL' : null),
			associatedProduct: (value) =>
				!value ? 'Please select a product' : null,
			tenantGroup: (value) =>
				!value ? 'Please select a tenant group' : null,
		},
	});

	return (
		<Modal
			id="add-custom-product-dialog"
			size={MODAL_MEDIUM}
			onConfirm={async () => {
				const { name, url, associatedProduct, tenantGroup } =
					form.values;
				if (associatedProduct && tenantGroup) {
					const dataObj: CreateCustomProductDto = {
						customerId,
						name,
						url,
						application: associatedProduct.value as ApplicationName,
						sandbox: tenantGroup.value === 'Sandbox',
					};

					const { success } = await requestWithErrorHandling({
						method: 'post',
						url: `/io/v1/admin/tenant/custom`,
						dataObj,
						appContext,
						intl,
						successMessage:
							'Custom product has been added successfully. Please refresh the page to see the changes.',
					});

					if (success) {
						setShowAddCustomProduct(false);
					}
				}
			}}
			onCancel={() => {
				setShowAddCustomProduct(false);
			}}
			confirmText={intl.formatMessage(messages.addButton)}
			cancelText={intl.formatMessage(messages.cancelButton)}
			headerText="Add a custom product"
			disableConfirm={!form.isValid()}
			{...preventEventBubbling}
		>
			<Form label="custom-product-form">
				<SectionHeader>
					<Combobox
						label="Associated product"
						id="custom-product-associated"
						withAsterisk={true}
						options={appContext.availableApplications.map(
							(application) => {
								const { app: value, productName: label } =
									application;
								return {
									label,
									value,
								};
							},
						)}
						{...form.getInputProps('associatedProduct')}
					/>
				</SectionHeader>
				<SectionHeader>
					<Input
						label="Name"
						id="custom-product-name"
						withAsterisk={true}
						{...form.getInputProps('name')}
					/>
				</SectionHeader>
				<SectionHeader>
					<Input
						label="URL"
						id="custom-product-url"
						withAsterisk={true}
						{...form.getInputProps('url')}
					/>
				</SectionHeader>
				<SectionHeader>
					<Combobox
						label="Tenant group"
						id="custom-product-tenant-group"
						withAsterisk={true}
						options={[
							{
								label: 'Production',
								value: 'Production',
							},
							{
								label: 'Sandbox',
								value: 'Sandbox',
							},
						]}
						{...form.getInputProps('tenantGroup')}
					/>
				</SectionHeader>
			</Form>
		</Modal>
	);
};

export default AddCustomProductDialog;
