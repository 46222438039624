export enum ToastType {
	DANGER = 'danger',
	INFO = 'info',
	SUCCESS = 'success',
}

export interface ToastAction {
	label: string;
	onClick: () => void;
}

export interface Toast {
	message: string;
	type: ToastType;
	id?: number;
	dismissAfter?: number;
	actions?: ToastAction[];
}
