import { defineMessages } from 'react-intl';

const messages = defineMessages({
	generateTokenButton: {
		id: 'admin.settings.generateTokenButton',
		description: 'Button text for the OKR generate token button',
		defaultMessage: 'Generate token',
	},
	copyToClipboardMessage: {
		id: 'admin.settings.copyToClipboardMessage',
		description: 'Toast message for successful copy to clipboard',
		defaultMessage: 'Token copied to clipboard',
	},
	copyToClipboardFailure: {
		id: 'admin.settings.copyToClipboardFailure',
		description: 'Toast message for failure to copy to clipboard',
		defaultMessage:
			'Sorry, something went wrong. Failed to copy token to clipboard.',
	},
	okrLevels: {
		id: 'admin.settings.okrLevels',
		description: 'Dropdown list item text for the OKRs Levels option',
		defaultMessage: 'Levels',
	},
	okrCustomAttributes: {
		id: 'admin.settings.okrCustomAttributes',
		description:
			'Dropdown list item text for the OKRs Custom Attributes option',
		defaultMessage: 'Custom attributes',
	},
});

export default messages;
