import { defineMessages } from 'react-intl';

const messages = defineMessages({
	setPrimaryButton: {
		id: 'home.productTile.modal.setPrimary.yes',
		description: 'Button text for setPrimary',
		defaultMessage: 'Set as primary',
	},
	noButton: {
		id: 'home.productTile.modal.setPrimary.no',
		description: 'Button text for No',
		defaultMessage: 'No',
	},
	setPrimarySandboxHeader: {
		id: 'home.productTile.modal.setPrimarySandboxHeader.header',
		description:
			'Header text for setting a product instance as primary sandbox confirmation dialog',
		defaultMessage: 'Set as primary sandbox',
	},
	setPrimaryContentSandbox: {
		id: 'home.productTile.modal.setPrimaryContentSandbox',
		description: 'Content for set primary sandbox confirmation dialog',
		defaultMessage:
			'This will set {title} as the primary sandbox instance for this product.',
	},
	setPrimaryContentSandboxWarning: {
		id: 'home.productTile.modal.setPrimaryContentSandboxWarning',
		description: 'Content for set primary sandbox confirmation dialog',
		defaultMessage:
			'WARNING: Changing your primary sandbox instance will break links that go to the previous sandbox in applications such as Roadmaps, Planview.Me, and OKRs.',
	},
	setPrimarySandboxSuccess: {
		id: 'home.productTile.modal.setPrimary.success',
		description: 'Text of success setting primary sandbox product instance',
		defaultMessage: '{title} is your primary sandbox instance',
	},
});

export default messages;
