import { defineMessages } from 'react-intl';

const messages = defineMessages({
	syncHeader: {
		id: 'admin.productDetails.tab.details.syncHeader',
		description:
			'Header for Last Sync Details in Product Details page -> Details tab',
		defaultMessage: 'Last sync details',
	},
	noLastSyncText: {
		id: 'admin.productDetails.tab.details.noLastSync',
		description:
			'Text to display in Last Sync Details section when there was no prior sync action',
		defaultMessage: 'No prior sync details available.',
	},
	timestampLabel: {
		id: 'admin.productDetails.details.sync.timestampLabel',
		description: 'Label on product details page for timestamp of last sync',
		defaultMessage: 'Timestamp:',
	},
	countLabel: {
		id: 'admin.productDetails.details.sync.countLabel',
		description:
			'Label on product details page for count of users in last sync',
		defaultMessage: 'Count:',
	},
	messagesLabel: {
		id: 'admin.productDetails.details.sync.messagesLabel',
		description:
			'Label on product details page for messages during last sync',
		defaultMessage: 'Messages:',
	},
	errorsLabel: {
		id: 'admin.productDetails.details.sync.errorsLabel',
		description:
			'Label on product details page for errors during last sync',
		defaultMessage: 'Errors:',
	},
});

export default messages;
