import styled from 'styled-components';
import { theme, sizePx, spacingPx } from '@planview/pv-utilities';

// For media queries to target phone
export const PhoneMaxWidth = 549;
export const NonPhoneMinWidth = 550;

type Props = {
	flex?: number;
};

// Simple Horizontal Flexbox styled div
export const HBox = styled.div<Props>`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	${(props) =>
		`flex-grow: ${typeof props.flex !== 'undefined' ? props.flex : 1};`};
	@media only screen and (max-width: ${PhoneMaxWidth}px) {
		justify-content: space-around;
	}
`;

// Simple Vertical Flexbox styled div
export const VBox = styled.div<Props>`
	display: flex;
	flex-direction: column;
	${(props) =>
		`flex-grow: ${typeof props.flex !== 'undefined' ? props.flex : 1};`}
`;

// Footer that can be used on a form or a page
// (Leaving this here for now, if we have more of such components, we should make this a new component)
export const Footer = styled.footer`
	bottom: ${spacingPx.medium};
	color: ${theme.textSecondary};
	font-size: 14px;
	line-height: 1.71;
	float: bottom;
`;

export const Header = styled.div`
	color: ${theme.textPrimary};
	line-height: 1.6;
	margin: ${spacingPx.medium} 0;
	text-align: center;
`;

export const ButtonContainer = styled.div`
	margin-top: ${spacingPx.medium};
`;

export const NoteContainer = styled.div`
	padding: ${spacingPx.medium} 0 0 0;
	gap: ${spacingPx.small};
	font-weight: normal;
	font-size: 13px;
	line-height: ${sizePx.xsmall};
	width: 460px;
`;
