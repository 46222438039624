import { defineMessages } from 'react-intl';

const messages = defineMessages({
	noRowsText: {
		id: 'admin.users.grid.noRows.text',
		description: 'Text to show on the grid if there are no rows',
		defaultMessage: 'No Rows',
	},
	moreRecordsText: {
		id: 'admin.users.grid.moreRecords.text',
		description:
			'Text to show on the grid if there are more than the max limit of records',
		defaultMessage:
			'Showing {maxRows} records. Use filters to refine results.',
	},
	loadDataError: {
		id: 'admin.users.grid.load.error',
		description:
			'Error status message shown when there was an issue loading data into a grid',
		defaultMessage:
			'An error occurred while loading data, please try again or contact your Planview administrator.',
	},
});

export default messages;
