import { DISMISS_AFTER_FIVE_SECONDS } from '../components/common/toast/Toast';
import {
	AppContext,
	AppContextProps,
	UserContext,
	UserContextProps,
} from '../context';
import { del, LOGIN_API_URL } from './request/request';
import { IntlShape, MessageDescriptor, useIntl } from 'react-intl';
import { getRequestErrorForFetch } from './request/RequestError';
import messages from '../messages/server';
import { ApiResponse } from '../types/api/api';
import { ToastType } from '../types/toast';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavigateFunction } from 'react-router';

const removeLocalStorageItems = (keys: string[]) => {
	keys.forEach((key) => {
		window.localStorage.removeItem(key);
	});
};

/**
 * make the request to logout
 * @return ApiResponse
 */
const doLogoutRequest = async () => {
	try {
		return (await del(LOGIN_API_URL)) as ApiResponse;
	} catch (error) {
		return getRequestErrorForFetch(error) as ApiResponse;
	}
};

/**
 * Log out of the app and clear out the appropriate context attributes
 *
 * @param {React.Context} userContext - User Context
 * @param {React.Context} appContext - App Context
 */
const logout = async (
	userContext: UserContextProps,
	appContext: AppContextProps,
	navigate: NavigateFunction,
	intl: IntlShape,
	logoutMessage?: MessageDescriptor,
) => {
	const { success, _appVersion, message } = await doLogoutRequest();
	const { checkAppVersion, setLoginPollingEnabled } = appContext;
	const { clearUserContext } = userContext;

	if (success) {
		clearUserContext();
		setLoginPollingEnabled(false);

		// this removes the auth token that is set in place by Dovetail components
		// that are embedded within PV Admin
		removeLocalStorageItems(['platforma_platformaToken']);

		// Plug in the app version check here, in case the page isn't reloaded over a long period of time.
		if (_appVersion) {
			checkAppVersion(_appVersion);
		}

		appContext.showToast({
			message: intl.formatMessage(logoutMessage ?? messages.userLogout),
			type: ToastType.SUCCESS,
			dismissAfter: DISMISS_AFTER_FIVE_SECONDS,
		});

		navigate('/', { state: { redirect: true } });
	} else {
		const messageObj = { id: message, defaultMessage: message };
		appContext.showToast({
			message: intl.formatMessage(messageObj),
			type: ToastType.DANGER,
		});
	}
};

export const useLogout = () => {
	const userContext = useContext(UserContext);
	const appContext = useContext(AppContext);
	const navigate = useNavigate();
	const intl = useIntl();

	return {
		logout: (logoutMessage?: MessageDescriptor) =>
			logout(userContext, appContext, navigate, intl, logoutMessage),
	};
};
