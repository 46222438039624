import React, { JSX } from 'react';
import styled from 'styled-components';
import { align, border, spacingPx } from '@planview/pv-utilities';
import { HBox } from '../../../components/common/Layout';

const Tile = styled.div`
	${border.normal()};
	padding: ${spacingPx.small};
	height: 40px;
`;

const StyledHBox = styled(HBox)`
	align-items: center;
`;

const LeftSide = styled.div`
	display: flex;
	flex-direction: row;
	flex-grow: 4;
	${align.centerV}
`;

const RightSide = styled.div`
	display: flex;
	flex-direction: row;
	gap: ${spacingPx.xsmall};
	margin-left: ${spacingPx.xlarge};
`;

const Icon = styled.div`
	width: calc(115px + ${spacingPx.small});
`;

type SettingsTileProps = {
	icon: JSX.Element;
	environmentLabel: JSX.Element;
	actionButtons: JSX.Element;
};

const SettingsTile = (props: SettingsTileProps) => {
	const { icon, environmentLabel, actionButtons } = props;
	return (
		<Tile>
			<StyledHBox>
				<LeftSide>
					<Icon>{icon}</Icon>
					{environmentLabel}
				</LeftSide>
				<RightSide>{actionButtons}</RightSide>
			</StyledHBox>
		</Tile>
	);
};
export default SettingsTile;
