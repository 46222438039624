import React, { useEffect, useState } from 'react';
import AppLogo from '../../../components/common/appLogo/AppLogo';
import styled from 'styled-components';
import { BottomTooltip, Checkbox, SIZE_SMALL } from '@planview/pv-uikit';
import EnvironmentTag from '../../../components/common/environmentTag';
import {
	align,
	border,
	borderRadius,
	theme,
	spacingPx,
	text,
} from '@planview/pv-utilities';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import messages from './ProductCheckBox.messages';
import { Combobox } from '@planview/pv-form';
import { isLicensingSupported } from '../../../helpers/util';

const ProductBox = styled.div`
	${border.normal()};
	${borderRadius.small};
	display: flex;
	flex-direction: column;
	margin-top: ${spacingPx.small};
	align-items: center-left;
	padding: ${spacingPx.xsmall};
	background-color: ${(props) =>
		props.provisionState?.errorMessage ? theme.error50 : ''};
`;

const ProductBoxRowItem = styled.div`
	${align.centerV}
`;

const ProductBoxComponent = styled.div`
	${text.small}
	margin-left: ${spacingPx.xsmall};
	margin-right: ${spacingPx.xsmall};
`;

const ProductBoxDropdownSection = styled.div`
	padding: ${spacingPx.small} ${spacingPx.medium} 0px ${spacingPx.large};
`;

const DescriptionLabel = styled(ProductBoxComponent)`
	color: ${theme.textPlaceholder};
`;

const ProductInfo = styled(ProductBoxComponent)`
	padding: ${spacingPx.xsmall} 0px;
`;

const StyledAppLogo = styled(AppLogo)`
	margin-left: ${spacingPx.xsmall};
	margin-right: ${spacingPx.xsmall};
`;

const areEqual = (userTenantAssignment, envSelector) =>
	userTenantAssignment.application === envSelector.application &&
	userTenantAssignment.tenantId === envSelector.tenantId;

const areMappingsEqual = (userTenantAssignment, otherUserTenantAssignment) =>
	userTenantAssignment.tenantId === otherUserTenantAssignment.tenantId &&
	userTenantAssignment.application === otherUserTenantAssignment.application;

const isSelectedApp = (user, envSelector) => {
	return user.userTenantAssignments.some((it) => areEqual(it, envSelector));
};

const isProvisioned = (provisionState) => {
	return provisionState?.provisionedOn != null;
};

const getTenantErrorMessage = (provisionState) => {
	if (provisionState && provisionState.errorMessage) {
		return (
			<ProductInfo key={provisionState.envSelector.tenantId}>
				{provisionState.errorMessage}
			</ProductInfo>
		);
	}
};

const ProductCheckBox = ({
	application,
	user,
	updateUser,
	provisionState,
	featureFlags,
	noDefaultOptions,
	intl,
}) => {
	const { formatDate } = useIntl();
	const [licenseOption, setLicenseOption] = useState();
	const [roleOption, setRoleOption] = useState();
	const [readOnlyRole, setReadOnlyRole] = useState(false);
	const [productSelected, setProductSelected] = useState(
		isSelectedApp(user, application.envSelector),
	);
	const licenseTypes = application.licenseTypes || [];
	const showLicensing =
		licenseTypes.length > 0 &&
		isLicensingSupported(application.app, featureFlags);

	const licenseOptions = licenseTypes.map((licenseType) => {
		const { displayText, name, unlimited } = licenseType;
		const label = unlimited ? displayText : displayText;
		return {
			label,
			value: name,
		};
	});

	const roles = application.roles || [];
	const showRole = roles.length > 0;
	const filteredRoles = roles.filter((role) => !role.readOnly);
	const roleOptions = filteredRoles.map((role) => {
		const { displayText, id } = role;
		return {
			label: displayText,
			value: id,
		};
	});

	let userTenantAssignments = JSON.parse(
		JSON.stringify(user.userTenantAssignments || []),
	);

	let userTenantAssignment = userTenantAssignments.find(
		(userTenantAssignment) =>
			userTenantAssignment.tenantId ===
				application.envSelector.tenantId &&
			userTenantAssignment.application === application.app,
	);

	useEffect(() => {
		const getLicenseType = async () => {
			if (noDefaultOptions) {
				setLicenseOption({ label: '', value: '' });
				return;
			}
			const defaultLicense = licenseTypes.find((licenseType) => {
				return licenseType.defaultLicense;
			});
			let defaultLicenseOption = defaultLicense
				? {
						label: defaultLicense.displayText,
						value: defaultLicense.name,
					}
				: {};

			if (userTenantAssignment && showLicensing) {
				const licenseName = userTenantAssignment.licenseName;

				const licenseType = licenseTypes.find(
					(licenseType) => licenseType.name === licenseName,
				);

				if (licenseType) {
					const { displayText, name } = licenseType;

					defaultLicenseOption = {
						label: displayText,
						value: name,
					};
				}
			}

			setLicenseOption(defaultLicenseOption);
		};

		void getLicenseType();

		const getRole = async () => {
			if (noDefaultOptions) {
				setRoleOption({ label: '', value: '' });
				return;
			}

			const { defaultRole } = application;
			let defaultRoleOption = defaultRole
				? {
						label: defaultRole.displayText,
						value: defaultRole.id,
					}
				: { label: '', value: '' };

			if (userTenantAssignment && showRole) {
				const roleId = userTenantAssignment.role;

				const role = roles.find((role) => role.id === roleId);

				if (role) {
					const { displayText, id, readOnly } = role;
					defaultRoleOption = {
						label: displayText,
						value: id,
					};
					setReadOnlyRole(readOnly);
				} else {
					defaultRoleOption = { label: '', value: '' };
				}
			}

			setRoleOption(defaultRoleOption);
		};

		void getRole();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<ProductBox
			data-testid={`application_${application.envSelectorEncodedString}`}
			$provisionState={provisionState}
		>
			<ProductBoxRowItem>
				<Checkbox
					size={SIZE_SMALL}
					selected={productSelected}
					onChange={(checked) => {
						setProductSelected(!productSelected);
						if (checked) {
							userTenantAssignment = {
								application: application.app,
								tenantId: application.envSelector.tenantId,
								licenseName: licenseOption.value
									? licenseOption.value
									: null,
								role: roleOption.value
									? roleOption.value
									: null,
								licenseRequired: showLicensing,
								roleRequired: showRole,
							};
							userTenantAssignments = [
								...userTenantAssignments,
								userTenantAssignment,
							];
						} else {
							userTenantAssignments =
								userTenantAssignments.filter(
									(it) =>
										!areMappingsEqual(
											it,
											userTenantAssignment,
										),
								);
						}
						updateUser({ ...user, userTenantAssignments });
					}}
					disabled={readOnlyRole}
				/>
				<StyledAppLogo app={application.app} scale={0.14} />
				<ProductBoxComponent>
					<EnvironmentTag sandbox={application.sandbox} />
				</ProductBoxComponent>
			</ProductBoxRowItem>

			<ProductBoxRowItem>
				<DescriptionLabel>{application.title}</DescriptionLabel>
			</ProductBoxRowItem>
			{showLicensing && productSelected ? (
				<ProductBoxDropdownSection>
					<Combobox
						id={`cb-license_${application.envSelectorEncodedString}`}
						label={intl.formatMessage(messages.licenseType)}
						withAsterisk={!(licenseOption ?? {}).value}
						value={licenseOption}
						onChange={(nextState) => {
							setLicenseOption(nextState);
							userTenantAssignment.licenseName = nextState.value;

							updateUser({ ...user, userTenantAssignments });
						}}
						options={licenseOptions}
						clearable={false}
					/>
				</ProductBoxDropdownSection>
			) : null}
			{showRole && productSelected ? (
				<ProductBoxDropdownSection>
					<BottomTooltip
						text={
							readOnlyRole
								? intl.formatMessage(messages.readOnlyMessage, {
										roleName: roleOption?.label,
									})
								: null
						}
					>
						<Combobox
							id={`cb-role_${application.envSelectorEncodedString}`}
							label={intl.formatMessage(messages.role)}
							withAsterisk={!(roleOption ?? {}).value}
							value={roleOption}
							onChange={(nextState) => {
								setRoleOption(nextState);
								userTenantAssignment.role = nextState.value;

								updateUser({ ...user, userTenantAssignments });
							}}
							options={roleOptions}
							readOnly={readOnlyRole}
							clearable={false}
						/>
					</BottomTooltip>
				</ProductBoxDropdownSection>
			) : null}
			<ProductBoxRowItem>
				<ProductInfo data-testid={`provisioned-on`}>
					{isProvisioned(provisionState) ? (
						<FormattedMessage
							{...messages.provisionedOnMessage}
							values={{
								date: formatDate(provisionState.provisionedOn, {
									year: 'numeric',
									month: 'numeric',
									day: 'numeric',
									hour: 'numeric',
									minute: 'numeric',
									second: 'numeric',
								}),
							}}
						/>
					) : null}
				</ProductInfo>
			</ProductBoxRowItem>

			<ProductBoxRowItem>
				{getTenantErrorMessage(provisionState)}
			</ProductBoxRowItem>
		</ProductBox>
	);
};

export default injectIntl(ProductCheckBox);
