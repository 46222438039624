import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'abortcontroller-polyfill';
import React from 'react';
import './index.css';
import App from './App';
import { getDefaultLocale, loadLocaleData } from './messages/intl';
import { logError } from './hooks/request/request';
import { createRoot } from 'react-dom/client';

async function bootstrapApplicationV18() {
	const locale = getDefaultLocale();
	const messages = await loadLocaleData(locale);
	const root = createRoot(document.getElementById('root')!);
	root.render(<App locale={locale} messages={messages} />);
}

window.onerror = (event, source, lineno, colno, error) => {
	void logError('window.onerror', error || event);
};

window.addEventListener('unhandledrejection', ({ reason }) => {
	void logError(`Unhandled promise rejection`, reason);
});

await bootstrapApplicationV18();
