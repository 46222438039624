import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { MODAL_SMALL } from '@planview/pv-uikit';
import { AppContext } from '../../../context';
import { Modal } from '../modal/Modal';
import messages from './RefreshAppModal.messages';

const Body = styled.div`
	line-height: 1.5em;
	margin-bottom: 30px;
`;

const refreshWindow = () => {
	window.location.reload();
};

const RefreshAppModal = () => {
	const { appDetails, needsLogin } = useContext(AppContext);
	const intl = useIntl();

	let dialogConfig = null;
	if (appDetails.forceRefresh) {
		dialogConfig = {
			confirmText: intl.formatMessage(messages.refresh),
			headerText: intl.formatMessage(messages.header),
			bodyMessage: messages.content,
		};
	} else if (needsLogin) {
		dialogConfig = {
			confirmText: intl.formatMessage(messages.loginButton),
			headerText: intl.formatMessage(messages.loginHeader),
			bodyMessage: messages.loginContent,
		};
	}

	return dialogConfig ? (
		<Modal
			id="refresh-app-modal"
			confirmText={dialogConfig.confirmText}
			headerText={dialogConfig.headerText}
			cancelText={''}
			onCancel={refreshWindow}
			onConfirm={refreshWindow}
			size={MODAL_SMALL}
		>
			<Body>
				<FormattedMessage {...dialogConfig.bodyMessage} />
			</Body>
		</Modal>
	) : null;
};

export default RefreshAppModal;
