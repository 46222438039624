import { defineMessages } from 'react-intl';

const messages = defineMessages({
	backButton: {
		id: 'admin.settings.services.backButton',
		description:
			'Back button on the service setting container page that brings the user back to the Settings page',
		defaultMessage: 'Back to settings',
	},
});

export default messages;
