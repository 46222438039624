import { User } from '../../../types';
import { useIntl } from 'react-intl';
import React, { useContext, useState } from 'react';
import { AppContext, UserContext } from '../../../context';
import {
	BulkProvisionDto,
	BulkUserDto,
	UserTenantAssignment,
	UserUpsertDto,
} from '../../../types/api/users';
import { ToastType } from '../../../types/toast';
import { isMissingRoles } from '../../../helpers/util';
import { Modal, MODAL_MEDIUM } from '@planview/pv-uikit';
import messages from '../users/UsersPage.messages';
import modalMessages from '../../../components/common/modal/Modal.messages';
import { AssignProductsSection } from './AssignProductsSection';
import { ApiResponse } from '../../../types/api/api';
import { post } from '../../../hooks/request/request';
import { NoteContainer } from '../../../components/common/Layout';

const postBulkProvision = async (
	dto: BulkProvisionDto,
): Promise<ApiResponse> => {
	const url = '/io/v1/user/provision';
	return (await post(url, dto)) as ApiResponse;
};

const postActivation = async (dto: {
	userIds: string[];
}): Promise<ApiResponse> => {
	const url = `/io/v1/user/activate`;
	return (await post(url, dto)) as ApiResponse;
};

type AddProductsDialogProps = {
	onConfirm: () => void;
	onCancel: () => void;
	isCustomerCare: boolean;
	selectedUsers: User[];
	activateMode?: boolean;
};

const AddProductsDialog = ({
	onConfirm,
	onCancel,
	isCustomerCare,
	selectedUsers,
	activateMode = false,
}: AddProductsDialogProps) => {
	// eslint-disable-next-line @typescript-eslint/unbound-method
	const { formatMessage } = useIntl();
	const { showToast } = useContext(AppContext);
	const userContext = useContext(UserContext);
	const { topDownUserManagementEnabled } = userContext.customer;

	const [user, setUser] = useState<UserUpsertDto>({
		id: '',
		firstName: '',
		lastName: '',
		email: '',
		userTenantAssignments: [] as UserTenantAssignment[],
		isAdmin: false,
	});

	const [isProvisioning, setProvisioning] = useState(false);

	/**
	 * Used in addProduct flow and reactivation flow when
	 * admin selects products for the reactivated users
	 */
	const sendProvisionRequest = async (
		bulkUserDtos: BulkUserDto[],
		userTenantAssignmentDtos: UserTenantAssignment[],
		activateMode: boolean,
	): Promise<boolean> => {
		setProvisioning(true);
		const dto: BulkProvisionDto = {
			bulkUserDtos,
			userTenantAssignmentDtos,
			activateMode,
		};
		const { success, message } = await postBulkProvision(dto);
		const toastMessage =
			activateMode && success
				? formatMessage(messages.activationJobStarted) + ' ' + message
				: message;
		showToast({
			message: toastMessage,
			type: success ? ToastType.INFO : ToastType.DANGER,
		});
		setProvisioning(false);
		return success;
	};

	/**
	 * Used in reactivate flow when no products are selected
	 */
	const sendActivationRequest = async (
		userIds: string[],
	): Promise<boolean> => {
		setProvisioning(true);

		const dto = { userIds };
		const { success, message } = await postActivation(dto);
		let toastMessage = formatMessage(messages.activateUserSuccessPlural, {
			count: userIds.length,
		});
		if (!success) {
			toastMessage = message;
		}
		showToast({
			message: toastMessage,
			type: success ? ToastType.INFO : ToastType.DANGER,
		});
		setProvisioning(false);
		return success;
	};

	const shouldDisableConfirm = () => {
		const { userTenantAssignments } = user;
		const missingLicenses = userTenantAssignments
			.filter((assn) => assn.licenseRequired)
			.some((assn) => !assn.licenseName);

		const missingRoles = isMissingRoles(userTenantAssignments);

		return (
			(!activateMode && userTenantAssignments?.length < 1) ||
			isProvisioning ||
			missingLicenses ||
			missingRoles
		);
	};

	return (
		<Modal
			id="add-products-modal"
			size={MODAL_MEDIUM}
			headerText={
				activateMode
					? formatMessage(messages.activateUserItemPlural)
					: formatMessage(messages.addProductEnvironmentsHeader)
			}
			disableConfirm={shouldDisableConfirm()}
			/* eslint-disable-next-line @typescript-eslint/no-misused-promises */
			onConfirm={async () => {
				const { userTenantAssignments } = user;
				let success = false;

				if (activateMode && userTenantAssignments.length == 0) {
					const userIds = selectedUsers.map(({ id }) => id);
					success = await sendActivationRequest(userIds);
				} else {
					const bulkUserDtos = selectedUsers as BulkUserDto[];
					success = await sendProvisionRequest(
						bulkUserDtos,
						userTenantAssignments,
						activateMode,
					);
				}
				if (success) {
					onConfirm();
				}
			}}
			onCancel={onCancel}
			confirmText={
				activateMode
					? formatMessage(messages.activateUserItemPlural)
					: formatMessage(messages.add)
			}
			cancelText={formatMessage(modalMessages.cancel)}
		>
			<AssignProductsSection
				user={user}
				setUser={setUser}
				isCustomerCare={isCustomerCare}
				applicationSelectionMessage={
					messages.addProductEnvironmentsItem
				}
				noDefaultOptions={selectedUsers.length > 1}
			/>
			{activateMode && !topDownUserManagementEnabled && (
				<NoteContainer>
					{formatMessage(messages.activateUserResyncNotePlural)}
				</NoteContainer>
			)}
		</Modal>
	);
};

export default AddProductsDialog;
