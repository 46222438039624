import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import styled from 'styled-components';
import qs from 'qs';
import { spacingPx, text } from '@planview/pv-utilities';
import { AppContext, AppContextProps, UserContext } from '../../../../context';
import { Modal } from '../../../common/modal/Modal';
import { requestWithErrorHandling } from '../../../../hooks/request/request';
import { getValidationFunction } from '../../../../helpers/validation';
import messages from './AddProductModal.messages';
import { Checkbox, MODAL_LARGE } from '@planview/pv-uikit';
import { isIdeaPlaceLicensingSupported } from '../../../../helpers/util';
import { AvailableApplication } from '../../../../context/appContext';
import { ApplicationName, Customer } from '../../../../types';
import { Input } from '@planview/pv-form';
import { AddProductRequestDto } from '../../../../types/api/tenants';

type ModalContentAttrsProps = {
	'data-testid'?: string;
};

const ModalContent = styled.div.attrs<ModalContentAttrsProps>({
	'data-testid': 'modal-content',
})`
	height: 14rem;
`;

const Paragraph = styled.div`
	padding: 1em 0;
`;

const FieldLabel = styled.div``;

const EmailInfo = styled.div`
	margin: ${spacingPx.medium} 0;
`;

const WarningLabel = styled.div`
	${text.semibold}
`;

const getPrefaceContent = (
	intl: IntlShape,
	customer: Customer | Record<string, never>,
) => {
	const { emailEnabled, ssoEnabled } = customer;

	let emailDescription = null;
	if (emailEnabled) {
		emailDescription = ssoEnabled
			? intl.formatMessage(messages.emailOnSsoOnDescription)
			: intl.formatMessage(messages.emailOnSsoOffDescription);
	}

	const authDescription = intl.formatMessage(messages.authDescription, {
		strong: (parts: ReactNode[]) => <strong>{parts}</strong>,
	});

	return (
		<ModalContent>
			<Paragraph>
				{intl.formatMessage(messages.generalDescription)}
			</Paragraph>
			{emailDescription && <Paragraph>{emailDescription}</Paragraph>}
			<Paragraph>{authDescription}</Paragraph>
		</ModalContent>
	);
};

const ProductSpecificContent = ({ app }: { app: string }) => {
	const intl = useIntl();
	// Generated key for an app - e.g., inputLabel_ppmpro
	const inputLabelKey = `inputLabel_${app.toLowerCase()}`;
	const userContext = useContext(UserContext);

	const emailMsg = intl.formatMessage(messages.addProductInfoEmail, {
		email: userContext.user.email,
		b: (parts: ReactNode[]) => <strong>{parts}</strong>,
	});

	switch (app) {
		case 'PROJECTPLACE':
			return (
				<FieldLabel>
					{intl.formatMessage(messages.inputLabel_projectplace)}
					<ul>
						<li>
							{intl.formatMessage(messages.projectplaceProdURL)}
						</li>
						<li>
							{intl.formatMessage(
								messages.projectplaceSandboxUSURL,
							)}
						</li>
						<li>
							{intl.formatMessage(
								messages.projectplaceSandboxSEURL,
							)}
						</li>
					</ul>
					<EmailInfo>{emailMsg}</EmailInfo>
				</FieldLabel>
			);
		case 'ADAPTIVEWORK':
			return (
				<FieldLabel>
					{intl.formatMessage(messages.inputLabel_adaptivework)}
					<ul>
						<li>
							{intl.formatMessage(messages.adaptiveworkProdUSURL)}
						</li>
						<li>
							{intl.formatMessage(messages.adaptiveworkProdEUURL)}
						</li>
						<li>
							{intl.formatMessage(
								messages.adaptiveworkSandboxUSURL,
							)}
						</li>
						<li>
							{intl.formatMessage(
								messages.adaptiveworkSandboxEUURL,
							)}
						</li>
					</ul>
					<EmailInfo>{emailMsg}</EmailInfo>
				</FieldLabel>
			);
		default:
			return (
				<FieldLabel>
					{intl.formatMessage(messages[inputLabelKey])}
					<EmailInfo>{emailMsg}</EmailInfo>
				</FieldLabel>
			);
	}
};

const isUrlValid = (intl: IntlShape, value: string) => {
	const validate = getValidationFunction(
		{
			type: 'url',
			required: true,
		},
		intl,
	);
	return !validate(value);
};

const handleAddClick = async ({
	intl,
	app,
	connectUri,
	appContext,
}: {
	intl: IntlShape;
	app: ApplicationName;
	connectUri: string;
	appContext: AppContextProps;
}) => {
	const queryObj = {
		app,
		scope: 'TENANT',
		connectUri,
		redirectUri: 'admin/products',
	};
	const queryString = qs.stringify(queryObj);
	const { success, targetPath } =
		await requestWithErrorHandling<AddProductRequestDto>({
			method: 'get',
			url: `/api/add-product?${queryString}`,
			appContext,
			intl,
			infoMessage: messages.addSuccess,
		});

	if (success) {
		window.open(targetPath);
	}
};

const handleAddFoundationAppClick = async ({
	intl,
	app,
	appContext,
	sandbox,
}: {
	intl: IntlShape;
	app: ApplicationName;
	appContext: AppContextProps;
	sandbox: boolean;
}) => {
	const { success } = await requestWithErrorHandling({
		method: 'post',
		url: `/io/v1/tenant`,
		dataObj: {
			app,
			sandbox,
		},
		appContext,
		intl,
		infoMessage: messages.addProductSuccess,
	});

	if (success) {
		// trigger a refresh of app data to pick up the new app
		appContext.refreshAppData();
	}
};

type AddProductModalProps = {
	id: string;
	availableApplication: AvailableApplication;
	onToggle: (value: boolean) => void;
};

interface PageModel {
	onConfirm: (() => void) | (() => Promise<void>);
	onCancel: () => void;
	disableConfirm: boolean;
	confirmText: string;
	children: React.ReactNode;
}

const AddProductModal = ({
	id,
	availableApplication,
	onToggle,
}: AddProductModalProps) => {
	const intl = useIntl();
	const [showPreface, setShowPreface] = useState(true);
	const [connectUri, setConnectUri] = useState('');
	const [sandbox, setSandbox] = useState(false);
	const appContext = useContext(AppContext);
	const customer = useContext(UserContext).customer;
	// Generated key for an app - e.g., inputPlaceholder_projectplace
	const { app, requiresConnectUri, addSandboxSupported, productName } =
		availableApplication;

	useEffect(() => {
		if (requiresConnectUri) {
			document.getElementById('app-connect-uri')?.focus();
		}
	}, [showPreface, requiresConnectUri]);

	const getPrefacePageModel = (): PageModel => {
		return {
			onConfirm: () => setShowPreface(false),
			onCancel: () => onToggle(false),
			disableConfirm: false,
			confirmText: intl.formatMessage(messages.continue),
			children: getPrefaceContent(intl, customer),
		};
	};

	const getConnectUriPageModel = (): PageModel => {
		const inputPlaceholderKey = `inputPlaceholder_${app.toLowerCase()}`;
		const placeholderText = intl.formatMessage(
			messages[inputPlaceholderKey],
		);
		const valid = connectUri && isUrlValid(intl, connectUri);

		return {
			onConfirm: async () => {
				setConnectUri(connectUri);
				if (!valid) {
					return;
				}
				await handleAddClick({ intl, appContext, app, connectUri });
				onToggle(false);
			},
			onCancel: () => onToggle(false),
			disableConfirm: !valid,
			confirmText: intl.formatMessage(messages.add),
			children: (
				<ModalContent>
					<ProductSpecificContent app={app} />
					<Input
						id="app-connect-uri"
						defaultValue={connectUri}
						onChange={(value) => setConnectUri(value)}
						placeholder={placeholderText}
						aria-label={placeholderText}
						type={'url'}
					/>
					{!appContext.featureFlags.enableMultipleProdInstances ? (
						<WarningLabel>
							<FormattedMessage
								{...messages.multipleProductionInstancesWarning}
							/>
						</WarningLabel>
					) : null}
				</ModalContent>
			),
		};
	};

	const getIdeaPlacePageModel = (): PageModel => {
		return {
			onConfirm: () => {},
			disableConfirm: true,
			onCancel: () => onToggle(false),
			confirmText: intl.formatMessage(messages.add),
			children: (
				<ModalContent>
					<Paragraph>
						<FormattedMessage
							{...messages.contactServiceModalText}
							values={{ appName: productName }}
						/>
					</Paragraph>
				</ModalContent>
			),
		};
	};

	const getFoundationAppPageModel = (): PageModel => {
		return {
			onConfirm: async () => {
				await handleAddFoundationAppClick({
					intl,
					appContext,
					app,
					sandbox,
				});
				onToggle(false);
			},
			onCancel: () => onToggle(false),
			confirmText: intl.formatMessage(messages.add),
			disableConfirm: false,
			children: (
				<ModalContent>
					<Paragraph>
						<FormattedMessage
							{...messages.addNewInstanceModalText}
							values={{ appName: productName }}
						/>
					</Paragraph>
					{addSandboxSupported && (
						<Checkbox
							label={intl.formatMessage(messages.addSandbox)}
							selected={sandbox}
							onChange={(enabled) => setSandbox(enabled)}
						/>
					)}
				</ModalContent>
			),
		};
	};

	const getPageModel = (): PageModel => {
		if (requiresConnectUri) {
			return showPreface
				? getPrefacePageModel()
				: getConnectUriPageModel();
		}

		if (
			isIdeaPlaceLicensingSupported(
				app,
				!!appContext.featureFlags.enableIdeaplaceLicensing,
			)
		) {
			return getIdeaPlacePageModel();
		}

		return getFoundationAppPageModel();
	};

	const pageModel: PageModel = getPageModel();

	return (
		<Modal
			{...pageModel}
			id={id}
			headerText={intl.formatMessage(messages.header)}
			size={MODAL_LARGE}
		/>
	);
};

export default AddProductModal;
