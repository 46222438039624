import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { Key, Settings } from '@planview/pv-icons';
import EnvironmentTag from '../../../../components/common/environmentTag';
import messages from './OKRSettingsTile.messages';
import { default as settingsTileMessages } from '../SettingsTile.messages';
import { AppContext } from '../../../../context';
import OKRTokenModal from './OKRTokenModal';
import { ButtonGhost, DropdownMenu, ListItem } from '@planview/pv-uikit';
import { useNavigate } from 'react-router-dom';
import SettingsTile from '../SettingsTile';
import { ToastType } from '../../../../types/toast';
import AppLogo from '../../../../components/common/appLogo/AppLogo';

type OKRSettingsTileProps = {
	sandbox: boolean;
	tenantGroupId: string;
	showPrimaryLabel: boolean;
	title: string;
	imageScale: number;
};

const OKRSettingsTile = (props: OKRSettingsTileProps) => {
	const {
		sandbox,
		tenantGroupId,
		showPrimaryLabel = false,
		title,
		imageScale,
	} = props;

	// state variable to show modal settings window
	const [showGenerateToken, setShowGenerateToken] = useState(false);
	const appContext = useContext(AppContext);
	const intl = useIntl();
	const navigate = useNavigate();

	const settingsTileIcon = <AppLogo app={'OKRS'} scale={imageScale} />;
	const settingsTileEnvLabel = (
		<EnvironmentTag
			sandbox={sandbox}
			secondarySandboxIsPrimary={showPrimaryLabel}
			secondarySandboxLabel={title}
		/>
	);

	const settingsTileActionButtons = (
		<>
			<ButtonGhost
				icon={<Key />}
				onClick={() => setShowGenerateToken(true)}
			>
				{intl.formatMessage(messages.generateTokenButton)}
			</ButtonGhost>
			<DropdownMenu
				label="settings"
				trigger={(props) => (
					<ButtonGhost {...props} icon={<Settings />} withCaret>
						{intl.formatMessage(
							settingsTileMessages.settingsButton,
						)}
					</ButtonGhost>
				)}
			>
				<ListItem
					label={intl.formatMessage(messages.okrLevels)}
					onActivate={() =>
						navigate(
							`/admin/settings/services/okr/levels/${tenantGroupId}`,
							{ state: { redirect: true } },
						)
					}
				/>
				<ListItem
					label={intl.formatMessage(messages.okrCustomAttributes)}
					onActivate={() =>
						navigate(
							`/admin/settings/services/okr/custom-attributes/${tenantGroupId}`,
							{ state: { redirect: true } },
						)
					}
				/>
			</DropdownMenu>
		</>
	);

	return (
		<>
			<SettingsTile
				icon={settingsTileIcon}
				environmentLabel={settingsTileEnvLabel}
				actionButtons={settingsTileActionButtons}
			/>
			{showGenerateToken ? (
				<OKRTokenModal
					tenantGroupId={tenantGroupId}
					label={title}
					onCancel={() => setShowGenerateToken(false)}
					onConfirm={() => {
						const message = intl.formatMessage(
							messages.copyToClipboardMessage,
						);
						appContext.showToast({
							message,
							type: ToastType.SUCCESS,
						});
					}}
				/>
			) : null}
		</>
	);
};
export default OKRSettingsTile;
