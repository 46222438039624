import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Modal } from '../../../../components/common/modal/Modal';
import { DESTRUCTIVE } from '@planview/pv-uikit';
import styled from 'styled-components';
import modalMessages from '../../../../components/common/modal/Modal.messages';
import messages from './ClientCredentials.messages';

type DeactivateClientCredentialsModalProps = {
	onConfirm: () => void;
	onCancel: () => void;
};

const NoTopMarginList = styled.ul`
	margin-top: 0;
`;

/**
 * Represents a form for revoking OAuth2 credentials.
 */
const DeactivateClientCredentialModal = (
	props: DeactivateClientCredentialsModalProps,
) => {
	const { onConfirm, onCancel } = props;
	const intl = useIntl();
	const [isDeactivating, setIsDeactivating] = useState(false);

	return (
		<Modal
			type={DESTRUCTIVE}
			onConfirm={() => {
				setIsDeactivating(true);
				onConfirm();
				setIsDeactivating(false);
			}}
			onCancel={() => {
				onCancel();
				setIsDeactivating(false);
			}}
			confirmText={intl.formatMessage(messages.deactivateButton)}
			cancelText={intl.formatMessage(modalMessages.cancel)}
			headerText={intl.formatMessage(messages.deactivateHeader)}
			disableConfirm={isDeactivating}
		>
			<div>
				<strong>
					{intl.formatMessage(messages.deactivateWarning)}
				</strong>
			</div>
			<NoTopMarginList>
				<li>{intl.formatMessage(messages.deactivateWarningDetail1)}</li>
				<li>{intl.formatMessage(messages.deactivateWarningDetail2)}</li>
			</NoTopMarginList>
			<div>{intl.formatMessage(messages.deactivateConfirmation)}</div>
		</Modal>
	);
};

export default DeactivateClientCredentialModal;
