/**
 * The OverviewPage is comprised of a section of tiles (for users and admin) that are
 * actionable (open the tenant when clicked).  Only PVID ssoEnabled tenants that also have an associated username
 * should be displayed on this page. There is no difference between a user and customer admin when
 * clicking one of the tiles.  There are also Profile and Quick Link sections.
 */

import React, { useContext } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import styled from 'styled-components';
import { theme, spacingPx } from '@planview/pv-utilities';
import ProductTile from '../../../components/home/productTile/ProductTile';
import {
	AppContext,
	AppContextProps,
	UserContext,
	UserContextProps,
} from '../../../context';
import {
	HBox,
	NonPhoneMinWidth,
	PhoneMaxWidth,
	VBox,
} from '../../../components/common/Layout';
import ProfileAndLinksSection from '../profile/ProfileAndLinksSection';
import { PageHeader } from '../../../components/common/Wrappers';
import { Section, SectionTitle } from '../../../components/home/Section';
import { Link } from '../../../components/common/Link';
import messages from './OverviewPage.messages';
import { Help, Link as LinkIcon } from '@planview/pv-icons';
import {
	DISMISS_AFTER_FIVE_SECONDS,
	DISMISS_AFTER_TWO_SECONDS,
} from '../../../components/common/toast/Toast';
import { ToastType } from '../../../types/toast';
import { Application, User } from '../../../types';
import { RightTooltip } from '@planview/pv-uikit';

const Container = styled.div`
	height: 100%;
	overflow: auto;
`;

const PageHeaderIndented = styled(PageHeader)`
	padding-left: ${spacingPx.medium};
`;
PageHeaderIndented.displayName = 'PageHeader';

const Content = styled.div`
	padding: ${spacingPx.medium};
`;

const ManageProductsLink = styled(Link)`
	font-weight: bold;
`;

const TopLevelVbox = styled(VBox)`
	width: -webkit-fill-available;
	@media only screen and (min-width: ${NonPhoneMinWidth}px) {
		flex-basis: ${PhoneMaxWidth * 0.6}px;
	}
`;

const getNoProductsDisplayMessage = (userContext: UserContextProps) => {
	let message = messages.noProducts;
	const { isAdmin } = userContext;
	if (isAdmin) {
		message = messages.noProductsAdmin;
	}
	return message;
};

const NoProductsText = () => {
	const userContext = useContext(UserContext);
	const displayMessage = getNoProductsDisplayMessage(userContext);

	return (
		<Content data-testid="overview-noProductsContent">
			{userContext.isPvCustomer() ? (
				<div>
					<FormattedMessage {...displayMessage} />
				</div>
			) : (
				<>
					<div>
						<FormattedMessage {...displayMessage} />
					</div>
					{userContext.isAdmin ? (
						<ManageProductsLink to={'/admin/products'}>
							<FormattedMessage {...messages.manageProducts} />
						</ManageProductsLink>
					) : null}
				</>
			)}
		</Content>
	);
};

const headerActions = (
	application: Application,
	intl: IntlShape,
	appContext: AppContextProps,
	userContext: UserContextProps,
) => [
	{
		text: intl.formatMessage(messages.copyLink),
		handler: () => {
			const { showToast } = appContext;
			const { user, hasPassword } = userContext;
			const { custom, domain, serviceApp } = application;

			const navigateUrl = new URL(application.navigateUrl);
			if (hasPassword(user as User)) {
				navigateUrl.searchParams.append('sso', 'false');
			}
			navigator.clipboard
				.writeText(
					custom && !serviceApp
						? `https://${domain}`
						: navigateUrl.toString(),
				)
				.then(
					() => {
						showToast({
							dismissAfter: DISMISS_AFTER_TWO_SECONDS,
							message: intl.formatMessage(
								messages.copyToClipboardSuccess,
							),
							type: ToastType.SUCCESS,
						});
					},
					() => {
						showToast({
							dismissAfter: DISMISS_AFTER_FIVE_SECONDS,
							message: intl.formatMessage(
								messages.copyToClipboardError,
							),
							type: ToastType.DANGER,
						});
					},
				);
		},
		icon: <LinkIcon />,
	},
];

const OverviewPage = () => {
	const appContext = useContext(AppContext);
	const userContext = useContext(UserContext);
	const intl = useIntl();
	const { customer } = userContext;
	const { overviewApplications } = appContext;
	const isPhone = window.innerWidth <= PhoneMaxWidth;

	const productGroupApplications = overviewApplications.filter(
		(application) => !application.sandbox && !application.serviceApp,
	);

	const serviceGroupApplications = overviewApplications.filter(
		(application) => application.serviceApp,
	);

	const primarySandboxTenantGroupId = overviewApplications.find(
		(application) => application.primary && application.sandbox,
	)?.tenantGroupId;

	const primarySandboxApplications = overviewApplications.filter(
		(application) =>
			application.tenantGroupId === primarySandboxTenantGroupId &&
			application.sandbox,
	);

	const sandboxApplications = overviewApplications.filter(
		(application) =>
			application.sandbox &&
			application.tenantGroupId !== primarySandboxTenantGroupId,
	);

	return (
		<Container>
			<PageHeaderIndented>
				<FormattedMessage {...messages.pageTitle} />
			</PageHeaderIndented>
			<HBox>
				<TopLevelVbox flex={6} data-testid="overview-section">
					{productGroupApplications.length > 0 && (
						<Section>
							<div data-testid="product-section">
								<SectionTitle data-testid="product-sectionTitle">
									<FormattedMessage {...messages.products} />
								</SectionTitle>
								<HBox>
									{productGroupApplications.map(
										(application, idx) => (
											<ProductTile
												key={idx}
												application={application}
												tileType="mapped"
												headerActions={headerActions(
													application,
													intl,
													appContext,
													userContext,
												)}
											/>
										),
									)}
								</HBox>
							</div>
						</Section>
					)}

					{!customer.hideServiceTiles &&
						serviceGroupApplications.length > 0 && (
							<Section>
								<div data-testid="services-section">
									<SectionTitle data-testid="services-sectionTitle">
										<FormattedMessage
											{...messages.services}
										/>
									</SectionTitle>
									<HBox>
										{serviceGroupApplications.map(
											(app, idx) => (
												<ProductTile
													key={idx}
													application={app}
													tileType="mapped"
													headerActions={headerActions(
														app,
														intl,
														appContext,
														userContext,
													)}
												/>
											),
										)}
									</HBox>
								</div>
							</Section>
						)}

					{primarySandboxApplications.length > 0 && (
						<Section>
							<div data-testid="primarySandbox-section">
								<SectionTitle data-testid="primarySandbox-sectionTitle">
									<FormattedMessage
										{...messages.primarySandboxes}
									/>
									{!isPhone && (
										<RightTooltip
											text={intl.formatMessage(
												messages.primarySandboxHelpTooltip,
											)}
											enterDelay={200}
										>
											<Help
												style={{
													color: theme.iconNormal,
												}}
											/>
										</RightTooltip>
									)}
								</SectionTitle>
								<HBox>
									{primarySandboxApplications.map(
										(app, idx) => (
											<ProductTile
												key={idx}
												application={app}
												tileType="mapped"
												headerActions={headerActions(
													app,
													intl,
													appContext,
													userContext,
												)}
											/>
										),
									)}
								</HBox>
							</div>
						</Section>
					)}

					{sandboxApplications.length > 0 && (
						<Section>
							<div data-testid="sandboxes-section">
								<SectionTitle data-testid="sandboxes-sectionTitle">
									<FormattedMessage {...messages.sandboxes} />
									{!isPhone && (
										<RightTooltip
											text={intl.formatMessage(
												messages.sandboxHelpTooltip,
											)}
											enterDelay={200}
										>
											<Help
												style={{
													color: theme.iconNormal,
												}}
											/>
										</RightTooltip>
									)}
								</SectionTitle>
								<HBox>
									{sandboxApplications.map((app, idx) => (
										<ProductTile
											key={idx}
											application={app}
											tileType="mapped"
											headerActions={headerActions(
												app,
												intl,
												appContext,
												userContext,
											)}
										/>
									))}
								</HBox>
							</div>
						</Section>
					)}
					{productGroupApplications.length === 0 &&
						serviceGroupApplications.length === 0 &&
						primarySandboxApplications.length === 0 &&
						sandboxApplications.length === 0 && (
							<Section>
								<div data-testid="noProducts-section">
									<NoProductsText />
								</div>
							</Section>
						)}
				</TopLevelVbox>
				<ProfileAndLinksSection />
			</HBox>
		</Container>
	);
};

export default OverviewPage;
