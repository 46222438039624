// TODO: A couple places are too complex to convert to TS, so still need to think through later
import React, { createElement, useEffect, useMemo, useRef } from 'react';
import useDovetail from '../../../hooks/useDovetail';
import { get } from '../../../hooks/request/request';
import {
	DovetailComponentType,
	DovetailLocale,
} from '../../../types/dovetail/dovetail';
import { OAuth2TokenResponse } from '../../../types/api/tokens';

type DovetailComponentProperties = {
	componentName: string;
	applicationName?: string;
	options: DovetailComponentOptions;
};

type DovetailComponentOptions = {
	product?: string;
};

const DOVETAIL_OPTIONS_MAP = new Map<
	DovetailComponentType,
	DovetailComponentProperties
>([
	[
		'okrs',
		{
			componentName: 'OKR',
			applicationName: 'OKRS',
			options: { product: 'platforma' },
		},
	],
	[
		'notifications',
		{
			componentName: 'Notification',
			options: { product: 'platforma' },
		},
	],
	[
		'launchpad',
		{
			componentName: 'LaunchPad',
			applicationName: 'PLANVIEW_ME',
			options: { product: 'platforma' },
		},
	],
	[
		'logbook',
		{
			componentName: 'Logbook',
			applicationName: 'LOGBOOK',
			options: { product: 'projectplace' },
		},
	],
	[
		'groups',
		{
			componentName: 'Groups',
			applicationName: 'GROUPS',
			options: { product: 'platforma' },
		},
	],
]);

const SUPPORTED_DOVETAIL_LOCALES = new Map<string, DovetailLocale>([
	['en', 'en-US'],
	['de', 'de-DE'],
	['es', 'es-ES'],
	['fr', 'fr-FR'],
]);

export const getDovetailLocale = (lang: string) => {
	if (Object.values(SUPPORTED_DOVETAIL_LOCALES).includes(lang)) {
		return lang;
	}

	return SUPPORTED_DOVETAIL_LOCALES.get(lang.substring(0, 2)) || 'en-US';
};

const getPlaceholder = (name: string) => () => {
	return {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		render(props) {
			console.log('Missing dovetail component', {
				name,
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				props,
			}); // eslint-disable-line no-console
		},
		unmount() {},
	};
};

const getPlanviewAuthTokenClosure = (
	tenantGroupId: string,
	applicationName?: string,
) => {
	return async function planviewAuthTokenClosure() {
		const urlParams = {
			tenantGroupId,
			...(applicationName && { app: applicationName }),
		};

		const queryString = new URLSearchParams(urlParams).toString();
		const {
			success,
			message,
			id_token: idToken,
			access_token: accessToken,
			refresh_token: refreshToken,
			expires_in: expiresIn,
			token_type: tokenType,
		} = (await get(
			`/io/v1/user/token?${queryString}`,
		)) as OAuth2TokenResponse;
		if (!success) {
			throw new Error(message);
		}

		return {
			idToken,
			accessToken,
			refreshToken,
			expiresIn,
			tokenType,
		};
	};
};

type DovetailComponentProps = {
	url: string;
	name: string;
	environment?: string;
	type: DovetailComponentType;
	className?: string;
	tenantGroupId: string;
	props?: AdditionalDovetailProps;
	element: string;
};

const DovetailInternalComponent = ({
	url,
	name,
	environment,
	type,
	className,
	tenantGroupId,
	props,
	element = 'div',
}: DovetailComponentProps) => {
	const componentProperties = DOVETAIL_OPTIONS_MAP.get(type);
	if (!componentProperties) {
		throw new Error(
			`Dovetail component properties not found for type: ${type}`,
		);
	}

	const { componentName, applicationName, options } = componentProperties;
	const platformaAuthTokenClosure = getPlanviewAuthTokenClosure(
		tenantGroupId,
		applicationName,
	);

	const componentLibrary = useDovetail(url, componentName, {
		platformaAuthTokenClosure,
		environment,
		...options,
	});
	const el = useRef(null);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const component = useRef<any>(null);

	useEffect(() => {
		if (!componentLibrary) {
			return;
		}

		/* Set up initial component */
		if (!component.current) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			const comp =
				name in componentLibrary.components
					? componentLibrary.components[name]
					: getPlaceholder(name);

			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call
			component.current = comp({ element: el.current });
		}

		/* Update values on initial render */
		// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
		component.current.render(props);
	}, [componentLibrary, name, props]);

	useEffect(() => {
		return () => {
			if (component.current) {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
				component.current.unmount();
			}
		};
	}, [component]);

	return createElement(element, {
		className,
		ref: el,
		style: { display: 'flex', flexDirection: 'column', flexGrow: 1 },
	});
};

type UserLocale = {
	date_format: string;
	locale: string;
	start_of_week: number;
};

type EnvironmentType = {
	label: string;
	color: string;
};

export type AdditionalDovetailProps = {
	className?: string;
	environment?: string;
	user_id?: string;
	userId?: string;
	user_locale?: UserLocale;
	baseUrl?: string;
	environmentType?: EnvironmentType;
};

const DovetailComponent = ({
	url,
	name,
	element,
	type,
	tenantGroupId,
	props: _props = {},
}: DovetailComponentProps) => {
	const props = useMemo(() => {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { environment, className, ...restOfProps } = _props;
		return restOfProps;
	}, [_props]);

	const { environment, className } = _props;
	if (!url) {
		return null;
	}
	return (
		<DovetailInternalComponent
			element={element}
			className={className}
			url={url}
			name={name}
			tenantGroupId={tenantGroupId}
			environment={environment}
			type={type}
			props={props}
		/>
	);
};

export default DovetailComponent;
