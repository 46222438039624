/* eslint-disable max-statements */

import { useContext } from 'react';
import { AppContext, UserContext } from '../../../../context';
import useResetPasswordOrResendInviteAction from './useResetPasswordOrResendInviteAction';
import useExportUsersAction from './useExportUsersAction';
import useEditUserAction from './useEditUserAction';
import useDeleteUserAction from './useDeleteUserAction';
import useImpersonateUserAction from '../../../pvadmin/organizationInfo/hooks/useImpersonateUserAction';
import useChangeRoleActions from './useChangeRoleActions';
import { UserTabs } from '../UsersTabsPage';
import useUserActivationActions from './useUserActivationActions';
import useImportUsersAction from './useImportUsersAction';
import useAddProductEnvsAction from './useAddProductEnvsAction';
import useChangePasswordLoginActions from './useChangePasswordLoginActions';
import useSetPasswordAction from './useSetPasswordAction';
import useSetGroupsAction from './useSetGroupsAction';

// React hook for user actions
const useActions = ({
	refresh,
	isCustomerCare,
	filterParams,
	customer,
	mode = UserTabs.CURRENT_USERS,
}) => {
	const userContext = useContext(UserContext);
	const appContext = useContext(AppContext);
	const { featureFlags } = appContext;

	const customerId = customer.id;
	const showTopDownComponents = customer.topDownUserManagementEnabled;

	// PV Admins level 300 (Customer Care) cannot perform most function on Planview Customer
	const customerCareViewingPvCustomer =
		userContext.isCustomerCareViewingPvCustomer(customer.id);

	const hasFoundationApp = appContext.applications.some(
		(app) => app.foundationApp,
	);

	const { impersonateAction, impersonateModal } = useImpersonateUserAction();
	const { addProductEnvsAction, addProductEnvsModal } =
		useAddProductEnvsAction(isCustomerCare);
	const { editAction, editModal } = useEditUserAction({ refresh });
	const { resendInviteAction, resetPasswordAction } =
		useResetPasswordOrResendInviteAction(isCustomerCare);
	const {
		makeSuperAdminAction,
		makeCustomerCare,
		makeAdminAction,
		removeAdminAction,
		makeUserAction,
	} = useChangeRoleActions({ refresh });
	const { enablePasswordLoginAction, disablePasswordLoginAction } =
		useChangePasswordLoginActions({ refresh, isCustomerCare });
	const { deleteAction, deleteModal } = useDeleteUserAction({ refresh });
	const { exportUsersAction } = useExportUsersAction({ filterParams });
	const {
		deactivateAction,
		activateAction,
		bulkActivateAction,
		activateModal,
		bulkActivateModal,
		deactivateModal,
	} = useUserActivationActions({ refresh });
	const { importUsersAction, importUsersModal } = useImportUsersAction({
		isCustomerCare,
		customer,
	});
	const { setPasswordAction, setPasswordModal } = useSetPasswordAction({
		refresh,
	});
	const { setGroupsAction, setGroupsModal } = useSetGroupsAction({ refresh });

	const menuActions = [];
	let toolbarActions = [];

	// Check mode, which tab actions to render
	if (mode === UserTabs.CURRENT_USERS) {
		// Customer Care (from the Organization > Organization Users page) has
		// smaller set of options
		if (isCustomerCare) {
			if (!customerCareViewingPvCustomer) {
				menuActions.push(impersonateAction);
				menuActions.push(resendInviteAction);
				menuActions.push(resetPasswordAction);
				menuActions.push(enablePasswordLoginAction);
				menuActions.push(disablePasswordLoginAction);
			}

			if (featureFlags.enableCcSetUserGroups) {
				toolbarActions.push(setGroupsAction);
			}
			if (featureFlags.enableCcSetUserPw && customerId !== 'PLANVIEW') {
				toolbarActions.push(setPasswordAction);
			}

			if (featureFlags.enableCcSetUserGroups) {
				menuActions.push(setGroupsAction);
			}
		} else {
			if (showTopDownComponents || hasFoundationApp) {
				toolbarActions.push(addProductEnvsAction);
			}
			menuActions.push(editAction);
			menuActions.push(resendInviteAction);
			menuActions.push(resetPasswordAction);
			if (userContext.isSuperAdminViewingPvCustomer(customerId)) {
				menuActions.push(makeSuperAdminAction);
				menuActions.push(makeCustomerCare);
				menuActions.push(makeUserAction);
			} else {
				menuActions.push(makeAdminAction);
				menuActions.push(removeAdminAction);
			}

			if (userContext.customer.ssoEnabled) {
				menuActions.push(enablePasswordLoginAction);
				menuActions.push(disablePasswordLoginAction);
			}

			if (featureFlags.enableDeactivateUsers) {
				menuActions.push(deactivateAction);
			} else {
				menuActions.push(deleteAction);
			}

			toolbarActions = toolbarActions.concat(menuActions);
			toolbarActions.push(exportUsersAction);
			toolbarActions.push(importUsersAction);
		}
	} else {
		menuActions.push(activateAction);
		menuActions.push(deleteAction);

		toolbarActions.push(bulkActivateAction);
		toolbarActions.push(deleteAction);
	}

	const modals = [
		addProductEnvsModal,
		editModal,
		deleteModal,
		impersonateModal,
		activateModal,
		bulkActivateModal,
		deactivateModal,
		importUsersModal,
		setPasswordModal,
		setGroupsModal,
	];

	return { menuActions, toolbarActions, modals };
};

export default useActions;
