import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { AppContext, CustomerContext, UserContext } from '../../../context';
import gridMessages from '../../../components/admin/UsersGrid.messages';
import messages from './UsersPage.messages';
import filterMessages from '../../../components/common/filter/Filter.messages';
import { UserTabs } from './UsersTabsPage';
import {
	FilterPanel,
	FilterSectionList,
	FilterSectionRangeDate,
	FilterSectionText,
} from '@planview/pv-filter';
import { Application, Tenant } from '../../../types';
import GroupsContext from '../../../context/groupsContext';

export type AdminUsersFilter = {
	securityLevel?: string[];
	authenticationType?: string[];
	envSelectors?: string[];
	provisionStatus?: string[];
	active?: string[];
	status?: string[];
	deactivated?: string[];
	lastLoginAt?: Array<string | null>;
	systemGroups: Set<string>;
};

type AdminUsersFilterInternal = {
	securityLevel: Set<string>;
	authenticationType: Set<string>;
	envSelectors: Set<string>;
	provisionStatus: Set<string>;
	active: Set<string>;
	status: Set<string>;
	lastLoginAt: Array<Date | null>;
	systemGroups: Set<string>;
};

type AdminFilterSection = {
	value: Set<string>;
	onChange: (val: Set<string>) => void;
	isCustomerCare?: boolean;
};

export const beginningOfTime = new Date('1970-01-01T00:00:00Z').toISOString();
export const endOfDays = new Date(
	+new Date().setHours(0, 0, 0, 0) + 86400000 * 365,
).toISOString();

/**
 * Parses the dates already set for filtering for use by FilterSectionRangeDate.
 * @param filterDates the user-set dates for filtering
 * @return the array of Dates
 */
const parseDates = (
	filterDates: Array<string | null> | undefined,
): Array<Date | null> => {
	if (filterDates === undefined) {
		return [];
	}
	let startDateString = filterDates[0];
	if (startDateString === beginningOfTime) {
		startDateString = null;
	}

	let endDateString = filterDates[1];
	if (endDateString === endOfDays) {
		endDateString = null;
	}

	return [
		startDateString === null ? null : new Date(startDateString),
		endDateString === null ? null : new Date(endDateString),
	];
};

const SecurityLevelFilter = ({
	value,
	onChange,
	isCustomerCare,
}: AdminFilterSection) => {
	const intl = useIntl();
	const userContext = useContext(UserContext);
	const superAdminViewingPlanviewCustomer =
		userContext.isSuperAdmin && !isCustomerCare;

	const options = React.useMemo(() => {
		if (superAdminViewingPlanviewCustomer) {
			return [
				{
					id: '400',
					label: 'Super admin',
				},
				{
					id: '300',
					label: 'Customer care',
				},
				{
					id: '100',
					label: 'User',
				},
			];
		}
		return [
			{
				id: '200',
				label: intl.formatMessage(messages.yesFilterOption),
			},
			{
				id: '100',
				label: intl.formatMessage(messages.noFilterOption),
			},
		];
	}, [superAdminViewingPlanviewCustomer, intl]);

	return (
		<FilterSectionList
			label={
				superAdminViewingPlanviewCustomer
					? 'Role'
					: intl.formatMessage(messages.userAdminHeader)
			}
			value={value}
			options={options}
			onChange={onChange}
		/>
	);
};

const toApp = (tenant: Tenant): SimpleApplication => ({
	filterable: tenant.filterable,
	envSelectorString: `${tenant.application}~${tenant.tenantId}`,
	title: tenant.title,
});

const byTitle = (a: SimpleApplication, b: SimpleApplication) =>
	a.title.localeCompare(b.title);

const ProductFilters = ({
	value,
	onChange,
	isCustomerCare,
}: AdminFilterSection) => {
	const intl = useIntl();
	const appContext = useContext(AppContext);
	const customerContext = useContext(CustomerContext);

	const applications: SimpleApplication[] = React.useMemo(() => {
		if (isCustomerCare) {
			if (customerContext.customer.tenants) {
				return customerContext.customer.tenants
					?.map(toApp)
					.sort(byTitle);
			}
			return [];
		}

		return appContext.applications;
	}, [isCustomerCare, appContext, customerContext]);

	const options = React.useMemo(() => {
		const apps = applications
			.filter((app) => app.filterable)
			.map((app) => ({
				id: app.envSelectorString,
				label: app.title,
			}));

		return [
			{
				id: 'NONE',
				label: intl.formatMessage(messages.filterNoProductsMapped),
			},
			...apps,
		];
	}, [applications, intl]);

	return (
		<FilterSectionList
			label={intl.formatMessage(gridMessages.productsColumn)}
			options={options}
			value={value}
			onChange={onChange}
		/>
	);
};

type AllUserFiltersProps = {
	onChange: (key: keyof AdminUsersFilterInternal, val: Set<string>) => void;
	filterParams: AdminUsersFilterInternal;
	isCustomerCare: boolean;
};

const AllUserFilters = ({
	filterParams,
	isCustomerCare,
	onChange,
}: AllUserFiltersProps) => {
	const intl = useIntl();
	const appContext = useContext(AppContext);
	const userContext = useContext(UserContext);
	const customerContext = useContext(CustomerContext);
	const groupsContext = useContext(GroupsContext);
	const customer = isCustomerCare
		? customerContext.customer
		: userContext.customer;
	const { showLastLogin, enableCcSetUserGroups } = appContext.featureFlags;
	const showSystemGroups =
		userContext.isCustomerCare && enableCcSetUserGroups;

	const groupOptions = groupsContext?.systemGroups.map((group) => ({
		id: group.id,
		label: group.title,
	}));

	return (
		<>
			<SecurityLevelFilter
				value={filterParams.securityLevel}
				onChange={(securityLevel) =>
					onChange('securityLevel', securityLevel)
				}
				isCustomerCare={isCustomerCare}
			/>
			{customer.ssoEnabled && (
				<FilterSectionList
					label={intl.formatMessage(gridMessages.localAuthColumn)}
					value={filterParams.authenticationType}
					options={[
						{
							id: 'PASSWORD',
							label: intl.formatMessage(filterMessages.yesFilter),
						},
						{
							id: 'SSO',
							label: intl.formatMessage(filterMessages.noFilter),
						},
					]}
					onChange={(authenticationType) =>
						onChange('authenticationType', authenticationType)
					}
				/>
			)}
			<ProductFilters
				isCustomerCare={isCustomerCare}
				value={filterParams.envSelectors}
				onChange={(envSelectors) =>
					onChange('envSelectors', envSelectors)
				}
			/>
			{customer.topDownUserManagementEnabled && (
				<FilterSectionList
					label={intl.formatMessage(
						messages.userProvisionStatusHeader,
					)}
					options={[
						{
							id: 'ERROR',
							label: intl.formatMessage(
								messages.errorFilterOption,
							),
						},
					]}
					value={filterParams.provisionStatus}
					onChange={(provisionStatus) =>
						onChange('provisionStatus', provisionStatus)
					}
				/>
			)}
			<FilterSectionList
				label={intl.formatMessage(messages.userStatusHeader)}
				options={[
					{
						id: 'true',
						label: intl.formatMessage(
							messages.userStatusActiveUser,
						),
					},
					{
						id: 'false',
						label: intl.formatMessage(
							messages.userStatusPendingUser,
						),
					},
				]}
				value={filterParams.active}
				onChange={(active) => onChange('active', active)}
			/>
			{showLastLogin && (
				<FilterSectionRangeDate
					label={intl.formatMessage(gridMessages.lastLoginAtColumn)}
					value={[
						filterParams.lastLoginAt[0],
						filterParams.lastLoginAt[1],
					]}
					onChange={(value) => {
						let startDate = value[0];
						let endDate = value[1];

						if (startDate === null && endDate === null) {
							// clear filter case:
							filterParams.lastLoginAt = [];
							onChange('lastLoginAt', new Set([]));
						} else {
							// if we have a null startDate, make it the beginning to time:
							if (startDate === null) {
								startDate = new Date(beginningOfTime);
							}

							// if we have a null endDate, make it the end of days:
							if (endDate === null) {
								endDate = new Date(endOfDays);
							}

							// set start date in params:
							filterParams.lastLoginAt[0] = startDate;

							// set end date in params:
							filterParams.lastLoginAt[1] = endDate;

							// convert Dates to ISO strings, and notify onChange:
							onChange(
								'lastLoginAt',
								new Set([
									filterParams.lastLoginAt[0].toISOString(),
									filterParams.lastLoginAt[1].toISOString(),
								]),
							);
						}
					}}
				/>
			)}
			{showSystemGroups && groupOptions.length > 0 && (
				<FilterSectionList
					label="System Groups"
					options={groupOptions}
					value={filterParams.systemGroups}
					onChange={(systemGroups) =>
						onChange('systemGroups', systemGroups)
					}
				/>
			)}
		</>
	);
};

type SimpleApplication = Pick<
	Application,
	'envSelectorString' | 'title' | 'filterable'
>;

export type UsersFilterProps = {
	enableClearFilter: boolean;
	onClearFilterClick: () => void;
	onCloseFilterClick: () => void;
	onClearSearchClick?: () => void;
	filterParams: AdminUsersFilter;
	searchValue: string;
	setSearchValue: (val: string | null) => void;
	isCustomerCare: boolean;
	mode: UserTabs;
	onChange: (key: keyof AdminUsersFilter, value: string[] | null) => void;
};

const UsersFilter = ({
	enableClearFilter,
	onClearFilterClick,
	onCloseFilterClick,
	filterParams: incomingFilters,
	searchValue,
	setSearchValue,
	isCustomerCare,
	mode = UserTabs.CURRENT_USERS,
	onChange,
}: UsersFilterProps) => {
	const intl = useIntl();

	const filterParams = React.useMemo<AdminUsersFilterInternal>(
		() => ({
			securityLevel: new Set(incomingFilters.securityLevel || []),
			authenticationType: new Set(
				incomingFilters.authenticationType || [],
			),
			envSelectors: new Set(incomingFilters.envSelectors || []),
			provisionStatus: new Set(incomingFilters.provisionStatus || []),
			active: new Set(incomingFilters.active || []),
			status: new Set(incomingFilters.status || []),
			lastLoginAt: parseDates(incomingFilters.lastLoginAt),
			systemGroups: new Set(incomingFilters.systemGroups || []),
		}),
		[incomingFilters],
	);

	const handleOnChange = React.useCallback(
		(key: keyof AdminUsersFilter, value: Set<string>) => {
			onChange(key, value.size ? [...value] : null);
		},
		[onChange],
	);

	return (
		<FilterPanel
			clearEnabled={enableClearFilter}
			onClear={onClearFilterClick}
			onClose={onCloseFilterClick}
		>
			<FilterSectionText
				label={intl.formatMessage(messages.user)}
				value={searchValue}
				onChange={setSearchValue}
				placeholder={intl.formatMessage(filterMessages.typeToSearch)}
			/>
			{mode === UserTabs.CURRENT_USERS && (
				<AllUserFilters
					onChange={handleOnChange}
					filterParams={filterParams}
					isCustomerCare={isCustomerCare}
				/>
			)}
		</FilterPanel>
	);
};

export default UsersFilter;
