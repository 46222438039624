import React, { useState, useContext } from 'react';
import { AppContext } from '../../../../context';
import messages from '../../organization/OrganizationsPage.messages';
import { useIntl } from 'react-intl';
import { requestWithErrorHandling } from '../../../../hooks/request/request';
import { ConfirmationModal } from '../../../../components/common/modal/Modal';
import { CustomerDto } from '../../../../types/api/customers';

type ResendMessagesConfirmationDialogProps = {
	onConfirm: () => void;
	onCancel: () => void;
	organization: CustomerDto;
};

const ResendMessagesConfirmationDialog = (
	props: ResendMessagesConfirmationDialogProps,
) => {
	const intl = useIntl();
	const appContext = useContext(AppContext);
	const [disableConfirm, setDisableConfirm] = useState(false);
	const { organization, onCancel, onConfirm } = props;

	const resendMessagesForAllTenants = async () => {
		setDisableConfirm(true);

		const orgId = organization.id;

		await requestWithErrorHandling({
			method: 'post',
			url: `/io/v1/admin/customer/${orgId}/resendMessagesForAllTenants`,
			appContext,
			intl,
			successMessage: messages.resendMessagesForOrgTenantsSuccess,
		});

		setDisableConfirm(false);
		onConfirm();
	};

	return (
		<ConfirmationModal
			onConfirm={resendMessagesForAllTenants}
			onCancel={onCancel}
			confirmText="Yes"
			cancelText="No"
			headerText="Resend Tenant Messages?"
			message="This will resend messages to each tenant to re-register with Planview Admin and initiate user syncs."
			disableConfirm={disableConfirm}
		/>
	);
};

const useResendMessagesForTenantAction = () => {
	const [organization, setResendMessagesOrg] = useState<CustomerDto | null>(
		null,
	);

	const resendMessagesModal = organization ? (
		<ResendMessagesConfirmationDialog
			key="confirmDialog"
			organization={organization}
			onCancel={() => {
				setResendMessagesOrg(null);
			}}
			onConfirm={() => {
				setResendMessagesOrg(null);
			}}
		/>
	) : null;

	return { setResendMessagesOrg, resendMessagesModal };
};

export default useResendMessagesForTenantAction;
