import { defineMessages } from 'react-intl';

const messages = defineMessages({
	description: {
		id: 'admin.addToBusinessUnit.dialog.description',
		defaultMessage:
			'A user with this email already exists in a different business unit.',
		description: '',
	},
	confirmationPrompt: {
		id: 'admin.addToBusinessUnit.dialog.confirmation.prompt',
		defaultMessage:
			'Do you want to add them to this business unit? You would inherit the profile of the user in the other business unit.',
		description: '',
	},
	cancel: {
		id: 'admin.addToBusinessUnit.dialog.cancel',
		defaultMessage: 'Cancel',
		description: '',
	},
});

export default messages;
