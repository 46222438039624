/* eslint-disable camelcase */
import { defineMessages, MessageDescriptor } from 'react-intl';

const messages: { [string: string]: MessageDescriptor } = defineMessages({
	adaptivework: {
		id: 'home.productTile.adaptiveWorkSlogan',
		description: 'One liner slogan to describe the product on add tile',
		defaultMessage:
			'Helps project delivery teams flexibly and efficiently execute work, automating processes to deliver great business impact.',
	},
	advisor: {
		id: 'home.productTile.advisorSlogan',
		description: 'One liner slogan to describe the product on add tile',
		defaultMessage:
			'Enables research and development leaders to connect strategy and portfolio performance to optimize return on investment during even the most uncertain times.',
	},
	copilot_admin: {
		id: 'home.productTile.copilotAdminSlogan',
		description: 'no need to translate - internal only app',
		defaultMessage: 'Copilot Admin.',
	},
	e1_prm: {
		id: 'home.productTile.e1Slogan',
		description: 'One liner slogan to describe the product on add tile',
		defaultMessage:
			'Enterprise-wide strategy to delivery: Plan and connect strategic initiatives, work, people, and financials to realize business outcomes.',
	},
	ea: {
		id: 'home.productTile.eaSlogan',
		description: 'One liner slogan to describe EA on add tile',
		defaultMessage:
			'Streamline and optimize enterprise structure, processes, and IT systems to enhance efficiency, innovation, and alignment with goals.',
	},
	hub: {
		id: 'home.productTile.hubSlogan',
		description: 'One liner slogan to describe the product on add tile',
		defaultMessage: 'Planview Hub',
	},
	launchpad: {
		id: 'home.productTile.launchpadSlogan',
		description:
			'One liner slogan to describe LaunchPad on add tile. TODO get this string',
		defaultMessage: 'Prototype unified dashboard for planview data.',
	},
	leankit: {
		id: 'home.productTile.leanKitSlogan',
		description: 'One liner slogan to describe the product on add tile',
		defaultMessage:
			'Enterprise Kanban to help Agile teams plan and visualize work, accelerate time-to-delivery, and continuously improve performance.',
	},
	planview_me: {
		id: 'home.productTile.planviewMeSlogan',
		description: 'One liner slogan to describe Planview.Me on add tile',
		defaultMessage:
			'Planview.Me is a new visually immersive, actionable and personalized user experience for the Planview Platform that highlights the data and details you need to get work done.',
	},
	ppmpro: {
		id: 'home.productTile.ppmProSlogan',
		description: 'One liner slogan to describe the product on add tile',
		defaultMessage:
			'Enterprise work management that advances PPM and project delivery capabilities.',
	},
	projectplace: {
		id: 'home.productTile.projectplaceSlogan',
		description: 'One liner slogan to describe the product on add tile',
		defaultMessage:
			'Project-centric collaborative workspaces that brings teams together to support everyday project delivery across the enterprise.',
	},
	roadmaps: {
		id: 'home.productTile.roadmapsSlogan',
		description: 'One liner slogan to describe the product on add tile',
		defaultMessage:
			'Guide on-strategy delivery by translating strategy into action, setting direction and expectations, and evaluating roadmap progress.',
	},
	teamtap: {
		id: 'home.productTile.teamtapSlogan',
		description: 'One liner slogan to describe the product on add tile',
		defaultMessage:
			'Enterprise idea and innovation management capabilities that also include lean and team level collaboration features.',
	},
	viz: {
		id: 'home.productTile.viz',
		description: 'One liner slogan to describe Viz on add tile',
		defaultMessage:
			'Value stream analytics solution that helps business leaders optimize end-to-end value delivery and make faster decisions.',
	},
	adminDisplayTile: {
		id: 'home.productTile.adminDisplayTile',
		description: 'Tile text replacing username',
		defaultMessage: 'No account',
	},
	logoutError: {
		id: 'home.productTile.logoutError',
		description: 'Text error when there is no active session',
		defaultMessage: 'Session expired. Please sign in again.',
	},
	syncErrors: {
		id: 'home.productTile.label.syncErrorsLabel',
		description:
			'Label on product details page for errors during last sync',
		defaultMessage: 'Sync errors',
	},
	syncErrorsDialogText: {
		id: 'home.productTile.label.syncErrorsDialogText',
		description: 'Dialog text when there are sync issues with a product',
		defaultMessage:
			'The following error was logged on the last attempt to sync users:',
	},
	optionsLabel: {
		id: 'home.productTile.label.options',
		description: 'Alt text for the menu button on a product tile',
		defaultMessage: 'options',
	},
	tileHiddenFromOverviewTooltip: {
		id: 'home.productTile.tooltip.hiddenFromOverview',
		description:
			'Tooltip for the tile icon indicating it is hidden on the Overview page',
		defaultMessage: 'Hidden from everyone in Overview',
	},
	planviewAdminEnabled: {
		id: 'home.productTile.icon.planviewAdminEnabled',
		description:
			'Tooltip text for icon in product tile when active in PV Admin',
		defaultMessage:
			"Planview Admin authentication enabled: Product's original authentication is disabled.",
	},
	planviewAdminDisabled: {
		id: 'home.productTile.icon.planviewAdminDisabled',
		description:
			'Tooltip text for icon in product tile when inactive in PV Admin',
		defaultMessage:
			"Planview Admin authentication disabled: Product's original authentication is enabled.",
	},
	syncInProgress: {
		id: 'home.productTile.icon.syncInProgress',
		description:
			'Tooltip text for icon in product tile when product is syncing',
		defaultMessage: 'Sync in progress',
	},
	pvAdminEnabledSyncErrorsTooltip: {
		id: 'home.productTile.icon.pvAdminEnabledSyncErrorsTooltip',
		description:
			'Tooltip text for icon in product tile when there are sync errors and the product is enabled in PV Admin',
		defaultMessage:
			'Product enabled and failed to sync. Click the icon for more details.',
	},
	pvAdminDisabledSyncErrorsTooltip: {
		id: 'home.productTile.icon.pvAdminDisabledSyncErrorsTooltip',
		description:
			'Tooltip text for icon in product tile when there are sync errors and the product is disabled in PV Admin',
		defaultMessage:
			'Product disabled and failed to sync. Click the icon for more details.',
	},
});

export default messages;
