import { defineMessages } from 'react-intl';

const messages = defineMessages({
	linkHeader: {
		id: 'home.productTile.modal.link.header',
		description: 'Dialog header text to get link to the product',
		defaultMessage: 'Link to product',
	},
	linkInfo: {
		id: 'home.productTile.modal.link.info',
		description: 'Dialog info text describing the link',
		defaultMessage:
			'The following URL can be used to sign in directly to {title}:',
	},
	copyButton: {
		id: 'home.productTile.modal.link.copy',
		description: 'Label for copy URL button',
		defaultMessage: 'Copy URL',
	},
});

export default messages;
