import { defineMessages } from 'react-intl';

/* eslint-disable camelcase */
const messages = defineMessages({
	addProduct: {
		id: 'home.productTile.connect',
		description:
			'Button text to add an identified product to the customer org',
		defaultMessage: 'Add product',
	},
	learnMore: {
		id: 'home.productTile.learnMore',
		description: 'Link text to learn more',
		defaultMessage: 'Learn more',
	},
	learnMoreUrl_adaptivework: {
		id: 'home.productTile.learnMoreUrl.adaptiveWork',
		description:
			'URL for the learn more site for AdaptiveWork. When there is a localized site, use that url such as https://www.planview.com/fr/products-solutions/products/adaptivework/',
		defaultMessage:
			'https://www.planview.com/products-solutions/products/adaptivework/',
	},
	learnMoreUrl_advisor: {
		id: 'home.productTile.learnMoreUrl.advisor',
		description:
			'URL for the learn more site for Advisor. When there is a localized site, use that url such as https://www.planview.com/fr/products-solutions/products/advisor/',
		defaultMessage:
			'https://www.planview.com/products-solutions/products/advisor/',
	},
	learnMoreUrl_e1_prm: {
		id: 'home.productTile.learnMoreUrl.e1_prm',
		description:
			'URL for the learn more site for Portfolios. When there is a localized site, use that url such as https://www.planview.com/fr/products-solutions/products/planview-portfolios/',
		defaultMessage:
			'https://www.planview.com/products-solutions/products/planview-portfolios/',
	},
	learnMoreUrl_ea: {
		id: 'home.productTile.learnMoreUrl.ea',
		description:
			'URL for the learn more site for EA. When there is a localized site, use that url',
		defaultMessage:
			'https://www.planview.com/products-solutions/products/enterprise-architecture/',
	},
	learnMoreUrl_leankit: {
		id: 'home.productTile.learnMoreUrl.leankit',
		description:
			'URL for the learn more site for AgilePlace. When there is a localized site, use that url such as https://www.planview.com/fr/products-solutions/products/agileplace/',
		defaultMessage:
			'https://www.planview.com/products-solutions/products/agileplace/',
	},
	learnMoreUrl_ppmpro: {
		id: 'home.productTile.learnMoreUrl.ppmpro',
		description:
			'URL for the learn more site for PPMPro. When there is a localized site, use that url such as https://www.planview.com/fr/products-solutions/products/ppm-pro/',
		defaultMessage:
			'https://www.planview.com/products-solutions/products/ppm-pro/',
	},
	learnMoreUrl_projectplace: {
		id: 'home.productTile.learnMoreUrl.projectplace',
		description:
			'URL for the learn more site for Project Place. When there is a localized site, use that url such as https://www.planview.com/fr/products-solutions/products/projectplace/',
		defaultMessage:
			'https://www.planview.com/products-solutions/products/projectplace/',
	},
	learnMoreUrl_roadmaps: {
		id: 'home.productTile.learnMoreUrl.roadmaps',
		description:
			'URL for the learn more site for Roadmaps. When there is a localized site, use that url',
		defaultMessage:
			'https://www.planview.com/products-solutions/products/roadmaps/',
	},
	learnMoreUrl_teamtap: {
		id: 'home.productTile.learnMoreUrl.teamtap',
		description:
			'URL for the learn more site for IdeaPlace. When there is a localized site, use that url',
		defaultMessage:
			'https://www.planview.com/products-solutions/products/ideaplace/',
	},
	learnMoreUrl_viz: {
		id: 'home.productTile.learnMoreUrl.viz',
		description:
			'URL for the learn more site for Viz. When there is a localized site, use that url',
		defaultMessage:
			'https://www.planview.com/products-solutions/products/viz/',
	},
	manageProduct: {
		id: 'home.productTile.manageProduct',
		description: 'Button text to edit product config',
		defaultMessage: 'Manage product',
	},
	iconTip: {
		id: 'home.productTile.actions.tooltip',
		description: 'Tool tip text for base (three-vertical dots) icon',
		defaultMessage: 'Actions',
	},
	syncUsers: {
		id: 'home.productTile.actions.syncUsers',
		description: 'Sync Users action text',
		defaultMessage: 'Sync users',
	},
	setPrimarySandbox: {
		id: 'home.productTile.actions.setPrimarySandbox',
		description: 'Set primary sandbox under a tile menu on products page',
		defaultMessage: 'Set as primary sandbox',
	},
	tenantSyncInfo: {
		id: 'home.productTile.actions.tenantSyncInfo',
		description: 'Text of success / non failure response to Sync users',
		defaultMessage:
			'A background job to sync users will start in approximately 1 minute.',
	},
	editProductDescription: {
		id: 'home.productTile.actions.editDescription',
		description: 'Text for menu item to edit product description',
		defaultMessage: 'Edit product description',
	},
	getLinkToProduct: {
		id: 'home.productTile.actions.getLinkToProduct',
		description:
			'Text for menu item to get a link that can be used to navigate to a product',
		defaultMessage: 'Get link to product',
	},
	delete: {
		id: 'home.productTile.actions.delete',
		description: 'Delete Product instance action text',
		defaultMessage: 'Delete',
	},
	hideFromOverview: {
		id: 'home.productTile.actions.hideFromOverview',
		description: 'Hide tile from Overview page action text',
		defaultMessage: 'Hide product from Overview',
	},
	showOnOverview: {
		id: 'home.productTile.actions.showOnOverview',
		description: 'Show tile on Overview page action text',
		defaultMessage: 'Show product in Overview',
	},
});

export default messages;
