import React, { ReactElement, useContext, useEffect } from 'react';
import { useLocation, useResolvedPath } from 'react-router-dom';
import { IntlShape, useIntl } from 'react-intl';
import qs from 'qs';
import { AppContext, AppContextProps } from '../../../context';
import { ToastType } from '../../../types/toast';

const scrubMessageId = (id: string) => {
	if (id) {
		return decodeURIComponent(id).replaceAll(/\+/g, ' ');
	}
	return undefined;
};

type CheckForUrlParamsProps = {
	intl: IntlShape;
	search: string;
	appContext: AppContextProps;
};

export const checkForUrlParams = (props: CheckForUrlParamsProps) => {
	const { intl, search, appContext } = props;
	const { error, info, success, errorId } = qs.parse(search, {
		ignoreQueryPrefix: true,
	});

	let messageId, type;
	if (error) {
		messageId = { id: scrubMessageId(error as string) };
		type = ToastType.DANGER;
	} else if (info) {
		messageId = { id: scrubMessageId(info as string) };
		type = ToastType.INFO;
	} else if (success) {
		messageId = { id: scrubMessageId(success as string) };
		type = ToastType.SUCCESS;
	}

	let message = messageId ? intl.formatMessage(messageId) : null;
	if (errorId) {
		message += ` ErrorId=${errorId as string}`;
	}

	if (message && type) {
		appContext.showToast({ message, type });
	}
};

type BaseRouteProps = {
	element: ReactElement;
};

const BaseRoute = (props: BaseRouteProps) => {
	const { element } = props;
	const appContext = useContext(AppContext);
	const intl = useIntl();
	const location = useLocation();
	const path = useResolvedPath('').pathname;

	useEffect(() => {
		const { search } = location;
		checkForUrlParams({
			intl,
			search,
			appContext,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return React.Children.map(element, (child) => {
		return React.cloneElement(child, { path });
	});
};

export default BaseRoute;
