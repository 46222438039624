import React, { useContext, useRef, useState } from 'react';
import {
	FormattedDate,
	FormattedMessage,
	injectIntl,
	useIntl,
} from 'react-intl';
import styled from 'styled-components';
import Grid from '../../../components/common/grid/Grid';
import { ButtonPrimary } from '../../../components/common/button/Button';
import { GridSearchInput } from '../../../components/common/input/GridSearchInput';
import { requestWithErrorHandling } from '../../../hooks/request/request';
import ActionsMenu from './ActionsMenu';
import messages from './MappedUsersGrid.messages';
import { HBox } from '../../../components/common/Layout';
import { TbFill, Toolbar } from '../../../components/common/toolbar/Toolbar';
import { Info } from '@planview/pv-icons';
import { useLocalStoragePreferences } from '@planview/pv-grid';
import { AppContext } from '../../../context';
import { theme, spacingPx } from '@planview/pv-utilities';

const Container = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	overflow: hidden;
	background-color: ${theme.backgroundNeutral0};
`;

const LastSynced = styled(HBox)`
	margin-left: ${spacingPx.small};
	margin-right: ${spacingPx.small};
	color: ${theme.textSecondary};
	font-size: 13px;
	justify-content: flex-end;
	flex: 1;
	line-height: 38px;
`;

const dateCellRenderer = ({ value }) =>
	value ? <FormattedDate value={new Date(value)} /> : '';

const getUsersGridColumns = (intl, app, appContext) => {
	const { formatMessage } = intl;
	const { roles, shouldShowAddedOnColumn } = app;
	const columns = [
		{
			id: 'firstName',
			label: formatMessage(messages.firstNameColumn),
			width: 300,
		},
		{
			id: 'lastName',
			label: formatMessage(messages.lastNameColumn),
			width: 300,
		},
		{
			id: 'email',
			label: formatMessage(messages.emailColumn),
			width: 300,
		},
	];

	if (shouldShowAddedOnColumn) {
		columns.push({
			id: 'addedOn',
			label: formatMessage(messages.addedOnColumn),
			cell: {
				label: (data) => dateCellRenderer(data),
			},
			width: 100,
		});
	}

	if (appContext.featureFlags.showLastLogin && app.ssoEnabled) {
		columns.push({
			id: 'lastLoginAt',
			label: intl.formatMessage(messages.lastLoginAtColumn),
			// todo mike 2023-11-2 make this sortable: true once we have UserTenantMappingItem's in
			//   single table and the necessary indices in place
			sortable: false,
			width: 80,
			cell: {
				label: (data) => dateCellRenderer(data),
			},
		});
	}

	if (roles && roles.length > 0) {
		const rolesById = roles.reduce(
			(acc, role) => acc.set(role.id, role),
			new Map(),
		);

		columns.push({
			id: 'role',
			label: formatMessage(messages.roleColumn),
			sortable: false,
			width: 100,
			cell: {
				label: ({ value }) => {
					let string = '';
					if (value) {
						const role = rolesById.get(value);
						if (role) {
							string = role.displayText;
						}
					}
					return string;
				},
			},
		});
	}

	return columns;
};

const isSyncInProgress = (appDetails) => {
	return !!appDetails.syncStart;
};

export const LastSyncedInfo = ({ appDetails, loading }) => {
	const { formatDate } = useIntl();
	const { showUserSync, lastSync, syncCount = 0 } = appDetails;
	if (!showUserSync) {
		return null;
	}

	const syncDate = lastSync
		? formatDate(lastSync, {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
				second: 'numeric',
			})
		: '';
	let message = lastSync ? messages.lastSynced : messages.lastSyncedNever;
	if (isSyncInProgress(appDetails)) {
		message = messages.lastSyncedInProgress;
	}

	return loading ? null : (
		<LastSynced data-testid="last-sync-info">
			<Info formattedMessage={messages.actionsToolTip} />
			<div>
				<FormattedMessage
					{...message}
					values={{
						syncDate,
						syncCount,
					}}
				/>
			</div>
		</LastSynced>
	);
};

const MappedUsersGrid = ({ ...props }) => {
	const { appDetails, intl } = props;
	const grid = useRef();
	const appContext = useContext(AppContext);
	const [loading, setLoading] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const columns = getUsersGridColumns(intl, appDetails, appContext);

	const preferencesKey = columns.map((c) => c.id).join('-');
	const preferencesAdapter = useLocalStoragePreferences(preferencesKey);

	const handleSyncUsers = async () => {
		const { appDetails } = props;
		setLoading(true);
		await requestWithErrorHandling({
			method: 'post',
			url: `/io/v1/user/sync/${appDetails.envSelectorEncodedString}`,
			appContext: appContext,
			intl: intl,
			infoMessage: messages.tenantSyncInfo,
		});
		setLoading(false);
	};

	const handleRefreshStatus = async () => {
		const { appDetails } = props;
		setLoading(true);
		const { success, ...rest } = await requestWithErrorHandling({
			method: 'get',
			url: `/io/v1/tenant/${appDetails.envSelectorEncodedString}`,
			appContext: appContext,
			intl: intl,
		});
		setLoading(false);
		if (success) {
			const { syncCount } = rest;
			// Update the app details so SSO status and Unmapped user count can be updated
			props.updateAppDetails(rest);
			// Refresh the grid only if the syncCount has changed.
			if (appDetails.syncCount !== syncCount) {
				grid.current.refresh();
			}
		}
	};

	// Wrapper for actions menu so we can pass both app and grid props
	const RowActionsMenu = (props) => (
		<ActionsMenu app={appDetails} {...props} />
	);

	return (
		<>
			<Toolbar data-testid="toolbar">
				<GridSearchInput
					value={searchValue}
					onChange={(searchQuery) => {
						setSearchValue(searchQuery);
					}}
					triggerSearch={(searchQuery) => {
						grid.current.triggerSearch(searchQuery);
					}}
				/>
				<TbFill />
				<LastSyncedInfo {...props} />
				{appDetails.showUserSync ? (
					isSyncInProgress(appDetails) ? (
						<ButtonPrimary
							loading={loading}
							message={messages.refreshSyncStatusButton}
							onClick={handleRefreshStatus}
						/>
					) : (
						<ButtonPrimary
							loading={loading}
							message={messages.syncUsersButton}
							onClick={handleSyncUsers}
						/>
					)
				) : null}
			</Toolbar>
			<Container>
				<Grid
					columns={columns}
					actionsMenu={RowActionsMenu}
					selectionType="none"
					url={`/io/v1/user/mapped/${appDetails.envSelectorEncodedString}`}
					innerRef={(ref) => {
						grid.current = ref;
					}}
					preferencesAdapter={preferencesAdapter}
					clearFilters={() => {
						setSearchValue('');
						grid.current.resetFiltering();
					}}
					{...props}
				/>
			</Container>
		</>
	);
};

export default injectIntl(MappedUsersGrid);
