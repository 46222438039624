import React, { useContext, useEffect, useState } from 'react';
import { get } from '../../../hooks/request/request';
import {
	LicenseDetail,
	LicenseInfoResponseDto,
	LicenseType,
} from '../../../types/api/licenses';
import messages from './LicenseInfo.messages';
import { Divider } from '@planview/pv-uikit';
import styled from 'styled-components';
import { border, theme, spacingPx, text } from '@planview/pv-utilities';
import { FormattedMessage } from 'react-intl';
import { AppContext } from '../../../context';
import { EnvSelector } from '../../../types';
import { isLicensingSupported } from '../../../helpers/util';

const InfoGroupContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: right;
`;

const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex-basis: 180px;
	${border.light()};
	background-color: ${theme.backgroundNeutral0};
	margin: ${spacingPx.small};
	padding: ${spacingPx.xsmall};
`;

const LicenseName = styled.div`
	${text.semibold};
	padding: ${spacingPx.xsmall};
`;

const CountContainer = styled.div`
	display: flex;
	flex-direction: row;
	padding: calc(${spacingPx.xsmall} / 2);
`;

const CountType = styled.div`
	flex-basis: 50%;
	${text.regular}
	padding-right: ${spacingPx.xsmall};
`;

const CountValue = styled.div`
	flex-basis: 50%;
	text-align: right;
	${text.numeric}
`;

interface LicenseDetailContainerProps extends LicenseDetail {
	licenseTypes: LicenseType[];
}

const LicenseDetailContainer = ({
	licenseType,
	totalLicenses,
	usedLicenses,
	licenseTypes,
}: LicenseDetailContainerProps) => {
	const appLicenseType = licenseTypes.find(
		(lt) => lt.name === licenseType.name,
	);
	return appLicenseType ? (
		<>
			<LicenseName>
				<FormattedMessage
					{...messages.licenseTypeHeader}
					values={{ LicenseType: appLicenseType?.displayText }}
				/>
			</LicenseName>
			<Divider />
			<CountContainer>
				<CountType>
					<FormattedMessage {...messages.total} />
				</CountType>
				<CountValue>
					{licenseType.unlimited ? (
						<FormattedMessage {...messages.unlimited} />
					) : (
						totalLicenses
					)}
				</CountValue>
			</CountContainer>
			<CountContainer>
				<CountType>
					<FormattedMessage {...messages.used} />
				</CountType>
				<CountValue>{usedLicenses}</CountValue>
			</CountContainer>
		</>
	) : null;
};

type LicenseInfoProps = {
	envSelector: EnvSelector;
	licenseTypes: LicenseType[];
	licenseDetails: LicenseDetail[];
	setLicenseDetails: (licenseDetails: LicenseDetail[]) => void;
};

const LicenseInfo = (props: LicenseInfoProps) => {
	const { envSelector, licenseTypes, licenseDetails, setLicenseDetails } =
		props;

	const appContext = useContext(AppContext);
	const { featureFlags } = appContext;
	const [showLicenseInfo, setShowLicenseInfo] = useState(
		isLicensingSupported(envSelector.application, featureFlags) &&
			!!licenseDetails.length,
	);

	useEffect(() => {
		const getLicenseInfo = async (envSelectorString: string) => {
			const url = '/io/v1/user/license/info';
			const urlParams = { envSelector: envSelectorString };
			const queryString = new URLSearchParams(urlParams).toString();

			const { licenseDetails } = (await get(
				`${url}?${queryString}`,
			)) as LicenseInfoResponseDto;

			if (licenseDetails?.length) {
				setLicenseDetails(licenseDetails);
				setShowLicenseInfo(
					isLicensingSupported(envSelector.application, featureFlags),
				);
			}
		};
		if (isLicensingSupported(envSelector.application, featureFlags)) {
			void getLicenseInfo(envSelector.toString());
		}
	}, [featureFlags, envSelector, setLicenseDetails]);

	return showLicenseInfo ? (
		<InfoGroupContainer data-testid="license-info-group">
			{licenseDetails?.map(
				({
					licenseType,
					totalLicenses,
					usedLicenses,
				}: LicenseDetail) => (
					<InfoContainer
						key={licenseType.name}
						data-testid="license-info-container"
					>
						<LicenseDetailContainer
							data-testid="license-detail"
							envSelector={envSelector}
							licenseType={licenseType}
							totalLicenses={totalLicenses}
							usedLicenses={usedLicenses}
							licenseTypes={licenseTypes}
						/>
					</InfoContainer>
				),
			)}
		</InfoGroupContainer>
	) : null;
};

export default LicenseInfo;
