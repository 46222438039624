import { defineMessages } from 'react-intl';

const messages = defineMessages({
	descriptionLabel: {
		id: 'admin.settings.clientCredentials.descriptionLabel',
		description: 'Label for name of credentials',
		defaultMessage: 'Name',
	},
	applicationLabel: {
		id: 'admin.settings.clientCredentials.applicationLabel',
		description: "Label for credentials' targeted application",
		defaultMessage: 'Application',
	},
	createdAtLabel: {
		id: 'admin.settings.clientCredentials.createdAtLabel',
		description: "Label for credentials' created on date",
		defaultMessage: 'Created on',
	},
	createdByLabel: {
		id: 'admin.settings.clientCredentials.createdByLabel',
		description: "Label for credentials' created by user",
		defaultMessage: 'Created by',
	},
	lastUsedAtLabel: {
		id: 'admin.settings.clientCredentials.lastUsedAtLabel',
		description: "Label for credentials' last used on date",
		defaultMessage: 'Last used on',
	},
	deactivatedAtLabel: {
		id: 'admin.settings.clientCredentials.deactivatedAtLabel',
		description: "Label for credentials' revoked on date",
		defaultMessage: 'Revoked on',
	},
	deactivatedByLabel: {
		id: 'admin.settings.clientCredentials.deactivatedByLabel',
		description: "Label for credentials' revoked by user",
		defaultMessage: 'Revoked by',
	},
	idLabel: {
		id: 'admin.settings.clientCredentials.idLabel',
		description: "Label for credentials' ID (identifier)",
		defaultMessage: 'ID',
	},
	createClientCredentials: {
		id: 'admin.settings.clientCredentials.create',
		description: 'Label for create OAuth2 credentials',
		defaultMessage: 'Create OAuth2 credentials',
	},
	showDeactivatedCredentialsSwitch: {
		id: 'admin.settings.clientCredentials.showDeactivated',
		description: 'Label for toggle to show/hide revoked credentials',
		defaultMessage: 'Show revoked credentials',
	},
	createTitle: {
		id: 'admin.settings.clientCredentials.createModal.title',
		description: 'Title for the create OAuth2 client credentials modal',
		defaultMessage: 'Create new OAuth2 credentials',
	},
	generatedTitle: {
		id: 'admin.settings.clientCredentials.createModal.generatedTitle',
		description:
			'Title of the modal for generated OAuth2 client credentials',
		defaultMessage: 'OAuth2 credentials created',
	},
	createButton: {
		id: 'admin.settings.clientCredentials.createModal.createButton',
		description: 'Label for create credentials button',
		defaultMessage: 'Create OAuth2 credentials',
	},
	descriptionPlaceholder: {
		id: 'admin.settings.clientCredentials.createModal.descriptionPlaceholder',
		description: 'Placeholder text for name of credentials',
		defaultMessage: 'Name for the credentials',
	},
	generatedSecretLabel: {
		id: 'admin.settings.clientCredentials.createModal.generatedSecret',
		description: "Label for generated credentials' secret",
		defaultMessage: 'Secret',
	},
	generatedCredentialsWarningMessage: {
		id: 'admin.settings.clientCredentials.createModal.generatedCredentialsWarningMessage',
		description:
			'Warning message to copy generated credentials. Do not translate <strong> tags.',
		defaultMessage:
			'For security reasons, you cannot view the <strong>Secret</strong> once you leave this page. Copy the <strong>ID</strong> and <strong>Secret</strong> and store them in a secure location. Publicly exposing your credentials can leave your account vulnerable to unexpected or malicious actors.',
	},
	generatedCredentialsCopyInstructions: {
		id: 'admin.settings.clientCredentials.createModal.generatedCredentialsCopyInstructions',
		description: 'Instructions for copying each text field to clipboard',
		defaultMessage:
			'You can click the icons to the right of each field to copy them to your clipboard.',
	},
	generateCredentialsFailure: {
		id: 'admin.settings.clientCredentials.generateCredentialsFailure',
		description: 'Toast message for failure to generate client credentials',
		defaultMessage:
			'Sorry, something went wrong. Failed to generate client credentials.',
	},
	deactivateCredentialsMenuItem: {
		id: 'admin.settings.clientCredentials.revokeMenuItem',
		description: 'Revoke credentials menu item',
		defaultMessage: 'Revoke credentials',
	},
	actionsButton: {
		id: 'admin.settings.clientCredentials.actionsButton',
		description: 'Bulk actions button for grid',
		defaultMessage: 'Actions',
	},
	noData: {
		id: 'admin.settings.clientCredentials.noData',
		description: 'Header when credentials grid has no data',
		defaultMessage: 'No OAuth2 credentials',
	},
	textFilterPlaceholder: {
		id: 'admin.settings.clientCredentials.filter.textPlaceholder',
		description: 'Placeholder text for text filters',
		defaultMessage: 'Type to search',
	},
	deactivateHeader: {
		id: 'admin.settings.clientCredentials.deactivate.header',
		description: 'Revoke OAuth2 credentials header',
		defaultMessage: 'Revoke OAuth2 credentials',
	},
	deactivateButton: {
		id: 'admin.settings.clientCredentials.deactivate.confirmButton',
		description: 'Revoke credentials button',
		defaultMessage: 'Revoke credentials',
	},
	deactivateWarning: {
		id: 'admin.settings.clientCredentials.deactivate.warning',
		description: 'Warning when revoking credentials',
		defaultMessage:
			'Proceeding with this action will immediately invalidate the authentication for any selected credentials. This action cannot be undone.',
	},
	deactivateWarningDetail1: {
		id: 'admin.settings.clientCredentials.deactivate.warningDetail1',
		description: 'Warning detail when revoking credentials',
		defaultMessage:
			'Any integration using the selected credentials for authentication will immediately lose access to its associated products.',
	},
	deactivateWarningDetail2: {
		id: 'admin.settings.clientCredentials.deactivate.warningDetail2',
		description: 'Warning detail when revoking credentials',
		defaultMessage:
			'You will need to reconfigure the authentication to continue accessing any product connected to the selected credentials.',
	},
	deactivateConfirmation: {
		id: 'admin.settings.clientCredentials.deactivate.confirmation',
		description: 'Confirmation question to revoke credentials',
		defaultMessage:
			'Are you sure you want to revoke the selected credentials?',
	},
});

export default messages;
