import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { get } from '../../hooks/request/request';
import GroupsContext, {
	Group,
	GroupsResponse,
} from '../../context/groupsContext';
import { OrgTag, OrgTagsDto } from '../../types/api/orgTags';
import OrgTagsContext from '../../context/orgTagsContext';
import { getOrgTagsByName } from '../../helpers/util';

const PVAdminPage = () => {
	const [systemGroups, setSystemGroups] = useState<Group[]>([]);
	const [systemGroupsById, setSystemGroupsById] = useState<
		Map<string, Group>
	>(new Map());
	const [availableOrgTags, setAvailableOrgTags] = useState<OrgTag[]>([]);
	const [availableOrgTagsByName, setAvailableOrgTagsByName] = useState<
		Map<string, OrgTag>
	>(new Map());
	const [loadedGroups, setLoadedGroups] = useState(false);
	const [loadedOrgTags, setLoadedOrgTags] = useState(false);

	useEffect(() => {
		const loadGroups = async () => {
			const { results, success } = await get<GroupsResponse>(
				'/io/v1/admin/group/system',
			);

			if (success) {
				setSystemGroups(results);
				const mapById = new Map(
					results.map((group) => [group.id, group]),
				);
				setSystemGroupsById(mapById);
				setLoadedGroups(true);
			}
		};
		void loadGroups();
	}, []);

	useEffect(() => {
		const loadOrgTags = async () => {
			const { orgTags, success } = await get<OrgTagsDto>(
				'/io/v1/admin/orgTag',
			);

			if (success) {
				setAvailableOrgTags(orgTags);
				const mapByName = getOrgTagsByName(orgTags);
				setAvailableOrgTagsByName(mapByName);
				setLoadedOrgTags(true);
			}
		};

		void loadOrgTags();
	}, []);

	return (
		<GroupsContext.Provider value={{ systemGroups, systemGroupsById }}>
			<OrgTagsContext.Provider
				value={{ availableOrgTags, availableOrgTagsByName }}
			>
				{loadedGroups && loadedOrgTags && <Outlet />}
			</OrgTagsContext.Provider>
		</GroupsContext.Provider>
	);
};

export default PVAdminPage;
